import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useTranslation } from 'react-i18next';
import { MdArrowDropDown } from "react-icons/md";
import { VscSettings } from "react-icons/vsc";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import { useLocation, useParams } from 'react-router-dom';
import 'jspdf-autotable';
import { decryptParams } from "../../../utils/cryptoUtils";
import Loader from '../../../components/common/Loder';
import authService from '../../../services/authService';
import autoTable from 'jspdf-autotable';
import FloatingLabelInput from '../../../components/common/FloatingLabelInput';
import NoDataFound from '../../../components/common/NoDataFound';
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';
import FloatingLabelSelect from '../../../components/common/FloatingLabelselect';
import moment from 'moment';
import { formatCurrency } from '../../../utils/formatCurrency';

export default function BookingSection() {
  const { t } = useTranslation();
  const token = localStorage.getItem("token");


  const location = useLocation();
  const { encryptedParam } = useParams()
  const { spID } = location.state || {};

  const [status, setStatus] = useState('');
  const [bookings, setBookings] = useState([]);
  const [bookingID, setbookingid] = useState('');

  const [loading, setLoading] = useState<boolean>(false);
  const [transactionID, settransactionId] = useState('');

  const filterDropdownRef = useRef<HTMLDivElement>(null);
  const SortDropdownRef = useRef<HTMLDivElement>(null);
  const ExportDropdownRef = useRef<HTMLDivElement>(null);

  const [isSortDropdownOpen, setSortDropdownOpen] = useState(false);
  const [selectedSort, setSelectedSort] = useState(t('Wallet.Latest'));
  const [isFilterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [isExportDropdownOpen, setExportDropdownOpen] = useState(false);
  const [bookingStatus,setbookingStatus] = useState("");

  const [first, setFirst] = useState(0);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);

  interface DecryptedParam {
    index?: any;
  }

  useEffect(() => {
    if (encryptedParam) {
      const decodedParam = decodeURIComponent(encryptedParam).replace(/\s/g, '+');
      const decryptedParam = decryptParams(decodedParam) as DecryptedParam;

      if (decryptedParam && 'spid' in decryptedParam) {
        const spid = decryptedParam.spid as number;
        BookingList(spid,"","","");
        console.log(spid, "SP ID");
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => { document.removeEventListener("mousedown", handleClickOutside); };
  }, [encryptedParam,page]);

  const statusTemplate = (rowData: any) => {
    const statusColor =
      rowData.booking_status === "COMPLETED" || rowData.booking_status === "PAID"
        ? "text-green-500"
        : rowData.booking_status === "ONGOING" || rowData.booking_status === "PENDING"
          ? "text-orange-500"
          : "text-red-500";
    return <span className={statusColor}>{rowData.booking_status}</span>;
  };

  const columns = [
    {
      field: 'id',
      header: t('Settlements.Sno'),
      body: (rowData: any, options: { rowIndex: number }) => options.rowIndex + 1 || '-',
    },
    {
      field: 'booking_date_time',
      header: t('Settlements.SettlementDateandTime'),
      sortable: true,
      body: (rowData: any) =>
        rowData.booking_date_time
          ? moment(rowData.booking_date_time).format('DD MMM YYYY - hh:mm A')
          : '-',
    },
    {
      field: 'booking_id',
      header: t('Booking.BookingId'),
      sortable: true,
      body: (rowData: any) => rowData.booking_id || '-',
    },
    {
      field: 'amount',
      header: t('Booking.Amount'),
      sortable: true,
      body: (rowData: any) => formatCurrency(rowData.amount !== undefined && rowData.amount !== null ? rowData.amount : '-'),
    },
    {
      field: 'transactionId',
      header: t('Wallet.TranID'),
      sortable: true,
      body: (rowData: any) => rowData.transaction_ref_number || '-',
    },
    {
      field: 'paymentReferenceId',
      header: t('Booking.PaymentReferenceID'),
      sortable: true,
      body: (rowData: any) => rowData.payment_gateway_ref_number || '-',
    },
    {
      field: 'booking_status',
      header: t('Booking.Status'),
      sortable: true,
      body: statusTemplate
    },
  ];

  const BookingList = (spid: any, sort_order: string = "DESC",bookingflg:any,bookingstatusflg:any) => {
    setLoading(true);
    authService.serviceProviderBookings(token, spid, page + 1, sort_order,bookingflg === "" ? "" : bookingID ,bookingstatusflg === "" ? "" : bookingStatus)
      .then((response) => {
        console.log(response, "Service Provider Bookings");
        if (response.status === 200) {
          setBookings(response.data.data)
          setTotalRecords(response.data.pagination.total)
        }
        else {
          setBookings([]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  if (loading) {
    return <Loader />;
  }

  const toggleSortDropdown = () => {
    setSortDropdownOpen(!isSortDropdownOpen);
    setFilterDropdownOpen(false);
    setExportDropdownOpen(false);
  };

  const toggleFilterDropdown = () => {
    setFilterDropdownOpen(!isFilterDropdownOpen);
    setSortDropdownOpen(false);
    setExportDropdownOpen(false);
  };

  const toggleExportDropdown = () => {
    setExportDropdownOpen(!isExportDropdownOpen);
    setSortDropdownOpen(false);
    setFilterDropdownOpen(false);
  };
  const clearAllFields = () => {
    setbookingid('');

    if (encryptedParam) {
      const decodedParam = decodeURIComponent(encryptedParam).replace(/\s/g, '+');
      const decryptedParam = decryptParams(decodedParam) as DecryptedParam;
  
      if (decryptedParam && 'spid' in decryptedParam) {
        const spid: any = decryptedParam.spid;
        BookingList(spid,"", "","");
        setFilterDropdownOpen(false);
      }
    } 

  };
  const handleSortChange = (sortOption: string) => {
    setSelectedSort(sortOption);
    setSortDropdownOpen(false);

    const sortOrder = sortOption === t('Wallet.Latest') ? "DESC" : "ASC";
    if (encryptedParam) {
      const decodedParam = decodeURIComponent(encryptedParam).replace(/\s/g, '+');
      const decryptedParam = decryptParams(decodedParam) as DecryptedParam;
  
      if (decryptedParam && 'spid' in decryptedParam) {
        const spid: any = decryptedParam.spid;
        BookingList(spid, sortOrder, "","");
        setFilterDropdownOpen(false);
      }
    } 
  };

  const exportToExcel = () => {
    const formattedData = bookings.map((item: any) => ({
      Sno: item.id,
      BookingDateTime: item.dateTime,
      BookingID: item.bookingId,
      Amount: item.amount,
      TransactionID: item.transactionId,
      PaymentReferenceID: item.paymentReferenceId,
      Status: item.status

    }));
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Operator_Bookings');
    XLSX.writeFile(workbook, 'Operator_Bookings.xlsx');
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableColumn = columns.map((col: any) => col.header);
    const tableRows = bookings.map((item: any) => [
      item.id,
      item.dateTime,
      item.bookingId,
      item.amount,
      item.transactionId,
      item.paymentReferenceId,
      item.status

    ]);

    autoTable(doc, {
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      styles: { fontSize: 10, },
      headStyles: {
        fillColor: [231, 246, 254],
        textColor: [0, 0, 0]
      }
    });

    doc.save('Operator_Bookings.pdf');
  };
  const handleClickOutside = (event: MouseEvent) => {
    if (filterDropdownRef.current && !filterDropdownRef.current.contains(event.target as Node)) {
      setFilterDropdownOpen(false);
    }
    if (ExportDropdownRef.current && !ExportDropdownRef.current.contains(event.target as Node)) {
      setExportDropdownOpen(false);
    }
    if (SortDropdownRef.current && !SortDropdownRef.current.contains(event.target as Node)) {
      setSortDropdownOpen(false);
    }
  };
  const onApply = () => {
    if (encryptedParam) {
      const decodedParam = decodeURIComponent(encryptedParam).replace(/\s/g, '+');
      const decryptedParam = decryptParams(decodedParam) as DecryptedParam;
  
      if (decryptedParam && 'spid' in decryptedParam) {
        const spid: any = decryptedParam.spid;
        BookingList(spid,"", bookingID,bookingStatus);
        setFilterDropdownOpen(false);
      }
    } else {
      console.error('Encrypted parameter is missing.');
    }
  };
  const onPageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    setRows(event.rows);
    setPage(event.page);
  };
  return (
    <div className="flex flex-col gap-[16px]">
      <div className="flex justify-between items-center">
        <span className="custom-page-heading">{t('BookingDetails.BookingDetails')}</span>
        <div className="flex gap-[8px]">
          {/* Sort by Latest Button */}
          <div className="relative">
            <button
              className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
              onClick={toggleSortDropdown}
            >
              {t('Wallet.SortBy')}: {selectedSort}
              <MdArrowDropDown />
            </button>
            {isSortDropdownOpen && (
              <div ref={SortDropdownRef} className="absolute bg-white border border-gray-300 rounded shadow-md mt-[5px] w-[130px] z-[500]">
                <button
                  className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100 border-b"
                  onClick={() => handleSortChange(t('Wallet.Latest'))}
                >
                  <span className="text-[16px] font-normal leading-[24px]">{(t('Wallet.Latest'))}</span>
                </button>
                <button
                  className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100"
                  onClick={() => handleSortChange(t('Wallet.Oldest'))}
                >
                  <span className="text-[16px] font-normal leading-[24px]">{(t('Wallet.Oldest'))}</span>
                </button>
              </div>
            )}
          </div>
          {/* Filter Dropdown */}
          <div className="relative">
            <button
              className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
              onClick={toggleFilterDropdown}
            >
              {t('Wallet.Filter')}
              <VscSettings />
            </button>
            {isFilterDropdownOpen && (
              <div ref={filterDropdownRef} className="absolute flex flex-col right-[-85px] gap-[20px] bg-white border border-gray-300 rounded shadow-md mt-[5px] p-5 w-[390px] z-[500]">
                <div className="text-[#3E4044] text-[18px] font-medium leading-[27px]">
                  {t('Wallet.Filter')}
                </div>

                <div className="text-[14px] leading-[24px] font-normal text-[#00000099]">
                  <FloatingLabelInput
                    id="email_float_label"
                    type="text"
                    label={t('Booking.BookingId')}
                    value={bookingID}
                    onChange={(e) => setbookingid(e.target.value)}
                  />
                </div>
                <div className="text-[14px] leading-[24px] font-normal text-[#00000099]">
                  <FloatingLabelSelect
                    // key={status}
                    id="paymenttype"
                    label={t("BookingDetails.BookingSts")}
                    value={bookingStatus}
                    onChange={(e: any) => setbookingStatus(e.target.value)}
                    options={[
                      { value: "COMPLETED", label: "COMPLETED" },
                      { value: "PENDING", label: "PENDING" },
                      { value: "ONGOING", label: "ONGOING" },
                      { value: "FAILED", label: "FAILED" },
                      { value: "CANCELLED", label: "CANCELLED" },
                    ]}
                  />
                </div>
                <div className="flex flex-col gap-[16px]">
                  <button
                    className="block w-full text-center px-[8px] py-[8px]"
                    onClick={clearAllFields}
                  >
                    {t('Filter.ClearAll')}
                  </button>
                  <button
                    className="block w-full text-center px-[8px] py-[8px] bg-[#2196F3] text-white" onClick={onApply}
                  >
                    {t('Filter.Apply')}
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className="relative">
            <button
              className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
              onClick={toggleExportDropdown}
            >
              {t('Wallet.Export')}
              <MdArrowDropDown />
            </button>
            {isExportDropdownOpen && (
              <div ref={ExportDropdownRef} className="absolute right-0 bg-white border border-gray-300 rounded shadow-md mt-[5px] w-[160px] z-[500]">
                <button
                  className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100 border-b"
                  onClick={() => {
                    setExportDropdownOpen(false);
                    exportToPDF()
                  }}
                >
                  <span className="text-[16px] font-normal leading-[24px]">{t('Export.ExportASPdf')}</span>
                </button>
                <button
                  className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100"
                  onClick={() => {
                    setExportDropdownOpen(false);
                    exportToExcel()
                  }}
                >
                  <span className="text-[16px] font-normal leading-[24px]">{t('Export.ExportASXlsx')}</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        {bookings.length === 0 ? <NoDataFound /> :
          <>
            <DataTable
              value={bookings}
              rows={10}
              tableStyle={{ minWidth: '50rem' }}
              rowHover
            >
              {columns.map((col, index) => (
                <Column
                  key={index}
                  field={col.field}
                  header={col.header}
                  sortable={col.sortable}
                  body={col.body}
                  headerClassName='custom-header-bg'
                  className='custom-col-bg'
                />
              ))}
            </DataTable>
            <Paginator
              first={first}
              rows={rows}
              totalRecords={totalRecords}
              onPageChange={onPageChange}
            />
          </>

        }
      </div>
    </div>
  );
}
