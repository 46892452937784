import React from 'react'
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import signup from './../../assets/signup.png';
import { useTranslation } from 'react-i18next';
import authService from '../../services/authService';
import FloatingLabelInput from '../../components/common/FloatingLabelInput';

export default function ForgotPassword() {

  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({ email: '' });
  const [apiError, setApiError] = useState('');

  const navigate = useNavigate();
  const { t } = useTranslation();

  const validateForm = () => {
    let formIsValid = true;
    const newErrors = { email: '' };
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      newErrors.email = t('signin.emailrequired');
      formIsValid = false;
    }
    else if (!email.match(emailPattern)) {
      newErrors.email = t('signin.invalidemail');
      formIsValid = false;
    }
    setErrors(newErrors);
    return formIsValid;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      ForgotPassword();
    }
  };

  const ForgotPassword = () => {
    setApiError('');
    authService.ForgotPassword(email)
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("forgotpwdemail", email);
          navigate("/email-verification", { state: { email } });
        } else {
          setApiError(response.data.message)
          console.log("Failure Message:", response.data.message);
        }
      })
      .catch((error) => {
        setApiError(error.message)
        console.error("Error:", error);
      })
  };

  return (
    <div className="flex justify-center min-h-screen bg-gradient-to-b from-[#50BEFF] to-[#019DF7]">
      <div className='screen-size flex flex-row p-[20px] w-full'>
        <div className="w-1/2 flex flex-col justify-center">
          <img
            className="w-full h-auto object-contain"
            src={signup}
            alt="signup"
          />
        </div>
        <div className="w-full md:w-1/2 flex flex-col items-center justify-center gap-6 md:gap-10">
          <form onSubmit={handleSubmit} className="bg-[#FFFFFF] rounded-2xl px-[64px] py-[120px] flex flex-col gap-[40px] min-w-[500px]">
            <div className='flex flex-col text-center gap-[8px]'>
              <h2 className="text-[32px] font-bold leading-[38.5px]"> {t('forgotpassword.resetpwd')}</h2>
              <div className='text-[#808080] text-[14px] font-normal leading-[21px]'>
                {t('forgotpassword.forgottxt1')}<br>
                </br>{t('forgotpassword.forgottxt2')}
              </div>
            </div>
            <div className='flex flex-col gap-[10px]'>
              <FloatingLabelInput
                id="email_float_label"
                type="email"
                label={<> {t('signin.emailaddress')} <span className='required_star'>*</span> </>}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (errors.email) setErrors({ ...errors, email: '' });
                }}
                error={errors.email}
              />
              {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
            </div>
            <div className='flex flex-col gap-[8px]'>
              {apiError && <p className="text-red-500 text-[12px] text-center mt-2">{apiError}</p>}
              <button type="submit" className="bg-[#019DF7] text-[#FFFFFF]  py-3 px-8 rounded w-full">
                {t('forgotpassword.sendlink')}
              </button>
              <Link to="/signin">
                <button type="submit" className="border border-[#019DF7] text-[#019DF7]  py-3 px-8 rounded w-full">
                  {t('forgotpassword.cancel')}
                </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
