import BookingList from "../features/bookings/pages/BookingList";

export default function Bookings() {

  return (
    <div className="flex flex-col gap-[16px]">
      <BookingList />
    </div>
  );
}
