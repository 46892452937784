// src/index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { PrimeReactProvider } from 'primereact/api';
import Tailwind from 'primereact/passthrough/tailwind';

import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'react-toastify/dist/ReactToastify.css';

import 'rsuite/Steps/styles/index.css';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import toast, { Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <PrimeReactProvider value={{ unstyled: true, pt: Tailwind }}>
        <Provider store={store}>
          <Router>
            <App />
            <ToastContainer />
          </Router>
        </Provider>
      </PrimeReactProvider>
    </I18nextProvider>
  </React.StrictMode>
);

reportWebVitals();