import { useState ,forwardRef,useEffect,useImperativeHandle} from 'react';
import { decryptParams } from "./../../utils/cryptoUtils";
import operatorService from '../../services/operatorServices';
import FloatingLabelInput from '../common/FloatingLabelInput';
import { useTranslation } from "react-i18next";
import {
  useParams,
} from "react-router-dom";

const BusinessDetails = forwardRef((props, ref) => {
  const [directorName, setDirectorname] = useState('');
  const [bussinessname, setBussinessName] = useState('');
  const [taxnumber, settaxnumber] = useState('');
  const [country, setcountry] = useState('');
  const [registernum, setregisternum] = useState('');
  const [regaddress, setregaddress] = useState('');
  const [businesscontact, setbusinesscontact] = useState('');
  const [type,settype]=useState('')
  const [business,setbusiness]=useState('')
  const { t } = useTranslation();
  
  const { encryptedParam } = useParams();

  useEffect(() => {
      if (encryptedParam) {
          fetchOperatorDetails(encryptedParam);
      }
  }, [encryptedParam]);

  const fetchOperatorDetails = (encryptedParam: string | undefined) => {
      if (!encryptedParam) return;

      try {
          const decodedParam = decodeURIComponent(encryptedParam).replace(/\s/g, "+");
          const decryptedParam: any = decryptParams(decodedParam);

          if (decryptedParam) {
              console.log(decryptedParam);
              operatorService.getOperatorById(decryptedParam?.operatorId)
                  .then((res) => {
                      console.log("Personal details:", res);
                      setbusiness(res.data.data);
                      setDirectorname(res.data.data.director_name)
                      setBussinessName(res.data.data.business_name)
                      settaxnumber(res.data.data.tax_registration_number)
                      settype(res.data.data.business_type)
                      setcountry(res.data.data.country_of_incorporation)
                      setregaddress(res.data.data.address_1)
                      setregisternum(res.data.data.registration_number)
                      setbusinesscontact(res.data.data.mobile_number)   
                  })
                  .catch((error) => {
                      console.error("Error fetching operator details:", error);
                  });
          }
      } catch (error) {
          console.error("Error processing the encrypted parameter:", error);
      }
  };

  useImperativeHandle(ref, () => ({
    getDetails() {
      return {
        bussinessname,
        type,
        registernum,
        regaddress,
        country,
        taxnumber,
        directorName,
        businesscontact
      };
    },
  }));
  return (
    <div className="h-72 border-gray-300 rounded-lg">
      <div className="my-4">
        <FloatingLabelInput
          id="name_float_label"
          type="text"
          label={t("Operators.Name")}
          value={bussinessname}
          onChange={(e) => setBussinessName(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <FloatingLabelInput
          id="type_float_label"
          type="text"
          label={t("Operators.Type")}
          value={type}
          onChange={(e) => settype(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <FloatingLabelInput
          id="regnum_float_label"
          type="text"
          label={t("Operators.RegistrationNumber")}
          value={registernum}
          onChange={(e) => setregisternum(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <FloatingLabelInput
          id="regaddress_float_label"
          type="text"
          label={t("Operators.RegistrationAddress")}
          value={regaddress}
          onChange={(e) => setregaddress(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <FloatingLabelInput
          id="Country_float_label"
          type="text"
          label={t("Operators.CountryOfIncorporation")}
          value={country}
          onChange={(e) => setcountry(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <FloatingLabelInput
          id="taxnumber_float_label"
          type="text"
          label={t("Operators.TaxRegistrationNumber")}
          value={taxnumber}
          onChange={(e) => settaxnumber(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <FloatingLabelInput
          id="signname_float_label"
          type="text"
          label={t("Operators.DirectorName")}
          value={directorName}
          onChange={(e) => setDirectorname(e.target.value)}
        />
      </div>
      <div className="pb-6">

        <FloatingLabelInput
          id="businesscontact_float_label"
          type="text"
          label={t("Business Contact Information")}
          value={businesscontact}
          onChange={(e) => setbusinesscontact(e.target.value)}
        />
      </div>
    </div>
  );
});

export default BusinessDetails;
