import { Link } from 'react-router-dom';
import signup from './../../assets/signup.png';
import check from './../../assets/check.png';
import { useTranslation } from 'react-i18next';

export default function EmailVerified() {
    const { t } = useTranslation();
    return (
        <div className="flex justify-center min-h-screen bg-gradient-to-b from-[#50BEFF] to-[#019DF7]">
            <div className='screen-size flex flex-row p-[20px] w-full'>
                <div className="w-1/2 flex flex-col justify-center">
                    <img className="w-full h-auto object-contain" src={signup} alt="" />
                </div>
                <div className="w-full md:w-1/2 flex flex-col items-center justify-center gap-6 md:gap-10">
                    <form className="bg-[#FFFFFF] rounded-2xl px-[64px] py-[200px] flex flex-col gap-[40px] min-w-[500px]">
                        <div className='flex flex-col text-center gap-[8px]'>
                            <img
                                className="w-full h-[60px] object-contain"
                                src={check}
                                alt="check"
                            />
                            <h2 className="text-[28px] font-bold leading-[38.5px]">  {t('emailverify.emailverified')}</h2>
                            <div className='text-[#808080] text-[14px] font-normal leading-[21px]'>
                                {t('emailverify.congsuccess')}
                            </div>
                        </div>
                        <div className='flex flex-col gap-[16px]'>
                            <Link to="/signin">
                                <button type="submit" className="bg-[#019DF7] text-[#FFFFFF]  py-3 px-8 rounded w-full">
                                    {t('signin.signin')}
                                </button>
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
