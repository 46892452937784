import { useEffect, useState, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MdArrowDropDown } from 'react-icons/md';
import { VscSettings } from 'react-icons/vsc';
import { useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import { decryptParams } from "../../../utils/cryptoUtils";
import { useLocation } from 'react-router-dom';
import autoTable from 'jspdf-autotable';
import Loader from '../../../components/common/Loder';
import authService from '../../../services/authService';
import NoDataFound from '../../../components/common/NoDataFound';
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';
import FloatingLabelInput from '../../../components/common/FloatingLabelInput';
import { formatCurrency } from '../../../utils/formatCurrency';
import { format } from 'rsuite/esm/internals/utils/date';

export default function Settlements() {
  const { id } = useParams();
  const { encryptedParam } = useParams()
  const { t } = useTranslation();

  const location = useLocation();
  const { spID } = location.state || {};

  const [bankName, setbankname] = useState('');
  const [transactionId, settransactionId] = useState('');

  const filterDropdownRef = useRef<HTMLDivElement>(null);
  const SortDropdownRef = useRef<HTMLDivElement>(null);
  const ExportDropdownRef = useRef<HTMLDivElement>(null);

  const [wallet, setWallet]: any = useState([]);
  const [settlements, setSettlements]: any = useState([]);
  const [settlementsDetails, setSettlementsDetails]: any = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedSort, setSelectedSort] = useState(t('Wallet.Latest'));

  const [isSortDropdownOpen, setSortDropdownOpen] = useState(false);
  const [isFilterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [isExportDropdownOpen, setExportDropdownOpen] = useState(false);

  const [first, setFirst] = useState(0);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);


  const token = localStorage.getItem("token");

  interface DecryptedParam {
    index?: any;
  }

  useEffect(() => {
    if (encryptedParam) {
      const decodedParam = decodeURIComponent(encryptedParam).replace(/\s/g, '+');
      const decryptedParam = decryptParams(decodedParam) as DecryptedParam;

      if (decryptedParam && 'spid' in decryptedParam) {
        const spid = decryptedParam.spid as number;
        SettlementsList(spid, "desc", "");
        console.log(spid, "SP ID");
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => { document.removeEventListener("mousedown", handleClickOutside); };

  }, [page]);

  const columns: any = [
    {
      field: 'id',
      label: t('Settlements.Sno'),
      body: (rowData: any, options: { rowIndex: number }) => options.rowIndex + 1,
    },
    {
      field: 'created_datetime',
      label: t('Settlements.SettlementDateandTime'),
      sortable: true,
      body: (rowData: any) =>
        rowData.created_datetime
          ? moment(rowData.created_datetime).format('DD MMM YYYY - hh:mm A')
          : "-", // Show "-" if no date
    },
    {
      label: t('Wallet.TranID'),
      field: "transaction_id",
      sortable: true,
    },
    {
      label: t('Settlements.BankName'),
      field: "bank_name",
      sortable: true,
    },
    {
      label: t('Settlements.Description'),
      field: "description",
      sortable: true,
    },
    {
      label: t('Settlements.SettlementAmt'),
      field: "amount",
      sortable: true,
      body: (rowData: any) => <span>{formatCurrency(rowData.amount)}</span>

    }
  ]

  const SettlementsList = (spid: any, sort_order: string = "desc", transactionflg: any) => {
    setLoading(true);
    authService.serviceProviderSettlements(token, spid, page + 1, sort_order, transactionflg === "" ? "" : transactionId)
      .then((response) => {
        console.log(response, "Service Provider Settlements");
        if (response.status === 200) {
          setSettlements(response?.data?.data?.settlements)
          setSettlementsDetails(response?.data?.data?.upcoming_details);
          setTotalRecords(response?.data?.pagination?.total)
        }
        else {
          setSettlements([]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  if (loading) {
    return <Loader />;
  }

  const toggleSortDropdown = () => {
    setSortDropdownOpen(!isSortDropdownOpen);
    setFilterDropdownOpen(false);
    setExportDropdownOpen(false);
  };

  const toggleFilterDropdown = () => {
    setFilterDropdownOpen(!isFilterDropdownOpen);
    setSortDropdownOpen(false);
    setExportDropdownOpen(false);
  };

  const toggleExportDropdown = () => {
    setExportDropdownOpen(!isExportDropdownOpen);
    setSortDropdownOpen(false);
    setFilterDropdownOpen(false);
  };

  const handleSortChange = (sortOption: string) => {
    setSelectedSort(sortOption);
    setSortDropdownOpen(false);
    const sortOrder = sortOption === t('Wallet.Latest') ? "desc" : "ASC";
    if (encryptedParam) {
      const decodedParam = decodeURIComponent(encryptedParam).replace(/\s/g, '+');
      const decryptedParam = decryptParams(decodedParam) as DecryptedParam;
  
      if (decryptedParam && 'spid' in decryptedParam) {
        const spid: any = decryptedParam.spid;
        SettlementsList(spid, sortOrder, "");
        setFilterDropdownOpen(false);
      }
    } 


  }
  const clearAllFields = (spid: any) => {
    settransactionId('');
    setbankname('');

    if (encryptedParam) {
      const decodedParam = decodeURIComponent(encryptedParam).replace(/\s/g, '+');
      const decryptedParam = decryptParams(decodedParam) as DecryptedParam;
  
      if (decryptedParam && 'spid' in decryptedParam) {
        const spid: any = decryptedParam.spid;
        SettlementsList(spid, "", "");
        setFilterDropdownOpen(false);
      }
    } 

  };
  const exportToExcel = () => {
    const formattedData = wallet.map((item: any,index:any) => ({
      Sno: index +1 ,
      DateandTime: item.created_datetime  || "-",
      TransactionID: item.transaction_id  || "-",
      BankName: item.bank_name  || "-",
      Description: item.description  || "-",
      SettlementAmount: item.amount  || "-"

    }));
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Serviceprovider_Settlement');
    XLSX.writeFile(workbook, 'Serviceprovider_Settlement.xlsx');
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableColumn = columns.map((col: any) => col.label);
    const tableRows = wallet.map((item: any) => [
      item.created_datetime || "-",
      item.transaction_id  || "-",
      item.bank_name  || "-",
      item.description  || "-",
      item.amount || "-",
    ]); 

    autoTable(doc, {
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      styles: { fontSize: 10, },
      headStyles: {
        fillColor: [231, 246, 254],
        textColor: [0, 0, 0]
      }
    });
    doc.save('Serviceprovider_Settlement.pdf');
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (filterDropdownRef.current && !filterDropdownRef.current.contains(event.target as Node)) {
      setFilterDropdownOpen(false);
    }
    if (ExportDropdownRef.current && !ExportDropdownRef.current.contains(event.target as Node)) {
      setExportDropdownOpen(false);
    }
    if (SortDropdownRef.current && !SortDropdownRef.current.contains(event.target as Node)) {
      setSortDropdownOpen(false);
    }
  };

  const onPageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    setRows(event.rows);
    setPage(event.page);
  };
  const onApply = () => {
    if (encryptedParam) {
      const decodedParam = decodeURIComponent(encryptedParam).replace(/\s/g, '+');
      const decryptedParam = decryptParams(decodedParam) as DecryptedParam;
  
      if (decryptedParam && 'spid' in decryptedParam) {
        const spid: any = decryptedParam.spid;
        SettlementsList(spid,"", transactionId);
        setFilterDropdownOpen(false);
      }
    } else {
      console.error('Encrypted parameter is missing.');
    }
  };

  return (
    <div className="flex flex-col">
      <div className="p-[24px] bg-[#ffffff] rounded-[8px] mb-[16px]">
        {settlementsDetails && (
          <>
            <section>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-4 gap-x-8">
                <div className='flex flex-col gap-[4px]'>
                  <span className='custom-body3-semibold'>
                    {t('Settlements.tltsettledamt')}</span>
                  <span className="custom-body-regular">$ {settlementsDetails.total_settled_amount || "-"}</span>
                </div>
                <div className='flex flex-col gap-[4px]'>
                  <span className='custom-body3-semibold'>
                    {t('ServiceProvider.CurrentWalletAmount')}</span>
                  <span className="custom-body-regular">$ {settlementsDetails.current_wallet_amount || "-"}</span>
                </div>
                <div className='flex flex-col gap-[4px]'>
                  <span className='custom-body3-semibold'>
                    {t('Settlements.NxtSettlement')}</span>
                  <span className="custom-body-regular">{settlementsDetails.next_settlement_date || "-"}</span>
                </div>
                <div className='flex flex-col gap-[4px]'>
                  <span className='custom-body3-semibold' >
                    {t('Settlements.SettlementCycle')}</span>
                  <span className="custom-body-regular">{settlementsDetails.settlement_cycle || "-"}</span>
                </div>
                <div className='flex flex-col gap-[4px]'>
                  <span className='custom-body3-semibold'>
                    {t('ServiceProvider.SettlementBankAccount')}</span>
                  <span className="custom-body-regular">{settlementsDetails.settlement_bank_account || "-"}</span>
                </div>
                <div className='flex flex-col gap-[4px]'>
                  <span className='custom-body3-semibold'>
                    {t('ServiceProvider.SettlementAccountNumber')}
                  </span>
                  <span className="custom-body-regular">{settlementsDetails.settlement_account_number || "-"}</span>
                </div>
              </div>
            </section>
          </>
        )}
      </div>
      {/* Datatable */}
      <div className="flex flex-col">
        <div className="flex justify-between items-center">

          <span className="custom-page-heading mb-[16px]">{t('ServiceProvider.SettlementsList')}</span>
          <div className="flex gap-[8px]">
            {/* Sort by Latest Button */}
            <div className="relative">
              <button
                className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
                onClick={toggleSortDropdown}
              >
                {t('Wallet.SortBy')}: {selectedSort}
                <MdArrowDropDown />
              </button>
              {isSortDropdownOpen && (
                <div ref={SortDropdownRef} className="absolute bg-white border border-gray-300 rounded shadow-md mt-[5px] w-[130px] z-[500]">
                  <button
                    className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100 border-b"
                    onClick={() => handleSortChange(t('Wallet.Latest'))}
                  >
                    <span className="text-[16px] font-normal leading-[24px]">{(t('Wallet.Latest'))}</span>
                  </button>
                  <button
                    className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100"
                    onClick={() => handleSortChange(t('Wallet.Oldest'))}
                  >
                    <span className="text-[16px] font-normal leading-[24px]">{(t('Wallet.Oldest'))}</span>
                  </button>
                </div>
              )}
            </div>

            {/* Filter Dropdown */}
            <div className="relative">
              <button
                className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
                onClick={toggleFilterDropdown}
              >
                {t('Wallet.Filter')}
                <VscSettings />
              </button>
              {isFilterDropdownOpen && (
                <div ref={filterDropdownRef} className="absolute flex flex-col right-[-85px] gap-[20px] bg-white border border-gray-300 rounded shadow-md mt-[5px] p-5 w-[390px] z-[500]">
                  <div className="text-[#3E4044] text-[18px] font-medium leading-[27px]">
                    {t('Wallet.Filter')}
                  </div>

                  <div className="text-[14px] leading-[24px] font-normal text-[#00000099]">
                    <FloatingLabelInput
                      id="transaction_ID"
                      type="text"
                      label={t('Wallet.TranID')}
                      value={transactionId}
                      onChange={(e) => settransactionId(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col gap-[16px]">
                    <button
                      className="block w-full text-center px-[8px] py-[8px]"
                      onClick={clearAllFields}
                    >
                      {t('Filter.ClearAll')}
                    </button>
                    <button
                      className="block w-full text-center px-[8px] py-[8px] bg-[#2196F3] text-white" onClick={onApply}
                    >
                      {t('Filter.Apply')}
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="relative">
              <button
                className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
                onClick={toggleExportDropdown}
              >
                {t('Wallet.Export')}
                <MdArrowDropDown />
              </button>
              {isExportDropdownOpen && (
                <div ref={ExportDropdownRef} className="absolute right-0 bg-white border border-gray-300 rounded shadow-md mt-[5px] w-[160px] z-[500]">
                  <button
                    className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100 border-b"
                    onClick={() => {
                      setExportDropdownOpen(false);
                      exportToPDF()
                    }}
                  >
                    <span className="text-[16px] font-normal leading-[24px]">{t('Export.ExportASPdf')}</span>
                  </button>
                  <button
                    className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100"
                    onClick={() => {
                      setExportDropdownOpen(false);
                      exportToExcel()
                    }}
                  >
                    <span className="text-[16px] font-normal leading-[24px]" >{t('Export.ExportASXlsx')}</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {settlements.length === 0 ? <NoDataFound /> :
          <>
            <DataTable value={settlements} rows={10}>
              {columns.map((item: any) => (
                <Column
                  field={item.field}
                  header={item.label}
                  headerClassName='custom-header-bg'
                  className='custom-col-bg'
                  sortable={item.sortable}
                  body={item.body}
                />
              ))}
            </DataTable>
            <Paginator
              first={first}
              rows={rows}
              totalRecords={totalRecords}
              onPageChange={onPageChange}
            />
          </>

        }
      </div>
    </div>
  );
}
