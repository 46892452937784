import React, { useState, useImperativeHandle, forwardRef } from 'react';
import FloatingLabelInput from '../common/FloatingLabelInput';
import { useTranslation } from 'react-i18next';
import EyeIcon from '../../components/common/EyeIcon';

interface ErrorType {
  password?: string;
  confirmPassword?: string;
  currentPassword?: string;
  newPassword?: string;
}

interface ChangePasswordModalContentProps {
  // onSubmit: (passwordData: { currentPassword: string; newPassword: string; confirmPassword: string }) => void;
  // setPasswordData: (data: { currentPassword: string; newPassword: string; confirmPassword: string }) => void;
}

const ChangePasswordModalContent = forwardRef((props: ChangePasswordModalContentProps, ref) => {
  const { t } = useTranslation();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewpassword] = useState('');
  const [confirmPassword, setConfirmpassword] = useState('');
  const [errors, setErrors] = useState<ErrorType>({});
  const [showPassword, setShowPassword] = useState(false);
  const [shownewPassword, setShownewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);



  const validatePassword = (password: any) => {
    const regex = /^(?=.*\d)(?=.*[!@#$%^&*()-+])(?=.*[a-zA-Z]).{8,}$/;
    return regex.test(password);
  };

  // Validation Function
  const validateForm = () => {
    let formIsValid = true;
    const newErrors: ErrorType = { currentPassword: '', newPassword: '', confirmPassword: '' };
    console.log("newerrors===", newErrors);

    if (!currentPassword) {
      newErrors.currentPassword = t('signup.currentpass');
      formIsValid = false;
    }

    if (!newPassword) {
      newErrors.newPassword = t('signup.newpass');
      formIsValid = false;
    }
    else if (!validatePassword(newPassword)) {
      newErrors.newPassword = t('signin.passwordalign');
      formIsValid = false;
    }
    if (!confirmPassword) {
      newErrors.confirmPassword = t('signup.cpr');
      formIsValid = false;
    }
    else if (newPassword !== confirmPassword) {
      newErrors.confirmPassword = t('signup.pwdnotmatch');
      formIsValid = false;
    }

    // Validation passed
    setErrors(newErrors);
    return {
      currentPassword,
      newPassword,
      confirmPassword,
      formIsValid
    };
  };

  // Use useImperativeHandle to expose the validateForm function to the parent
  useImperativeHandle(ref, () => ({
    validateForm,
  }));

  const toggleoldPasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleconfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const togglenewPasswordVisibility = () => {
    setShownewPassword(!shownewPassword);
  };

  return (
    <div className='mt-2'>
      {errors && <div className="error-message">{errors.password}</div>}
      <div className="mb-8">
        <div className='relative'>
          <FloatingLabelInput
            id="password_float_label"
            type={showPassword ? "text" : "password"}
            label={t('Settings.EnterPassword')}
            value={currentPassword}
            onChange={(e) => {
              const newPassword = e.target.value.slice(0, 20);
              setCurrentPassword(newPassword);
            }}
            error={errors.password}
          />
          <EyeIcon
            onClick={toggleoldPasswordVisibility}
            isPasswordVisible={showPassword}
          />
        </div>
        {errors.currentPassword && <p className="text-red-500 text-sm">{errors.currentPassword}</p>}
      </div>

      <div className="mb-8">
        <div className='relative'>
          <FloatingLabelInput
            id="newpassword_float_label"
            type={shownewPassword ? "text" : "password"}
            label={t('Settings.Newpassword')}
            value={newPassword}
            onChange={(e) => {
              const newPassword = e.target.value.slice(0, 20);
              setNewpassword(newPassword);
            }}
            error={errors.newPassword}
          />
          <EyeIcon
            onClick={togglenewPasswordVisibility}
            isPasswordVisible={shownewPassword}
          />
        </div>
        {errors.newPassword && <p className="text-red-500 text-sm">{errors.newPassword}</p>}
      </div>
      <div className="">
        <div className='relative'>
          <FloatingLabelInput
            id="confirmpassword_float_label"
            type={showConfirmPassword ? "text" : "password"}
            label={t('Settings.confirmpassword')}
            value={confirmPassword}
            onChange={(e) => {
              const newPassword = e.target.value.slice(0, 20);
              setConfirmpassword(newPassword);
            }}
            error={errors.confirmPassword}
          />
          <EyeIcon
            onClick={toggleconfirmPasswordVisibility}
            isPasswordVisible={showConfirmPassword}
          />
        </div>
        {errors.confirmPassword && <p className="text-red-500 text-sm">{errors.confirmPassword}</p>}
      </div>
    </div>

  );
});
export default ChangePasswordModalContent;