import api from './api';

const updatePassword = async (payload: any) => {
    try {
        const response = await api.patch('/user/update-password', payload);
        return response
    } catch (error: any) {
        return error
    }
};

const getGeneralConfig = async () => {
    const response = await api.get('user/config-details')
    return response
}

const updateGeneralConfig = async (payload: any) => {
    try {
        const response = await api.patch('user/config', payload)
        return response
    } catch (error: any) {
        return error
    }
}

const getPaymentAcquirerConfig = async () => {
    const response = await api.get('/user/payment-config')
    return response
}

const upsertPaymentAcquirerConfig = async (payload: any) => {
    try {
        const response = await api.post('user/upsert-payment-acquirer-config', payload)
        return response
    } catch (error: any) {
        return error
    }
}

const upsertPaymentDisbursalConfig = async (payload: any) => {
    try {
        const response = await api.post('user/upsert-payment-disbursal-config', payload)
        return response
    } catch (error: any) {
        return error
    }
}


const getSODetails = async (id: any) => {
    try {
        const response = await api.get(`/user/${id}/details`)
        return response
    } catch (error: any) {
        return error
    }
}

const SettingService = {
    updatePassword,
    getGeneralConfig,
    updateGeneralConfig,
    getPaymentAcquirerConfig,
    upsertPaymentAcquirerConfig,
    upsertPaymentDisbursalConfig,
    getSODetails
}

export default SettingService