import noData from '../../assets/noData.png'

const NoDataFound = () => {
    return (
        <div className="bg-white shadow-md p-6 flex flex-col gap-8 w-full rounded-lg justify-center items-center">
            <div className="flex flex-col justify-center items-center">
                <img src={noData} alt="Error" className="w-[285px] h-[160px]" />
                <label className="text-[40px] text-[#3E4044] text-center font-semibold">No Data Found!</label>
            </div>
        </div>
    )
}

export default NoDataFound;