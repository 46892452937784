
import { PiEyeSlashThin } from "react-icons/pi";
import { PiEyeThin } from "react-icons/pi";
import React from "react";

interface EyeIcon {
  onClick: (event: React.MouseEvent<SVGSVGElement>) => void;
  isPasswordVisible: boolean; // To toggle between icons
}
export default function EyeIcon({ onClick, isPasswordVisible  }: EyeIcon) {
  return(
    <svg
    onClick={onClick} className="absolute cursor-pointer right-2 top-1/2 transform -translate-y-1/2 mt-[2px]" width="24" height="24" viewBox="0 0 24 24" fill="none"  xmlns="http://www.w3.org/2000/svg">
    {isPasswordVisible ? (
      // Open eye icon
        <PiEyeThin size={21} style={{ color: "#999999"}} />
    ) : (
      // Closed eye icon
      <PiEyeSlashThin size={20} style={{ color: "#999999", marginTop: "10px" }} />
    )}
  </svg>
  );
}
