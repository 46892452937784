import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import {  useNavigate } from 'react-router-dom';
import Sidebar from '../layout/Sidebar';
import Header from '../layout/Header';
import Loader from '../common/Loder';

export default function Layout() {

    const [loading, setLoading] = useState<boolean>(true);

    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            navigate('/signin', { replace: true });
        } else {
            const timer = setTimeout(() => {
                setLoading(false);
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [navigate]);

    return (
        <div className='flex flex-row bg-neutral-100 h-screen w-screen overflow-hidden'>
            <Sidebar />
            <div className='flex flex-col flex-grow bg-[#F4F6FA] '>
                <div>
                    <Header />
                </div>
                <div className='flex-grow overflow-y-auto '>
                    <div className='px-[24px] py-[24px] bg-[#F4F6FA]'>
                        {loading ? <div className='mt-40'><Loader /></div> : <Outlet />}
                    </div>
                </div>
            </div>
        </div>
    );
}
