import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import signup from './../../assets/signup.png';
import EyeIcon from '../../components/common/EyeIcon';
import { useTranslation } from 'react-i18next';
import authService from '../../services/authService';
import FloatingLabelInput from '../../components/common/FloatingLabelInput';

interface ErrorType {
  newPassword?: string;
  confirmPassword?: string;
}

export default function ChangePassword() {

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [errors, setErrors] = useState<ErrorType>({});
  const [apiError, setApiError] = useState('');

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const tkn = params.get('token');

  const email = localStorage.getItem("forgotpwdemail");

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    if (!tkn) {
      navigate('/signin');
      return;
    }
    ForgotEmailVerify();
  }, [tkn, navigate]);

  const validateForm = () => {
    let formIsValid = true;
    const newErrors: ErrorType = { newPassword: '', confirmPassword: '' };

    if (!newPassword) {
      newErrors.newPassword = t('changepwd.newpwdreq');
      formIsValid = false;
    }

    if (!confirmPassword) {
      newErrors.confirmPassword = t('signup.cpr');
      formIsValid = false;
    } else if (newPassword !== confirmPassword) {
      newErrors.confirmPassword = t('signup.pwdnotmatch');
      formIsValid = false;
    }

    setErrors(newErrors);
    return formIsValid;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      UpdatePassword();
      console.log('Password changed successfully');
    }
  };

  const ForgotEmailVerify = () => {
    authService.ForgotEmailVerify(tkn || "")
      .then((response) => {
        console.log(response, "Response");
        if (response.status === 200) {
          console.log("email Verified")
        }
      })
      .catch(() => {
        console.error("Error:");
      })
  }

  const UpdatePassword = () => {
    setApiError('');
    authService.ChangePassword(email || '', newPassword || '')
      .then((response) => {
        console.log(response, "Response");
        if (response.status === 200) {
          navigate('/password-update')
          console.log("password-updated")
        } else {
          setApiError(response.data.message)
          console.log("Failure Message:", response.data.message);
        }
      })
      .catch(() => {
        console.error("Error:");
      })
  }

  const { t } = useTranslation();
  return (
    <div className="flex justify-center min-h-screen bg-gradient-to-b from-[#50BEFF] to-[#019DF7]">
      <div className='screen-size flex flex-row p-[20px] w-full'>
        <div className="w-1/2 flex flex-col justify-center">
          <img
            className="w-full h-auto object-contain"
            src={signup}
            alt="signup"
          />
        </div>
        <div className="w-full md:w-1/2 flex flex-col items-center justify-center gap-6 md:gap-10">
          <form onSubmit={handleSubmit} className="bg-[#FFFFFF] rounded-2xl px-[64px] py-[120px] flex flex-col gap-[40px] min-w-[500px]">
            <div className='flex flex-col text-center gap-[8px]'>
              <h2 className="text-[28px] font-bold leading-[38.5px]">{t('changepwd.changepassword')}</h2>
            </div>
            <div className='flex flex-col gap-[10px]'>


              <div className="relative">
                <FloatingLabelInput
                  id="newpassword_float_label"
                  type={showPassword ? "text" : "password"}
                  label={<> {t('signin.password')} <span className='required_star'>*</span> </>}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  error={errors.newPassword}
                />
                <EyeIcon onClick={togglePasswordVisibility} isPasswordVisible={showPassword} />
              </div>
              {errors.newPassword && <p className="text-red-500 text-sm">{errors.newPassword}</p>}

              <div className="relative">
                <FloatingLabelInput
                  id="confirmNewPassword_float_label"
                  type={showConfirmPassword ? "text" : "password"}
                  label={<> {t('signup.confirmpwd')} <span className='required_star'>*</span> </>}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  error={errors.confirmPassword}
                />
                <EyeIcon onClick={toggleNewPasswordVisibility} isPasswordVisible={showPassword} />
              </div>
              {errors.confirmPassword && <p className="text-red-500 text-sm">{errors.confirmPassword}</p>}
            </div>
            <div className='flex flex-col gap-[8px]'>
              <button type="submit" className="bg-[#019DF7] text-[#FFFFFF] py-3 px-8 rounded w-full">
                {t('changepwd.confirm')}
              </button>
              <Link to="/signin">
                <button type="button" className="border border-[#019DF7] text-[#019DF7] py-3 px-8 rounded w-full">
                  {t('forgotpassword.cancel')}
                </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
