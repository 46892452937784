import axios from 'axios';

// Base URL from environment variables
const baseURL = process.env.REACT_APP_BASE_URL || 'http://localhost:5000/api';

// Create a centralized Axios instance
const api = axios.create({
  baseURL: baseURL,
  timeout: 10000, // Set timeout for requests
});

// Interceptors for requests
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Interceptors for responses
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      console.warn('Unauthorized! Redirecting to login...');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default api;
