import * as React from "react";
import { Box } from "@mui/material";
import {MenuItem} from "@mui/material";
import {FormControl} from "@mui/material";
import { SelectChangeEvent } from "@mui/material";
import {Select} from "@mui/material";

interface DropDownProps {
  options: Array<{ value: string; label: string }>; 
  placeholder?: string; 
  value: string;
  required?: boolean 
  onChange: (event: SelectChangeEvent) => void; 
}

const DropDown: React.FC<DropDownProps> = ({
  options,
  placeholder ,
  value,
  required= false,
  onChange,
}) => {
  return (
    <Box sx={{ minWidth: 120, width: 342, height: 64 }} >
      <FormControl fullWidth variant="outlined">
        <Select
          required={required}
          value={value}
          onChange={onChange}
          displayEmpty
          renderValue={(selected) => selected || placeholder}
          sx={{
            height: "40px",
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "gray", 
            },
            "& .MuiSelect-select": {
              padding: "16px 14px",
            },
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default DropDown;
