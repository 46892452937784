import React, { FC, ReactNode } from 'react';

interface FloatingLabelSelectProps {
    id: string;
    label: ReactNode;
    options: { value: string; label: string }[];
    value: string;
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    error?: string;
    disabled?: boolean;
    buttonText?: string;
    onButtonClick?: () => void;
    [key: string]: any;
}

const FloatingLabelSelect: FC<FloatingLabelSelectProps> = ({
    id,
    label,
    options,
    value,
    onChange = () => {},
    error = '',
    disabled = false,
    buttonText = '',
    onButtonClick,
    ...props
}) => {
    return (
        <div className="relative w-full">
            <select
                id={id}
                className={`block p-[12px] text-black w-full rounded border focus:outline-none focus:ring-0 peer ${error ? 'border-red-500' : 'border-gray-300'} 
                    ${disabled || buttonText ? 'bg-white cursor-not-allowed' : ''} peer-placeholder-shown:text-[#999999]`}
                value={value}
                onChange={onChange}
                disabled={disabled}
                {...props}
            >
                <option value="" disabled hidden>
                    {label}
                </option>
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            <label
                htmlFor={id}
                className={`absolute text-sm duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 
                    left-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2
                    peer-placeholder-shown:text-[#999999] peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:text-black`}
            >
                {label}
            </label>
            {buttonText && (
                <button
                    onClick={onButtonClick}
                    type="button"
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 text-[#019DF7] font-semibold"
                >
                    {buttonText}
                </button>
            )}
        </div>
    );
};

export default FloatingLabelSelect;
