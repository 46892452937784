import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import { MdArrowDropDown } from "react-icons/md";
import { VscSettings } from "react-icons/vsc";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import NoDataFound from "../../components/common/NoDataFound";
import { RiArrowLeftSLine } from "react-icons/ri";
import SettlementsServices from "../../services/settlementsServices";
import Helpers from "../../utils/helpers";
import { Loader } from "rsuite";
import FloatingLabelInput from "../../components/common/FloatingLabelInput";
import { formatCurrency } from "../../utils/formatCurrency";

export default function Historydetail() {
  const { t } = useTranslation();
  // const { id } = useParams();

  const location = useLocation();
  const { operatorId, SettlementID } = location.state || {};

  const [transactionId, settransactionId] = useState("");
  const [bankName, setbankname] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSortDropdownOpen, setSortDropdownOpen] = useState(false);
  const [isFilterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [isExportDropdownOpen, setExportDropdownOpen] = useState(false);
  const [wallet, setWallet]: any = useState([]);
  const [settlementsBreakDown, setSettlementsBreakDown] = useState([]);
  const [settlementsDetails, setSettlementsDetails] = useState<any>();
  const [allsettle, setallsettle] = useState([]);


  const navigate = useNavigate();
  const [selectedSort, setSelectedSort] = useState(t("Wallet.Latest"));
  const [first, setFirst] = useState(0);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);

  const filterDropdownRef = useRef<HTMLDivElement>(null);
  const SortDropdownRef = useRef<HTMLDivElement>(null);
  const ExportDropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    console.log(operatorId, SettlementID, "operator and settlement ID")
    fetchHistoryDetails("DESC", "");
    document.addEventListener("mousedown", handleClickOutside);
    return () => { document.removeEventListener("mousedown", handleClickOutside); };
  }, [page]);


  useEffect(() => {



  }, []);

  const onPageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    setRows(event.rows);
    setPage(event.page);
  };

  const fetchHistoryDetails = async (sort_order: string, transactionIdflg: any) => {
    setLoading(true);
    try {
      const res = await SettlementsServices.fetchSettlementsHistoryDetails(operatorId, SettlementID, page + 1, sort_order, transactionIdflg === "" ? "" : transactionId);
      console.log(res, "this is res");
      const sp_breakdownData = res.data.sp_breakdown.map(
        (item: any, idx: any) => ({
          ...item,
          index: idx + 1,
          end_date: Helpers.formatDateString(item.end_date),
          description: item.description || "-",
        })
      );
      const settlementsDetails = res.data.upcomingSettlement;
      settlementsDetails.next_settlement_date = Helpers.formatDateString(
        settlementsDetails.next_settlement_date
      );
      setSettlementsBreakDown(sp_breakdownData);
      setSettlementsDetails(settlementsDetails);
      setTotalRecords(res.data.pagination.total_records);
    } catch (error) {
      console.error("error fetching settlements history details", error);
    } finally {
      setLoading(false);
    }
  };

  console.log(settlementsBreakDown, "hello", settlementsDetails);

  const statusTemplate = (rowData: any) => {
    const statusColor =
      rowData.settlement_status === "COMPLETED"
        ? "text-[#15B565]"
        : rowData.settlement_status === "PENDING"
          ? "text-[#E55959]"
          : "text-[#da3636]";
    return <span className={statusColor}>{rowData.settlement_status}</span>;
  };

  const columns: any = [
    {
      field: "index",
      label: t("Settlements.Sno"),
      sortable: true,
    },
    {
      field: "end_date",
      label: t("Settlements.SettlementDateandTime"),
      sortable: true,
    },
    {
      field: "transaction_id",
      label: t("Wallet.TranID"),
      sortable: true,
    },
    {
      field: "bank_name",
      label: t("Settlements.BankName"),
      sortable: true,
    },
    {
      label: t("Settlements.Description"),
      field: "description",
      sortable: true,
    },
    {
      label: t("Settlements.SettlementAmt"),
      field: "amount",
      sortable: true,
      body: (rowData: any) => (
        <span className="text-green-500">
          <span>$</span>
          {formatCurrency(rowData.amount)}
        </span>
      ),
    },
    {
      label: t("Booking.Status"),
      field: "settlement_status",
      sortable: true,
      body: statusTemplate,
    },
  ];

  const toggleSortDropdown = () => {
    setSortDropdownOpen(!isSortDropdownOpen);
    setFilterDropdownOpen(false);
    setExportDropdownOpen(false);
  };

  const toggleFilterDropdown = () => {
    setFilterDropdownOpen(!isFilterDropdownOpen);
    setSortDropdownOpen(false);
    setExportDropdownOpen(false);
  };

  const toggleExportDropdown = () => {
    setExportDropdownOpen(!isExportDropdownOpen);
    setSortDropdownOpen(false);
    setFilterDropdownOpen(false);
  };

  const handleSortChange = (sortOption: string) => {
    setSelectedSort(sortOption);
    setSortDropdownOpen(false);

    const sortOrder = sortOption === t('Wallet.Latest') ? "DESC" : "ASC";
    fetchHistoryDetails(sortOrder, "");
    setFilterDropdownOpen(false);
  };

  const clearAllFields = () => {
    settransactionId("");
    fetchHistoryDetails("", "");
  };

  const exportToExcel = () => {
    const formattedData = wallet.map((item: any) => ({
      Sno: item.id,
      DateandTime: item.dateTime,
      TransactionID: item.transactionId,
      BankName: item.bankName,
      Description: item.description,
      SettlementAmount: item.total,
    }));
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      "Serviceprovider_Settlement"
    );
    XLSX.writeFile(workbook, "Serviceprovider_Settlement.xlsx");
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (filterDropdownRef.current && !filterDropdownRef.current.contains(event.target as Node)) {
      setFilterDropdownOpen(false);
    }
    if (ExportDropdownRef.current && !ExportDropdownRef.current.contains(event.target as Node)) {
      setExportDropdownOpen(false);
    }
    if (SortDropdownRef.current && !SortDropdownRef.current.contains(event.target as Node)) {
      setSortDropdownOpen(false);
    }
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableColumn = columns.map((col: any) => col.label);
    const tableRows = wallet.map((item: any) => [
      item.id,
      item.dateTime,
      item.transactionId,
      item.bankName,
      item.description,
      item.total,
    ]);

    autoTable(doc, {
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      styles: { fontSize: 10 },
      headStyles: {
        fillColor: [231, 246, 254],
        textColor: [0, 0, 0],
      },
    });
    doc.save("Serviceprovider_Settlement.pdf");
  };


  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };
  const onApply = () => {
    fetchHistoryDetails("DESC", transactionId);
    setFilterDropdownOpen(false);
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center gap-2 ">
        <button
          className="border border-black text-black px-6 py-2 rounded flex items-center"
          onClick={handleBackClick}
        >
          <span className="flex items-center">
            <RiArrowLeftSLine className="mr-1" />
            {t("emailverify.back")}
          </span>
        </button>
        <span className="custom-page-heading px-2 py-2 mb-[16px]">
          {SettlementID || "-"}
        </span>
      </div>
      <div className="p-[24px] bg-[#ffffff] mt-[15px] rounded-[8px] mb-[16px]">
        <section>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-4 gap-x-8">
            <div className="flex flex-col gap-[4px]">
              <span className="text-[14px] font-semibold leading-[21px] text-[#3E4044">
                {t("Settlements.tltsettledamt")}
              </span>
              <span className="text-[14px] font-normal leading-[21px] text-[#6C6C6C]">
                {settlementsDetails?.total_amount_to_be_settled ? `${formatCurrency(settlementsDetails.total_amount_to_be_settled)}` : "-"}

              </span>
            </div>
            <div className="flex flex-col gap-[4px]">
              <span className="text-[14px] font-semibold leading-[21px] text-[#3E4044">
                {t("ServiceProvider.CurrentWalletAmount")}
              </span>
              <span className="text-[14px] font-normal leading-[21px] text-[#6C6C6C]">
                {settlementsDetails?.wallet_balance ? `${formatCurrency(settlementsDetails.wallet_balance)}` : "-"}
              </span>
            </div>
            <div className="flex flex-col gap-[4px]">
              <span className="text-[14px] font-semibold leading-[21px] text-[#3E4044">
                {t("Settlements.NxtSettlement")}
              </span>
              <span className="text-[14px] font-normal leading-[21px] text-[#6C6C6C]">
                {settlementsDetails?.next_settlement_date || "-"}
              </span>
            </div>
            <div className="flex flex-col gap-[4px]">
              <span className="text-[14px] font-semibold leading-[21px] text-[#3E4044">
                {t("Settlements.SettlementCycle")}
              </span>
              <span className="text-[14px] font-normal leading-[21px] text-[#6C6C6C]">
                {settlementsDetails?.settlement_cycle || "-"}
              </span>
            </div>
            <div className="flex flex-col gap-[4px]">
              <span className="text-[14px] font-semibold leading-[21px] text-[#3E4044">
                {t("Operators.PayOutDay")}
              </span>
              <span className="text-[14px] font-normal leading-[21px] text-[#6C6C6C]">
                {settlementsDetails?.payout_day || "-"}
              </span>
            </div>
          </div>
        </section>
      </div>
      {/* Datatable */}
      <div className="flex flex-col ">
        <div className="flex justify-between items-center">
          <span className="custom-page-heading mb-[16px]">
            {t("ServiceProvider.SettlementsList")}
          </span>
          <div className="flex gap-[8px]">
            {/* Sort by Latest Button */}
            <div className="relative">
              <button
                className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
                onClick={toggleSortDropdown}
              >
                {t("Wallet.SortBy")}: {selectedSort}
                <MdArrowDropDown />
              </button>
              {isSortDropdownOpen && (
                <div className="absolute bg-white border border-gray-300 rounded shadow-md mt-[5px] w-[130px] z-[500]">
                  <button
                    className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100 border-b"
                    onClick={() => handleSortChange(t("Wallet.Latest"))}
                  >
                    <span className="text-[16px] font-normal leading-[24px]">
                      {t("Wallet.Latest")}
                    </span>
                  </button>
                  <button
                    className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100"
                    onClick={() => handleSortChange(t("Wallet.Oldest"))}
                  >
                    <span className="text-[16px] font-normal leading-[24px]">
                      {t("Wallet.Oldest")}
                    </span>
                  </button>
                </div>
              )}
            </div>

            {/* Filter Dropdown */}
            <div className="relative">
              <button
                className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
                onClick={toggleFilterDropdown}
              >
                {t("Wallet.Filter")}
                <VscSettings />
              </button>
              {isFilterDropdownOpen && (
                <div className="absolute flex flex-col right-[-85px] gap-[20px] bg-white border border-gray-300 rounded shadow-md mt-[5px] p-5 w-[390px] z-[500]">
                  <div className="text-[#3E4044] text-[18px] font-medium leading-[27px]">
                    {t("Wallet.Filter")}
                  </div>
                  <div className="text-[14px] leading-[24px] font-normal text-[#00000099]">
                    <FloatingLabelInput
                      id="email_float_label"
                      type="text"
                      label={t('Filter.TransactionsID')}
                      value={transactionId}
                      onChange={(e: any) => settransactionId(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col gap-[16px]">
                    <button
                      className="block w-full text-center px-[8px] py-[8px]"
                      onClick={clearAllFields}
                    >
                      {t('Filter.ClearAll')}
                    </button>
                    <button
                      className="block w-full text-center px-[8px] py-[8px] bg-[#2196F3] text-white"
                      onClick={onApply}
                    >
                      {t('Filter.Apply')}
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="relative">
              <button
                className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
                onClick={toggleExportDropdown}
              >
                {t("Wallet.Export")}
                <MdArrowDropDown />
              </button>
              {isExportDropdownOpen && (
                <div className="absolute right-0 bg-white border border-gray-300 rounded shadow-md mt-[5px] w-[160px] z-[500]">
                  <button
                    className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100 border-b"
                    onClick={() => {
                      setExportDropdownOpen(false);
                      exportToPDF();
                    }}
                  >
                    <span className="text-[16px] font-normal leading-[24px]">
                      {t("Export.ExportASPdf")}
                    </span>
                  </button>
                  <button
                    className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100"
                    onClick={() => {
                      setExportDropdownOpen(false);
                      exportToExcel();
                    }}
                  >
                    <span className="text-[16px] font-normal leading-[24px]">
                      {t("Export.ExportASXlsx")}
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {loading ? (
          <div className="mt-40">
            {" "}
            <Loader />
          </div>
        ) : settlementsBreakDown.length === 0 ? (
          <NoDataFound />
        ) : (
          <>
            <DataTable value={settlementsBreakDown} rows={10} >
              {columns.map((item: any) => (
                <Column
                  field={item.field}
                  header={item.label}
                  headerClassName="custom-header-bg"
                  className="custom-col-bg"
                  sortable={item.sortable}
                  body={item.body}

                />
              ))}
            </DataTable>
            <Paginator
              first={first}
              rows={rows}
              totalRecords={totalRecords}
              onPageChange={onPageChange}
            />
          </>
        )}
      </div>
    </div>
  );
}
