import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import signup from './../../assets/signup.png';
import Group from './../../assets/Group.png';
import { useTranslation } from 'react-i18next';
import authService from '../../services/authService';

export default function EmailVerification() {
    const navigate = useNavigate();
    const [isTimerActive, setIsTimerActive] = useState(false);
    const [timer, setTimer] = useState(10);

    const location = useLocation();
    const { email } = location.state || {};

    useEffect(() => {
        if (isTimerActive && timer > 0) {
            const interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
            return () => clearInterval(interval);
        } else if (timer === 0) {
            setIsTimerActive(false);
        }
    }, [isTimerActive, timer]);

    const handleResendClick = () => {
        ReSentPassword();
    };

    const ReSentPassword = () => {
        authService.ForgotPassword(email)
            .then((response) => {
                console.log(response, "Response");
                if (response.status === 200) {
                    setIsTimerActive(true);
                    setTimer(60);
                    console.log("email Verified")
                }
            })
            .catch(() => {
                console.error("Error:");
            })
    }

    const { t } = useTranslation();
    return (
        <div className="flex justify-center min-h-screen bg-gradient-to-b from-[#50BEFF] to-[#019DF7]">
            <div className='screen-size flex flex-row p-[20px] w-full'>
                <div className="w-1/2 flex flex-col justify-center">
                    <img
                        className="w-full h-auto object-contain"
                        src={signup}
                        alt=""
                    />
                </div>

                <div className="w-full md:w-1/2 flex flex-col items-center justify-center gap-6 md:gap-10">

                    <form className="bg-[#FFFFFF] rounded-2xl px-[64px] py-[165px] flex flex-col gap-[40px] min-w-[500px]">

                        <div className='flex flex-col text-center gap-[8px]'>

                            <img
                                className="w-full h-[60px] object-contain"
                                src={Group}
                                alt=""
                            />
                            <h2 className="text-[28px] font-bold leading-[38.5px]">{t('emailverify.emailsent')}</h2>
                            <div className='text-[#808080] text-[14px] font-normal leading-[21px]'>
                                {t('emailverify.confirmationemail')} {email}.<br />
                                {t('emailverify.plsclick')}
                            </div>
                            <div className="flex text-[#019DF7] text-sm justify-center items-center cursor-pointer" onClick={() => navigate(-1)}>
                                {t('emailverify.editemail')}
                            </div>
                        </div>
                        <div className="flex text-[black] text-sm justify-center items-center cursor-pointer margin-top-[10]">
                            {isTimerActive ? (
                                <div className="flex flex-col items-center gap-1 text-center text-sm text-gray-600">
                                    <label className="text-[#E28A11] font-medium">
                                        {t('emailverify.rstsnt')}
                                    </label>
                                    <label>
                                        {t('emailverify.Youcan')}
                                        <span className="text-[#019DF7] font-semibold">
                                            {` 00:${timer.toString().padStart(2, '0')}`}
                                        </span>
                                    </label>
                                </div>
                            ) : (
                                <>
                                    {t('emailverify.notrecemail')}
                                    <span
                                        onClick={handleResendClick}
                                        className="text-[#019DF7] mx-1"
                                    >
                                        {t('emailverify.resend')}
                                    </span>
                                </>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
