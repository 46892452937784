import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import signup from './../../assets/signup.png';
import EyeIcon from '../../components/common/EyeIcon';
import { useTranslation } from 'react-i18next';
import { ProgressSpinner } from 'primereact/progressspinner';
import authService from '../../services/authService';
import FloatingLabelInput from '../../components/common/FloatingLabelInput';

const Signin = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({ email: '', password: '' });
  const [apiError, setApiError] = useState('');

  useEffect(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("forgotpwdemail");
    localStorage.removeItem("userId");
    localStorage.removeItem("email");
  }, []);

  const handleForgotPasswordClick = () => {
    navigate('/forgot-password');
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateForm = () => {
    let formIsValid = true;
    const newErrors = { email: '', password: '' };
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      newErrors.email = t('signin.emailrequired');
      formIsValid = false;
    }
    else if (!email.match(emailPattern)) {
      newErrors.email = t('signin.invalidemail');
      formIsValid = false;
    }
    if (!password) {
      newErrors.password = t('signin.passwordrequired');
      formIsValid = false;
    }
    setErrors(newErrors);
    return formIsValid;
  };


  const onSubmit = () => {
    setLoading(true); // Start loading

    authService.SignIn(email, password)
      .then((response) => {
        console.log("Response object:", response);

        const { status, data } = response; // Destructure Axios response
        if (status === 200) {
          const { accessToken, user } = data;
          localStorage.setItem("token", accessToken);
          localStorage.setItem("userId", user.id.toString());
          localStorage.setItem("email", user.email);
          localStorage.setItem("name", user.name);

          navigate('/bookings'); // Navigate to the bookings page
        } else {
          console.log(data)
          setApiError(data?.message || 'Unexpected response structure.');
        }
      })
      .catch((error) => {
        console.error("Error logging in:", error);

        const errorMessage = error.message ||
          'An error occurred. Please try again.';

        setApiError(errorMessage);
      })
      .finally(() => {
        setLoading(false); // Stop loading
      });
  };

  const handleSignIn = (e: any) => {
    e.preventDefault();
    setApiError('');
    if (validateForm()) {
      setLoading(true);
      onSubmit();
    }
  };

  return (
    <div className="flex justify-center min-h-screen bg-gradient-to-b from-[#50BEFF] to-[#019DF7]">
      <div className='screen-size flex flex-row p-[20px] w-full'>
        <div className="w-1/2 flex flex-col justify-center">
          <img
            className="w-full h-auto object-contain"
            src={signup}
            alt="signin"
          />
        </div>
        <div className="w-full md:w-1/2 flex flex-col items-center justify-center gap-6 md:gap-10">
          <form onSubmit={handleSignIn} className="bg-[#FFFFFF] rounded-2xl px-[64px] py-[105px] flex flex-col gap-[40px] min-w-[500px]">
            <div className='flex flex-col text-center gap-[8px]'>
              <h2 className="text-[32px] font-bold leading-[38.5px]">{t('signin.signin')}</h2>
            </div>
            <div className='flex flex-col gap-[10px]'>
              <FloatingLabelInput
                id="email_float_label"
                type="email"
                label={<> {t('signin.emailaddress')} <span className='required_star'>*</span> </>}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (errors.email) setErrors({ ...errors, email: '' });
                }}
                error={errors.email}
              />
              {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}

              <div className="relative">
                <FloatingLabelInput
                  id="password_float_label"
                  type={showPassword ? "text" : "password"}
                  label={<> {t('signin.password')} <span className='required_star'>*</span> </>}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    if (errors.password) setErrors({ ...errors, password: '' });
                  }}
                  error={errors.password}
                />
                <EyeIcon onClick={togglePasswordVisibility} isPasswordVisible={showPassword} />
              </div>
              {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}
            </div>

            <div className="flex flex-col gap-4 md:gap-6">
              {apiError && <p className="text-red-500 text-[12px] text-center mt-2">{apiError}</p>}

              <button type="submit" className="bg-[#019DF7] h-[53px] text-white py-3 px-8 rounded w-full">
                {loading ? <ProgressSpinner style={{ width: '20px', height: '20px', color: '#FFFFFF' }} strokeWidth="5" /> : t('signin.signin')}
              </button>
              <div
                className="flex text-[#019DF7] text-sm justify-center items-center cursor-pointer w-full"
                onClick={handleForgotPasswordClick}
              >
                <span tabIndex={0}>{t('signin.forgotpassword')}</span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signin;
