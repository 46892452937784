import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { RiArrowLeftSLine } from "react-icons/ri";
import {
  useNavigate,
  useParams,
  Outlet,
  Link,
  useLocation,
} from "react-router-dom";
import pdf from "../../../assets/pdf.png";
import editIcon from "../../../assets/edit_1.svg";
import { decryptParams } from "../../../utils/cryptoUtils";
import operatorService from "../../../services/operatorServices";
import EyeIcon from "../../../components/common/EyeIcon";
import PersonalDetails from "../../../components/Modal/Personaldetails";
import Modal from "../../../components/common/Modal";
import BusinessDetails from "../../../components/Modal/Businessdetails";
import Apiconfiguration from "../../../components/Modal/Apicpnfiguration";
import { ProgressSpinner } from 'primereact/progressspinner';
import toast, { Toaster } from "react-hot-toast";

export interface OperatorDetails {
  operator_id: number | null;
  name: string;
  address_1: string | null;
  address_2: string | null;
  city: string | null;
  state: string | null;
  zipcode: string | null;
  company_name: string | null;
  mobile_number: string;
  email_id: string | null;
  registration_number: string | null;
  contract_status: string | null;
  contract_signed_document_path: string | null;
  account_status: string | null;
  onboard_date: string | null;
  created_datetime: string | null;
  updated_datetime: string | null;
  created_by: string | null;
  updated_by: string | null;
  is_email_verified: boolean;
  password: string | null;
  business_name: string | null;
  business_type: string | null;
  country_of_incorporation: string | null;
  tax_registration_number: string | null;
  director_name: string | null;
  business_reg_document_path: string | null;
  tax_reg_document_path: string | null;
  id_proof_document_path: string | null;
  address_proof_document_path: string | null;
  onboarding_status: string | null;
  current_step: number | null;
  is_golive_ready: boolean;
  payment_gateway_key: string | null;
  is_basic_details_submitted: boolean | null;
  is_contract_doc_submitted: boolean | null;
  is_onboard_payment_submitted: boolean | null;
  wallet_balance: number | null;
  is_api_keys_submitted: boolean | null;
  reason: string | null;
  logo_path: string | null;
}


const initialOperatorDetails = {
  operator_id: null,
  name: "",
  address_1: "",
  address_2: "",
  city: "",
  state: "",
  zipcode: "",
  company_name: "",
  mobile_number: "",
  email_id: "",
  registration_number: "",
  contract_status: "",
  contract_signed_document_path: "",
  account_status: "",
  onboard_date: "",
  created_datetime: "",
  updated_datetime: "",
  created_by: "",
  updated_by: "",
  is_email_verified: false,
  password: "",
  business_name: "",
  business_type: "",
  country_of_incorporation: "",
  tax_registration_number: "",
  director_name: "",
  business_reg_document_path: "",
  tax_reg_document_path: "",
  id_proof_document_path: "",
  address_proof_document_path: "",
  onboarding_status: "",
  current_step: null,
  is_golive_ready: false,
  payment_gateway_key: "",
  is_basic_details_submitted: null,
  is_contract_doc_submitted: null,
  is_onboard_payment_submitted: null,
  wallet_balance: null,
  is_api_keys_submitted: null,
  reason: "",
  logo_path: "",
};


export default function OperatorDetails() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false)

  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);
  const getMaskedValue = (key: string) => "*".repeat(key?.length);

  const { encryptedParam } = useParams();
  const [operatorDetails, setOperatorDetails]: any = useState<OperatorDetails>(
    initialOperatorDetails
  );

  const encodedEncryptedParam = encodeURIComponent(encryptedParam || "");
  const isBasicDetailsActive =
    location.pathname === `/operator-details/${encodedEncryptedParam}`;

  useEffect(() => {
    if (encryptedParam) {
      const decodedParam = decodeURIComponent(encryptedParam).replace(
        /\s/g,
        "+"
      );
      const decryptedParam: any = decryptParams(decodedParam);

      if (decryptedParam) {
        console.log(decryptedParam)
        operatorService
          .getOperatorById(decryptedParam?.operatorId)
          .then((res) => {
            console.log("Fetched Operator Details:", res);
            setOperatorDetails(res.data.data);
          })
          .catch((error) => {
            console.error("Error fetching operator details:", error);
          });
      }
    }
  }, [encryptedParam]);

  const handleSaveClick = async () => {

    setLoading(true)
    try {
      const updatedData = {
        operator_id: operatorDetails.operator_id,
        name: operatorDetails.name,
        city: operatorDetails.city,
        state: operatorDetails.state,
        zipcode: operatorDetails.zipcode,
        company_name: operatorDetails.company_name,
        business_name: operatorDetails.business_name,
        business_type: operatorDetails.business_type,
        registration_number: operatorDetails.registration_number,
        address_1: operatorDetails.address_1,
        address_2: operatorDetails.address_2,
        country_of_incorporation: operatorDetails.country_of_incorporation,
        tax_registration_number: operatorDetails.tax_registration_number,
        director_name: operatorDetails.director_name,
        mobilenumber: operatorDetails.mobile_number,
        business_reg_path: operatorDetails.business_reg_document_path,
        tax_reg_path: operatorDetails.tax_reg_document_path,
        id_proof_path: operatorDetails.id_proof_document_path,
        address_proof_path: operatorDetails.address_proof_document_path,
        is_active: operatorDetails.account_status,
        email: operatorDetails.email_id,
        is_email_verified: operatorDetails.is_email_verified,
        onboarding_status: operatorDetails.onboarding_status,
        current_step: operatorDetails.current_step,
        is_golive_ready: operatorDetails.is_golive_ready,
        is_basic_details_submitted: operatorDetails.is_basic_details_submitted,
        is_contract_doc_submitted: operatorDetails.is_contract_doc_submitted,
        is_onboard_payment_submitted: operatorDetails.is_onboard_payment_submitted,
        wallet_balance: operatorDetails.wallet_balance,
        is_api_keys_submitted: operatorDetails.is_api_keys_submitted,
        reason: operatorDetails.reason,
        logo_path: operatorDetails.logo_path,
        acquisition_key: operatorDetails.payment_gateway_key,
        disbursal_key: operatorDetails.disbursal_key
      };

      const res = await operatorService.updateOperatorDetails(updatedData)
      console.log(res.status, "console")
      if (res.status === 200) {
        console.log(res, "Operator Updated")
        toast.success("Operator Updated");
      }


    } catch (error) {
      console.error('Error updating operator details:', error)
    } finally {
      setLoading(false)
    }

  };
  const [selectedSection, setSelectedSection] = useState<string | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [activeModal, setActiveModal] = useState<'confirm' | 'section' | 'success' | 'done' | null>(null);

  const personalDetailsRef: any = useRef(null);
  const businessDetailsRef: any = useRef(null);
  const apiDetailsRef: any = useRef(null);
  const handleSure = (section: string, title: string) => {
    setSelectedSection(section);
    setActiveModal('section');
    setModalTitle(title);
    setModalVisible(true);
  };
  const handlePersonaldetailsClick = () => handleSure('personal', t('Personal Details'));
  const handlebusinessdetailsClick = () => handleSure('business', t('Business Details'));
  const handleapiconfigClick = () => handleSure('apiconfig', t('Api Configuration'));
  const getModalContent = () => {

    if (activeModal === 'section') {
      switch (selectedSection) {
        case 'personal':
          return <PersonalDetails ref={personalDetailsRef} />;
        case 'business':
          return <BusinessDetails ref={businessDetailsRef} />;
        case 'apiconfig':
          return <Apiconfiguration ref={apiDetailsRef} />;
        default:
          return null;
      }
    }

  };
  const handleUpdate = () => {
    console.log(personalDetailsRef, "buiugi")
    if (selectedSection === "personal") {
      if (personalDetailsRef.current) {
        const details = personalDetailsRef.current.getDetails();
        const data = {
          name: details.name,
          mobile_number: details.mobileNumber,
          email_id: details.email,
        }
        setOperatorDetails({
          ...operatorDetails,
          ...data,
        });
        setSelectedSection('');
        setModalTitle('');
        setModalVisible(false);
      }
    }
  }
  const handlebusinessUpdate = () => {
    console.log(businessDetailsRef, "buiugi")
    if (selectedSection === "business") {
      if (businessDetailsRef.current) {
        const businessdetails = businessDetailsRef.current.getDetails();
        console.log(businessDetailsRef, "bussiness details");
        const data = {

          business_name: businessdetails.bussinessname,
          business_type: businessdetails.type,
          registration_number: businessdetails.registernum,
          address_1: businessdetails.regaddress,
          country_of_incorporation: businessdetails.country,
          tax_registration_number: businessdetails.taxnumber,
          director_name: businessdetails.directorName,
          business_reg_document_path: businessdetails.businesscontact
        }
        setOperatorDetails({
          ...operatorDetails,
          ...data,
        });
        setSelectedSection('');
        setModalTitle('');
        setModalVisible(false);
      }
    }
  }
  const handleApiConfigUpdate = () => {
    if (selectedSection === "apiconfig") {
      if (apiDetailsRef.current) {
        const apiDetails = apiDetailsRef.current.getDetails();
        const data = {
          payment_gateway_key: apiDetails.acquisition_key,
          disbursal_key: apiDetails.disbursal_key
        }
        setOperatorDetails({
          ...operatorDetails,
          ...data,
        });
        setSelectedSection('');
        setModalTitle('');
        setModalVisible(false);
      }
    }
  }
  const handleupdateClick = () => {
    setModalVisible(true);
  };

  const getFooterContent = () => {

    return (
      <div className='settings-modal-button'>
        <button className='settings-modal-cancel-button' onClick={() => setModalVisible(false)}>{t('Settings.cancel')}</button>
        <button
          className="settings-modal-success-button"
          onClick={() => {
            if (selectedSection === "personal") {
              handleUpdate(); // Call the personal update function
            } else if (selectedSection === "business") {
              handlebusinessUpdate(); // Call the business update function
            } else if (selectedSection === 'apiconfig') {
              handleApiConfigUpdate()
            }
          }}
        >
          {t('Update')}
        </button>
      </div>
    );

  }
  return (
    <div className="flex flex-col gap-[30px]">
      <div className="flex flex-row items-center gap-2">
        <button
          className="border border-black text-black px-6 py-2 rounded flex items-center"
          onClick={() => navigate("/operators/approved")}
        >
          <span className="flex items-center">
            <RiArrowLeftSLine className="mr-1" />
            {t("emailverify.back")}
          </span>
        </button>
        <span className="custom-page-heading px-2 py-2">
          {operatorDetails.operator_id + " " + "-" + operatorDetails.name}
        </span>
      </div>

      <div className="flex flex-row gap-[16px] text-[14px] text-[#667085] border-b border-[#BDBDBD] font-semibold leading-[17px]">
        <div
          className={`cursor-pointer hover:text-[#03A9F4]  pt-[1px] pb-[12px] ${isBasicDetailsActive
            ? "text-[#03A9F4]  border-b-2 border-[#03A9F4]"
            : ""
            }`}
          onClick={() => navigate(`/operator-details/${encodedEncryptedParam}`)}
        >
          {t("ServiceProvider.BasicDetails")}
        </div>
        <Link
          to="service-providers"
          className={`cursor-pointer hover:text-[#03A9F4] px-[4px] pt-[1px] pb-[12px] ${location.pathname.includes("service-providers")
            ? "text-[#03A9F4]  border-b-2 border-[#03A9F4]"
            : ""
            }`}
        >
          {t("sidebar.service-providers")}
        </Link>
        <Link
          to="booking-section"
          className={`cursor-pointer hover:text-[#03A9F4] px-[4px] pt-[1px] pb-[12px] ${location.pathname.includes("booking-section")
            ? "text-[#03A9F4]  border-b-2 border-[#03A9F4]"
            : ""
            }`}
        >
          {t("sidebar.bookings")}
        </Link>
        <Link
          to="wallet-section"
          className={`cursor-pointer hover:text-[#03A9F4] px-[4px] pt-[1px] pb-[12px] ${location.pathname.includes("wallet")
            ? "text-[#03A9F4]  border-b-2 border-[#03A9F4]"
            : ""
            }`}
        >
          {t("sidebar.wallet")}
        </Link>
        <Link
          to="settlements"
          className={`cursor-pointer hover:text-[#03A9F4] px-[4px]  pt-[1px] pb-[12px] ${location.pathname.includes("settlements")
            ? "text-[#03A9F4]  border-b-2 border-[#03A9F4]"
            : ""
            }`}
        >
          {t("sidebar.settlements")}
        </Link>
      </div>

      {isBasicDetailsActive && (
        <div className="p-[24px] bg-[#ffffff] rounded-[8px]">
          {/* personal Deatils */}
          <div className="flex flex-col gap-y-[32px] pb-[38px]">
            <div className="flex flex-row justify-between items-center">
              <span className="text-primaryBlack">{t("Operators.PersonalDetails")}</span>
              <button onClick={handlePersonaldetailsClick} className="custom-edit-button">
                <img src={editIcon} alt="Edit Icon" />
                {t("Settings.Edit")}
              </button>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-4 gap-x-8">
              <DetailItem
                label={t("Operators.Id")}
                value={operatorDetails?.operator_id}
              />
              <DetailItem
                label={t("Operators.Name")}
                value={operatorDetails?.name}
              />
              <DetailItem
                label={t("ServiceProvider.PhoneNumber")}
                value={operatorDetails?.mobile_number}
              />
              <DetailItem
                label={t("ServiceProvider.EmailID")}
                value={operatorDetails?.email_id}
              />
            </div>
          </div>
          <hr />
          {/* Business Deatail */}
          <div className="flex flex-col gap-y-[32px] py-[24px]">
            <div className="flex flex-row justify-between items-center">
              <span className="text-primaryBlack">{t("Operators.BusinessDetails")}</span>
              <button onClick={handlebusinessdetailsClick} className="custom-edit-button">
                <img src={editIcon} alt="Edit Icon" />
                {t("Settings.Edit")}
              </button>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-4 gap-x-8 mb-8">
              <DetailItem
                label={t("Operators.Name")}
                value={operatorDetails?.business_name}
              />
              <DetailItem
                label={t("Operators.Type")}
                value={operatorDetails?.business_type}
              />
              <DetailItem
                label={t("Operators.RegistrationNumber")}
                value={operatorDetails?.registration_number}
              />
              <DetailItem
                label={t("Operators.RegistrationAddress")}
                value={operatorDetails?.address_1}
              />
              <DetailItem
                label={t("Operators.CountryOfIncorporation")}
                value={operatorDetails?.country_of_incorporation}
              />
              <DetailItem
                label={t("Operators.TaxRegistrationNumber")}
                value={operatorDetails?.tax_registration_number}
              />
              <DetailItem
                label={t("Operators.DirectorName")}
                value={operatorDetails?.director_name}
              />
              <DetailItem label={t("Operators.BusinessContactInfo")}
                value={operatorDetails?.mobile_number} />
            </div>
          </div>
          <hr />
          {/* upload Documents */}
          <div className="flex flex-col gap-y-[32px] py-[24px]">
            <div className="flex flex-row justify-between items-center">
              <span className="text-primaryBlack">{t("Operators.UploadedDocuments")}</span>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-10 gap-x-8 mb-8">
              <DetailItem
                label=""
                value={
                  <a
                    href={operatorDetails?.business_reg_document_path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-x-[16.52px] border border-1px-solid-black p-4 rounded-md"
                  >
                    <img
                      src={pdf}
                      alt="PDF Icon"
                      loading="lazy"
                      className="w-[34px] h-[34px]"
                    />
                    <span className="text-[15px] leading-[21px] text-[#6C6C6C]">
                      {t("Operators.BusinessRegistrationCertificate")}
                    </span>
                  </a>
                }
              />
              <DetailItem
                label=""
                value={
                  <a
                    href={operatorDetails?.tax_reg_document_path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-x-[16.52px] border border-1px-solid-black p-4 rounded-md"
                  >
                    <img
                      src={pdf}
                      alt="PDF Icon"
                      loading="lazy"
                      className="w-[34px] h-[34px]"
                    />
                    <span className="text-[15px] leading-[21px] text-[#6C6C6C]">
                      {t("Operators.TaxRegistrationDocument")}
                    </span>
                  </a>
                }
              />
              <DetailItem
                label=""
                value={
                  <a
                    href={operatorDetails?.id_proof_document_path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-x-[16.52px] border border-1px-solid-black p-4 rounded-md"
                  >
                    <img
                      src={pdf}
                      alt="PDF Icon"
                      loading="lazy"
                      className="w-[34px] h-[34px]"
                    />
                    <span className="text-[15px] leading-[21px] text-[#6C6C6C]">
                      {t("Operators.DirectorIDProof")}
                    </span>
                  </a>
                }
              />
              <DetailItem
                label=""
                value={
                  <a
                    href={operatorDetails?.address_proof_document_path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-x-[16.52px] border border-1px-solid-black p-4 rounded-md"
                  >
                    <img
                      src={pdf}
                      alt="PDF Icon"
                      loading="lazy"
                      className="w-[34px] h-[34px]"
                    />
                    <span className="text-[15px] leading-[21px] text-[#6C6C6C]">
                      {t("Operators.ProofAddress")}
                    </span>
                  </a>
                }
              />
            </div>
          </div>
          <hr />
          {/* contract detail */}
          <div className="flex flex-col gap-y-[32px] py-[24px]">
            <div className="flex flex-row justify-between items-center">
              <span className="text-primaryBlack">{t("Operators.ContractDetails")}</span>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-4 gap-x-8 mb-8">
              <DetailItem
                label=""
                value={
                  <a
                    href={operatorDetails?.contract_signed_document_path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-x-[16.52px] border border-1px-solid-black p-4 rounded-md"
                  >
                    <img
                      src={pdf}
                      alt="PDF Icon"
                      loading="lazy"
                      className="w-[34px] h-[34px]"
                    />
                    <span className="text-[15px] leading-[21px] text-[#6C6C6C]">
                      {t("Operators.contractPdf")}
                    </span>
                  </a>
                }
              />
            </div>
          </div>
          <hr />
          {/* payment detail */}
          <div className="flex flex-col gap-y-[32px] py-[24px]">
            <div className="flex flex-row justify-between items-center">
              <span className="text-primaryBlack">{t("BookingDetails.PaymentDetails")}</span>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-4 gap-x-8 mb-8">
              <DetailItem
                label={t("BusinessDetails.Type")}
                value="Bank Transfers"
              />
              <DetailItem
                label=""
                value={
                  <a
                    href={"pending from be"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-x-[16.52px] border border-1px-solid-black p-4 rounded-md"
                  >
                    <img
                      src={pdf}
                      alt="PDF Icon"
                      loading="lazy"
                      className="w-[34px] h-[34px]"
                    />
                    <span className="text-[15px] leading-[21px] text-[#6C6C6C]">
                      {t("Operators.PaymentReceipt")}
                    </span>
                  </a>
                }
              />
            </div>
          </div>
          <hr />
          {/* Api configuration */}
          <div className="flex flex-col gap-y-[32px] py-[24px]">
            <div className="flex flex-row justify-between items-center">
              <span className="text-primaryBlack">{t("Operators.APIConfigurationDetails")}</span>
              <button onClick={handleapiconfigClick} className="custom-edit-button">
                <img src={editIcon} alt="Edit Icon" />
                {t("Settings.Edit")}
              </button>
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6'>
              <div className="flex flex-col gap-1">
                <div className='custom-body3-semibold'>{t('Operators.PaymentAcquirerAPIKey')}</div>
                <div className='custom-body-regular'>{operatorDetails?.payment_gateway_key}</div>
              </div>
              <div className="flex flex-col gap-1">
                <div className='custom-body3-semibold'>{t('Settings.Apikey')}</div>
                <div className="flex items-center space-x-2">
                  <div className='relative flex custom-body-regular tracking-widest pr-2'>
                    <div className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap pr-7">
                      {isVisible ? operatorDetails?.disbursal_key : getMaskedValue(operatorDetails?.disbursal_key)}
                    </div>
                    <EyeIcon
                      onClick={toggleVisibility}
                      isPasswordVisible={isVisible}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full">
            <button
              className="w-20 h-10 bg-[#2196f3] text-[#ffffff] shadow-sm rounded-md float-right"
              onClick={handleSaveClick}
            >
              Save
            </button>
          </div>
        </div>
      )}

      {/* This is where child components will render */}
      <Outlet context={{ operatorDetails }} />
      <Modal
        visible={modalVisible}
        onHide={() => setModalVisible(false)}
        title={modalTitle}
        footer={getFooterContent()}
        content={getModalContent()}
      />
            <Toaster />
    </div>

  );
}

function DetailItem({
  label,
  value,
}: {
  label: string;
  value: React.ReactNode;
}) {
  return (
    <div className="flex flex-col gap-[4px]">
      <span className="custom-body3-semibold">
        {label}
      </span>
      <span className="custom-body-regular">
        {value}
      </span>
    </div>
  );
}
