import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import SettingService from "../../services/settingsService";

interface ErrorType {
  language: string;
  country: string;
  currency: string;
}

interface OptionType {
  id: number;
  name: string;
}

interface GeneralConfigProps {}

const GeneralConfiguration = forwardRef((props: GeneralConfigProps, ref) => {
  const { t } = useTranslation();

  const [languages, setLanguages] = useState<OptionType[]>([]);
  const [countries, setCountries] = useState<OptionType[]>([]);
  const [currencies, setCurrencies] = useState<OptionType[]>([]);

  // Define state variables for the selects
  const [language, setLanguage] = useState("");
  const [country, setCountry] = useState("");
  const [currency, setCurrency] = useState("");

  useEffect(() => {
    getGeneralConfigs();
  }, []);

  const getGeneralConfigs = async () => {
    try {
      const res = await SettingService.getGeneralConfig();
      console.log(res);
      setLanguages(
        res?.data?.languages.map((lang: any) => ({
          id: lang.so_language_id,
          name: lang.language_name,
        }))
      );
      setCountries(
        res?.data?.countries.map((country: any) => ({
          id: country.so_country_id,
          name: country.country_name,
        }))
      );
      setCurrencies(
        res?.data?.currencies.map((currency: any) => ({
          id: currency.so_currency_id,
          name: currency.currency_name,
        }))
      );

      // Set default values after fetching data
      if (res?.data?.languages.length > 0) setLanguage(res.data.languages[0].language_name);
      if (res?.data?.countries.length > 0) setCountry(res.data.countries[0].country_name);
      if (res?.data?.currencies.length > 0) setCurrency(res.data.currencies[0].currency_name);
    } catch (error) {
      console.error(error);
    }
  };

  // Handlers to update state when a selection is made
  const handleLanguageChange = (event: any) => setLanguage(event.target.value);
  const handleCountryChange = (event: any) => setCountry(event.target.value);
  const handleCurrencyChange = (event: any) => setCurrency(event.target.value);

  const validateForm = () => {
    let formIsValid = true;

    if (!language || !country || !currency) {
      formIsValid = false;
    }

    return {
      language,
      country,
      currency,
      formIsValid,
    };
  };

  useImperativeHandle(ref, () => ({
    validateForm,
  }));

  return (
    <div>
      {/* Language Select */}
      <label htmlFor="language" className="block input-label-settings">
        {t("Settings.language")}
      </label>
      <select
        id="language"
        value={language}
        onChange={handleLanguageChange}
        className="w-full mb-4 p-2 pl-[0.3rem] border rounded-md"
      >
        {languages.map((lang) => (
          <option key={lang.id} value={lang.name} className="text-[14px]">
            {lang.name}
          </option>
        ))}
      </select>

      {/* Country Select */}
      <label htmlFor="country" className="block input-label-settings">
        {t("Settings.country")}
      </label>
      <select
        id="country"
        value={country}
        onChange={handleCountryChange}
        className="w-full mb-4 p-2 pl-[0.3rem] border rounded-md"
      >
        {countries.map((ctry) => (
          <option key={ctry.id} value={ctry.name} className="text-[14px]">
            {ctry.name}
          </option>
        ))}
      </select>

      {/* Currency Select */}
      <label htmlFor="currency" className="block input-label-settings">
        {t("Settings.currency")}
      </label>
      <select
        id="currency"
        value={currency}
        onChange={handleCurrencyChange}
        className="w-full mb-4 p-2 pl-[0.3rem] border rounded-md"
      >
        {currencies.map((curr) => (
          <option key={curr.id} value={curr.name} className="text-[14px]">
            {curr.name}
          </option>
        ))}
      </select>
    </div>
  );
});

export default GeneralConfiguration;
