import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useEffect, useState, useRef } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { VscSettings } from 'react-icons/vsc';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import autoTable from 'jspdf-autotable';
import FloatingLabelInput from '../components/common/FloatingLabelInput';
import NoDataFound from '../components/common/NoDataFound';
import authService from '../services/authService';
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';
import moment from 'moment';
import Loader from '../components/common/Loder';
import { formatCurrency } from '../utils/formatCurrency';

export default function Wallet() {
  const { t } = useTranslation();
  const [wallet, setWallet]: any = useState([]);
  const [allwallet, setAllwallet] = useState([])
  const [loading, setLoading] = useState<boolean>(false);

  const [paymenttype, setpaymenttype] = useState('');
  const [isSortDropdownOpen, setSortDropdownOpen] = useState(false);
  const [isFilterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [isExportDropdownOpen, setExportDropdownOpen] = useState(false);

  const filterDropdownRef = useRef<HTMLDivElement>(null);
  const SortDropdownRef = useRef<HTMLDivElement>(null);
  const ExportDropdownRef = useRef<HTMLDivElement>(null);

  const [selectedSort, setSelectedSort] = useState(t('Wallet.Latest'));

  const [startdate, setstartdate] = useState('');
  const [enddate, setenddate] = useState('');


  const id = localStorage.getItem("userId")
  const [walletBalance, setWalletBalance] = useState('')

  const [first, setFirst] = useState(0);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);

  useEffect(() => {
    getWalletDetails("DESC","", "")

    document.addEventListener("mousedown", handleClickOutside);
    return () => { document.removeEventListener("mousedown", handleClickOutside); };
  }, [page]);

  const getWalletDetails = (sort_order: string = "DESC",startdataflg: any, enddateflag: any) => {
    setLoading(true);
    authService.getWallet(id, page + 1,sort_order, startdataflg === "" ? "" : startdate, enddateflag === "" ? "" : enddate)
      .then((response) => {
        console.log(response, "klkjkj")
        if (response.status === 200) {
          setAllwallet(response?.data?.transactions_list?.transactions);
          setWallet(response?.data?.transactions_list?.transactions);
          setTotalRecords(response?.data?.pagination?.total);
          const formattedBalance = new Intl.NumberFormat('en-US').format(response?.data?.transactions_list?.wallet_balance);
          setWalletBalance(formattedBalance)
        }
        else {
          setAllwallet([])
          setWallet([])
          setWalletBalance('-')
        }
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching wallet details:", error);
        setAllwallet([]);
        setWallet([]);
        setWalletBalance("-");
      });
      

  }
  if (loading) {
    return <Loader/>
  }
  const columns = [
    {
      label: t('Wallet.DateTime'),
      field: "transaction_datetime",
      sortable: true,
      body: (rowData: any) => <span className="text-gray-500">{moment(rowData.transaction_datetime).format("DD MMM YYYY - hh:mm A")}</span>
    },
    {
      label: t('Wallet.TranID'),
      field: "transaction_id",
      sortable: true,
    },
    {
      label: t('Wallet.paymentRefId'),
      field: "payment_disb_id",
      sortable: true,
    },
    {
      label: t('Wallet.PaymentType'),
      field: "transaction_type",
      sortable: true,
    },
    {
      label: t('Wallet.TotalAmount'),
      field: "amount",
      sortable: true,
      body: (rowData: any) => <span className="text-green-500">{formatCurrency(rowData.amount)}</span>
    }
  ];

  const toggleSortDropdown = () => {
    setSortDropdownOpen(!isSortDropdownOpen);
    setFilterDropdownOpen(false);
    setExportDropdownOpen(false);
  };

  const toggleFilterDropdown = () => {
    setFilterDropdownOpen(!isFilterDropdownOpen);
    setSortDropdownOpen(false);
    setExportDropdownOpen(false);
  };

  const toggleExportDropdown = () => {
    setExportDropdownOpen(!isExportDropdownOpen);
    setSortDropdownOpen(false);
    setFilterDropdownOpen(false);
  };

  const handleSortChange = (sortOption: string) => {
    setSelectedSort(sortOption);
    setSortDropdownOpen(false);

    const sortOrder = sortOption === t('Wallet.Latest') ? "DESC" : "ASC";
    getWalletDetails(sortOrder,"", "");

  };

  const clearAllFields = () => {
    setstartdate('');
    setenddate('');
    getWalletDetails("DESC","", "")
  };
  const onApply = () => {
    getWalletDetails("",startdate, enddate);
  };

  const exportToExcel = () => {
    const formattedData = wallet.map((item: any) => ({
      DateTime: item.dateTime,
      TransactionID: item.transactionId,
      PaymentRefID: item.paymentRefId,
      PaymentType: item.paymentType,
      TotalAmount: item.total,
    }));
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Wallet_Details');
    XLSX.writeFile(workbook, 'Wallet_Details.xlsx');
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableColumn = columns.map((col) => col.label);
    const tableRows = wallet.map((item: any) => [
      item.dateTime,
      item.transactionId,
      item.paymentRefId,
      item.paymentType,
      item.total,
    ]);

    autoTable(doc, {
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      styles: { fontSize: 10, },
      headStyles: {
        fillColor: [231, 246, 254],
        textColor: [0, 0, 0]
      }
    });

    doc.save('Wallet_Details.pdf');
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (filterDropdownRef.current && !filterDropdownRef.current.contains(event.target as Node)) {
      setFilterDropdownOpen(false);
    }
    if (ExportDropdownRef.current && !ExportDropdownRef.current.contains(event.target as Node)) {
      setExportDropdownOpen(false);
    }
    if (SortDropdownRef.current && !SortDropdownRef.current.contains(event.target as Node)) {
      setSortDropdownOpen(false);
    }
  };

  const onPageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    setRows(event.rows);
    setPage(event.page);
  };

  return (
    <div className="flex flex-col ">
      <span className="custom-page-heading text-[#3E4044] mb-[16px]">{t('Wallet.Wallet')}</span>

      <div className="p-[24px] bg-[#ffffff] rounded-[8px] mb-[16px]">
        <div>
          <span className="text-[#3E4044] text-[14px] font-semibold">{t('Wallet.WalletBalance')}</span>
          <span className="block text-[#232323] text-[22px] font-semibold">$ {walletBalance}</span>
        </div>
      </div>

      <div className="flex justify-between items-center mb-[16px]">
        <span className="text-[#3E4044] text-[18px] font-medium leading-[27px]">{t('Settlements.TransactionList')}</span>

        <div className="flex gap-[8px]">
          {/* Sort by Latest Button */}
          <div className="relative">
            <button
              className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
              onClick={toggleSortDropdown}
            >
              {t('Wallet.SortBy')}: {selectedSort}
              <MdArrowDropDown />
            </button>
            {isSortDropdownOpen && (
              <div ref={SortDropdownRef} className="absolute bg-white border border-gray-300 rounded shadow-md mt-[5px] w-[130px] z-[500]">
                <button
                  className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100 border-b"
                  onClick={() => handleSortChange(t('Wallet.Latest'))}
                >
                  <span className="text-[16px] font-normal leading-[24px]">{(t('Wallet.Latest'))}</span>
                </button>
                <button
                  className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100"
                  onClick={() => handleSortChange(t('Wallet.Oldest'))}
                >
                  <span className="text-[16px] font-normal leading-[24px]">{(t('Wallet.Oldest'))}</span>
                </button>
              </div>
            )}
          </div>

          {/* Filter Dropdown */}
          <div className="relative">
            <button
              className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
              onClick={toggleFilterDropdown}
            >
              {t('Wallet.Filter')}
              <VscSettings />
            </button>
            {isFilterDropdownOpen && (
              <div ref={filterDropdownRef} className="absolute flex flex-col right-[-85px] gap-[20px] bg-white border border-gray-300 rounded shadow-md mt-[5px] p-5 w-[390px] z-[500]">
                <div className="text-[#3E4044] text-[18px] font-medium leading-[27px]">
                  {t('Wallet.Filter')}
                </div>

                <div className="text-[14px] leading-[24px] font-normal text-[#00000099]">
                  <FloatingLabelInput
                    id="start_date_label"
                    type="date"
                    label={t('Filter.startdate')}
                    value={startdate}
                    onChange={(e) => setstartdate(e.target.value)}
                  />
                </div>
                <div className="text-[14px] leading-[24px] font-normal text-[#00000099]">
                  <FloatingLabelInput
                    id="end_date_label"
                    type="date"
                    label={t('Filter.enddate')}
                    value={enddate}
                    onChange={(e) => setenddate(e.target.value)}
                  />
                </div>
                <div className="flex flex-col gap-[16px]">
                  <button
                    className="block w-full text-center px-[8px] py-[8px]"
                    onClick={clearAllFields}
                  >
                    {t('Filter.ClearAll')}
                  </button>
                  <button
                    className="block w-full text-center px-[8px] py-[8px] bg-[#2196F3] text-white"
                    onClick={onApply}
                  >
                    {t('Filter.Apply')}
                  </button>
                </div>
              </div>
            )}
          </div>
          {/* Export Dropdown */}
          <div className="relative">
            <button
              className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
              onClick={toggleExportDropdown}
            >
              {t('Wallet.Export')}
              <MdArrowDropDown />
            </button>
            {isExportDropdownOpen && (
              <div ref={ExportDropdownRef} className="absolute right-0 bg-white border border-gray-300 rounded shadow-md mt-[5px] w-[160px] z-[500]">
                <button
                  className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100 border-b"
                  onClick={() => {
                    setExportDropdownOpen(false);
                    exportToPDF();
                  }}
                >
                  <span className="text-[16px] font-normal leading-[24px]">{t('Export.ExportASPdf')}</span>
                </button>
                <button
                  className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100"
                  onClick={() => {
                    setExportDropdownOpen(false);
                    exportToExcel();
                  }}
                >
                  <span className="text-[16px] font-normal leading-[24px]">{t('Export.ExportASXlsx')}</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {wallet.length === 0 ? <NoDataFound /> :
        <>
          <DataTable value={wallet} rows={15}>
            {columns.map((item) => (
              <Column
                key={item.field}
                field={item.field}
                header={item.label}
                headerClassName='custom-header-bg'
                className='custom-col-bg'
                sortable={item.sortable}
                body={item.body}
              />
            ))}
          </DataTable>
          <Paginator
            first={first}
            rows={rows}
            totalRecords={totalRecords}
            onPageChange={onPageChange}
          />
        </>

      }
    </div>
  );
}
