import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useEffect, useState, useRef } from "react";
import { useParams } from 'react-router-dom';
import { MdArrowDropDown } from "react-icons/md";
import { VscSettings } from "react-icons/vsc";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import { decryptParams } from "../../../utils/cryptoUtils";
import { encryptParams } from '../../../utils/cryptoUtils';
import FloatingLabelInput from "../../../components/common/FloatingLabelInput";
import NoDataFound from "../../../components/common/NoDataFound";
import operatorService from "../../../services/operatorServices";
import { useOutletContext } from "react-router-dom";
import Helpers from "../../../utils/helpers";
import Loader from "../../../components/common/Loder";
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';
import { setTokenSourceMapRange } from "typescript";
import { formatCurrency } from "../../../utils/formatCurrency";

export default function WalletSection() {
  const { t } = useTranslation();
  const { operatorDetails } = useOutletContext<{ operatorDetails: any }>();
  const [loading, setLoading] = useState(false)
  const [transactionid, settransactionid] = useState("");
  const [paymentid, setpaymentid] = useState("");
  const [paymenttype, setpaymenttype] = useState("");
  const [isSortDropdownOpen, setSortDropdownOpen] = useState(false);
  const [isFilterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [isExportDropdownOpen, setExportDropdownOpen] = useState(false);
  const [selectedSort, setSelectedSort] = useState(t("Wallet.Latest"));
  const [allWalletTransactions, setAllWalletTransactions] = useState([]);
  const [walletBalance, setWalletBalance] = useState("");
  const { encryptedParam } = useParams();

  const [startdate, setstartdate] = useState('');
  const [enddate, setenddate] = useState('');

  const [first, setFirst] = useState(0);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);

  const filterDropdownRef = useRef<HTMLDivElement>(null);
  const SortDropdownRef = useRef<HTMLDivElement>(null);
  const ExportDropdownRef = useRef<HTMLDivElement>(null);


  useEffect(() => {

    if (encryptedParam) {
      const decodedParam = decodeURIComponent(encryptedParam).replace(/\s/g, '+');
      const decryptedParam = decryptParams(decodedParam) as DecryptedParam;
      if (decryptedParam.operatorId) {
        getWalletTransaction(decryptedParam.operatorId,"DESC","","");
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => { document.removeEventListener("mousedown", handleClickOutside); };
  }, [page]);

  const getWalletTransaction = async (operatorId: any, sort_order: string = "DESC",startdateflg:any,enddateflg:any) => {
    setLoading(true)
    try {
      const res = await operatorService.getWalletTransactionByOperatorId(operatorId, page + 1,sort_order,startdateflg === "" ? "" : startdate,enddateflg === "" ? "" :  enddate);
      const transactions = res.transactions_list.map(
        (item: any, idx: any) => ({
          ...item,
          index: idx + 1,
          transaction_datetime: Helpers.formatDateString(
            item.transaction_datetime
          ),
          balance_amount: item.balance_amount || "-",
        })
      );
      setAllWalletTransactions(transactions);
      setTotalRecords(res.pagination.total_records)
      setWalletBalance(res.wallet_balance);
    } catch (error) {
      console.error('error fetching wallet transactions', error);
    } finally {
      setLoading(false)
    }
  };

  const columns: any = [
    {
      field: "index",
      label: t("Settlements.Sno"),
      sortable: false,
    },
    {
      field: "transaction_datetime",
      label: t("Wallet.DateTime"),
      sortable: true,
    },
    {
      field: "transaction_id",
      label: t("Wallet.TranID"),
      sortable: true,
    },
    {
      field: "payment_disb_id",
      label: t("Wallet.paymentRefId"),
      sortable: true,
    },
    {
      field: "transaction_type",
      label: t("Wallet.TransactionType"),
      sortable: true,
    },
    {
      label: t("Wallet.TotalAmount"),
      field: "amount",
      sortable: true,
      body: (rowData: any) => (
        <span className="text-green-500">{formatCurrency(rowData.amount)}</span>
      ),
    },
    {
      label: t("Wallet.Balance"),
      field: "balance",
      sortable: true,
      body: (rowData:any) => (
        formatCurrency(rowData.balance)
      )
    },
  ];

  const toggleSortDropdown = () => {
    setSortDropdownOpen(!isSortDropdownOpen);
    setFilterDropdownOpen(false);
    setExportDropdownOpen(false);
  };

  const toggleFilterDropdown = () => {
    setFilterDropdownOpen(!isFilterDropdownOpen);
    setSortDropdownOpen(false);
    setExportDropdownOpen(false);
  };

  const toggleExportDropdown = () => {
    setExportDropdownOpen(!isExportDropdownOpen);
    setSortDropdownOpen(false);
    setFilterDropdownOpen(false);
  };
  interface DecryptedParam {
    index?: any;
    operatorId?: string;
  }

  const handleSortChange = (sortOption: string) => {
    setSelectedSort(sortOption);
    setSortDropdownOpen(false);

    const sortOrder = sortOption === t('Wallet.Latest') ? "DESC" : "ASC";

    if (encryptedParam) {
      const decodedParam = decodeURIComponent(encryptedParam).replace(/\s/g, '+');
      const decryptedParam = decryptParams(decodedParam) as DecryptedParam;

      if (decryptedParam.operatorId) {
        getWalletTransaction(decryptedParam.operatorId,sortOrder,"","");
        setFilterDropdownOpen(false);
      }
    }
  };

  const clearAllFields = () => {
    settransactionid("");
    setstartdate("");
    setenddate("");

    if (encryptedParam) {
      const decodedParam = decodeURIComponent(encryptedParam).replace(/\s/g, '+');
      const decryptedParam = decryptParams(decodedParam) as DecryptedParam;

      if (decryptedParam.operatorId) {
        getWalletTransaction(decryptedParam.operatorId,"DESC", "", "",);
        setFilterDropdownOpen(false);
      }
    }

  };

  const exportToExcel = () => {
    const formattedData = allWalletTransactions.map((item: any) => ({
      DateTime: item.transaction_datetime || "-",
      TransactionID: item.transaction_id || "-",
      PaymentRefID: item.payment_disb_id || "-",
      PaymentType: item.transaction_type || "-",
      TotalAmount: item.amount || "-",
      Balance: item.balance || "-",
    }));
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Operator_Wallet");
    XLSX.writeFile(workbook, "Operator_Wallet.xlsx");
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableColumn = columns.map((col: any) => col.label);
    const tableRows = allWalletTransactions.map((item: any,index:any) => [
      index + 1,
      item.transaction_datetime || "-",
      item.transaction_id || "-",
      item.payment_disb_id || "-",
      item.transaction_type || "-",
      item.amount || "-",
      item.balance || "-",
    ]);

    autoTable(doc, {
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      styles: { fontSize: 10 },
      headStyles: {
        fillColor: [231, 246, 254],
        textColor: [0, 0, 0],
      },
    });

    doc.save("Operator_Wallet.pdf");
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (filterDropdownRef.current && !filterDropdownRef.current.contains(event.target as Node)) {
      setFilterDropdownOpen(false);
    }
    if (ExportDropdownRef.current && !ExportDropdownRef.current.contains(event.target as Node)) {
      setExportDropdownOpen(false);
    }
    if (SortDropdownRef.current && !SortDropdownRef.current.contains(event.target as Node)) {
      setSortDropdownOpen(false);
    }
  };

  const onPageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    setRows(event.rows);
    setPage(event.page);
  };
  
  const onApply = () => {
    if (encryptedParam) {
      const decodedParam = decodeURIComponent(encryptedParam).replace(/\s/g, '+');
      const decryptedParam = decryptParams(decodedParam) as DecryptedParam;

      if (decryptedParam.operatorId) {
        getWalletTransaction(decryptedParam.operatorId,"",startdate,enddate);
        setFilterDropdownOpen(false);
      }
    }
  };
  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-[4px] p-[24px] bg-[#ffffff] rounded-[8px] mb-[16px]">
        <span className="text-[#3E4044] text-[14px] font-semibold">
          {" "}
          {t("Wallet.WalletBalance")}
        </span>
        <span className="block text-[#232323] text-[22px] font-semibold">
          {walletBalance}
        </span>
      </div>

      <div className="flex justify-between items-center mb-[16px]" >
        <span className="text-[#3E4044] text-[18px] font-medium leading-[27px]">
          {t("Settlements.TransactionList")}
        </span>

        <div className="flex gap-[8px]">
          {/* Sort by Latest Button */}
          <div className="relative">
            <button
              className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
              onClick={toggleSortDropdown}
            >
              {t("Wallet.SortBy")}: {selectedSort}
              <MdArrowDropDown />
            </button>
            {isSortDropdownOpen && (
              <div ref={SortDropdownRef} className="absolute bg-white border border-gray-300 rounded shadow-md mt-[5px] w-[130px] z-[500]">
                <button
                  className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100 border-b"
                  onClick={() => handleSortChange(t("Wallet.Latest"))}
                >
                  <span className="text-[16px] font-normal leading-[24px]">
                    {t("Wallet.Latest")}
                  </span>
                </button>
                <button
                  className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100"
                  onClick={() => handleSortChange(t("Wallet.Oldest"))}
                >
                  <span className="text-[16px] font-normal leading-[24px]">
                    {t("Wallet.Oldest")}
                  </span>
                </button>
              </div>
            )}
          </div>

          {/* Filter Dropdown */}
          <div className="relative">
            <button
              className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
              onClick={toggleFilterDropdown}
            >
              {t("Wallet.Filter")}
              <VscSettings />
            </button>
            {isFilterDropdownOpen && (
              <div ref={filterDropdownRef} className="absolute flex flex-col right-[-85px] gap-[20px] bg-white border border-gray-300 rounded shadow-md mt-[5px] p-5 w-[390px] z-[500]">
                <div className="text-[#3E4044] text-[18px] font-medium leading-[27px]">
                  {t("Wallet.Filter")}
                </div>
                <div className="text-[14px] leading-[24px] font-normal text-[#00000099]">
                  <FloatingLabelInput
                    type="date"
                    id="startdate"
                    label={t('Filter.startdate')}
                    value={startdate}
                    onChange={(e) => setstartdate(e.target.value)}
                  />
                </div>
                <div className="text-[14px] leading-[24px] font-normal text-[#00000099]">
                  <FloatingLabelInput
                    type="date"
                    id="enddate"
                    label={t('Filter.enddate')}
                    value={enddate}
                    onChange={(e) => setenddate(e.target.value)}
                  />
                </div>
                <div className="flex flex-col gap-[16px]">
                  <button
                    className="block w-full text-center px-[8px] py-[8px]"
                    onClick={clearAllFields}
                  >
                    {t("Filter.ClearAll")}
                  </button>
                  <button
                    className="block w-full text-center px-[8px] py-[8px] bg-[#2196F3] text-white"
                    onClick={onApply}
                  >
                    {t("Filter.Apply")}
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="relative">
            <button
              className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
              onClick={toggleExportDropdown}
            >
              {t("Wallet.Export")}
              <MdArrowDropDown />
            </button>
            {isExportDropdownOpen && (
              <div ref={ExportDropdownRef} className="absolute right-0 bg-white border border-gray-300 rounded shadow-md mt-[5px] w-[160px] z-[500]">
                <button
                  className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100 border-b"
                  onClick={() => {
                    setExportDropdownOpen(false);
                    exportToPDF();
                  }}
                >
                  <span className="text-[16px] font-normal leading-[24px]">
                    {t("Export.ExportASPdf")}
                  </span>
                </button>
                <button
                  className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100"
                  onClick={() => {
                    setExportDropdownOpen(false);
                    exportToExcel();
                  }}
                >
                  <span className="text-[16px] font-normal leading-[24px]">
                    {t("Export.ExportASXlsx")}
                  </span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {loading ? (
        <div className="mt-40"><Loader /></div>
      ) : allWalletTransactions.length === 0 ? (
        <NoDataFound />
      ) : (
        <>

          <DataTable value={allWalletTransactions} rows={15}>
            {columns.map((item: any) => (
              <Column
                field={item.field}
                header={item.label}
                headerClassName="custom-header-bg"
                className="custom-col-bg"
                sortable={item.sortable}
                body={item.body}
              />
            ))}
          </DataTable>
          <Paginator
            first={first}
            rows={rows}
            totalRecords={totalRecords}
            onPageChange={onPageChange}
          />
        </>
      )}
    </div>
  );
}
