import React from 'react'

const AccountStatus = () => {
  return (
    <div>
      This is AccountStatus page
    </div>
  )
}

export default AccountStatus
