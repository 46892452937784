import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/logo.webp'
import { DASHBOARD_SIDEBAR_LINKS } from '../../lib/consts/navigation';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { RxCross2 } from "react-icons/rx";
import logout from '../../assets/logout.png'
import { useNavigate } from 'react-router-dom';

interface SidebarLinkProps {
  item: {
    key: string;
    // label: string;
    path: string;
    icon?: React.ReactNode;
  };
  isActive: boolean;
}

const SidebarLink: React.FC<SidebarLinkProps> = ({ item, isActive }) => {
  const { t } = useTranslation();
  
  
  return (
    <Link
      to={item.path}
      className={`flex items-center p-[16px] text-[16px] rounded
        ${isActive ? 'bg-[#019DF7] text-[#FFFFFF]' : 'text-gray-700 hover:bg-[#019DF7] hover:text-[#FFFFFF]'}`}
    >
      {item.icon && <span className='mr-3'>{item.icon}</span>}
      <span className='text-[16px] font-medium leading-[24px]'>{t(`sidebar.${item.key}`)}</span>
    </Link>
  );
};

const Sidebar: React.FC = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const { t } = useTranslation();
  const navigate = useNavigate()
  const [showModal,setShowModal]=useState(false)

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/signin');
  };
  return (
    <div className='bg-[#FFFFFF] min-w-[250px] px-[24px] flex flex-col border-r border-[#0000001F] z-10'>
      <div className='flex items-center min-h-[100px]'>
        <img src={logo} alt='logo' className='max-w-[190px] h-auto object-contain' rel='reload'/>
      </div>
      <div className='flex flex-col justify-between h-[100%] py-[16px] gap-[32px]'>
        <div className='flex flex-col gap-1'>
        {DASHBOARD_SIDEBAR_LINKS.map((item) => {
            const isActive = 
              currentPath === item.path ||  // Exact match for the path
              currentPath.startsWith(item.path) ||  // Match if currentPath starts with item.path (for nested routes)
              (item.key === 'service-providers' && currentPath.startsWith('/provider-details')) ||
              (item.key === 'operators' && currentPath.includes('/operators/')) ||  // For service-providers path
              (item.key === 'operators' && currentPath.includes('/operator-details/'));   // For service-providers path

            return (
              <SidebarLink
                key={item.key}
                item={item}
                isActive={isActive}
              />
            );
          })}

        </div>
   {/* Logout Button */}
   <div>
          <button
            className="text-[14px] text-red-500 px-[16px] py-[8px]"
            onClick={() => setShowModal(true)}
          >
            {t('sidebar.logout')}
          </button>
        </div>
      </div>

      {/* Logout Confirmation Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white shadow-lg w-[350px] relative text-center rounded-lg">
            {/* Close Icon */}
            <div className="flex justify-end space-x-4 border-t pt-4 pb-3 pr-3 rounded-[100px]">
              <RxCross2
                className="absolute top-3 right-3 text-gray-400 hover:text-gray-600 text-xl"
                onClick={() => setShowModal(false)}
                aria-label="Close"
              />
            </div>
            {/* Centered Image */}
            <div className="p-7 flex flex-col items-center space-y-4">
              <img className="w-[57px] h-[80px]" src={logout} alt="Logout Icon" />
              <p className="text-[14px]" >{t('sidebar.logoutconfirm')}</p>
            </div>
            {/* Modal Footer */}
            <div className="flex justify-end  rounded-lg space-x-4 border-t pt-4 pb-3 pr-3 bg-[#F5F5F5] ">
              <button
                onClick={() => setShowModal(false)}
                className="border border-black text-black bg-transparent px-4 py-1 rounded hover:bg-gray-100 text-[14px]"
              >
                {t('sidebar.close')}
              </button>
              <button
                onClick={handleLogout}
                className="bg-red-600 text-white px-4 py-1 rounded hover:bg-red-700 text-[14px]"
              >
               {t('sidebar.logoutbtn')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
