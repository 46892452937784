// Modal.tsx
// import React from 'react';
// import { Dialog } from 'primereact/dialog';

// interface ModalProps {
//   visible: boolean;
//   onHide: () => void;
//   title: string;
//   children: React.ReactNode;
// }

// const Modal: React.FC<ModalProps> = ({ visible, onHide, title, children }) => {
//   return (
//     <Dialog
//       header={title}
//       visible={visible}
//       // style={{ width: '50vw' }}
//       style={{ width: '372px', height: '355px' }}
//       onHide={onHide}
//       modal
//       draggable={false}
//       resizable={false}
//       className="custom-header-bg"
//     >
//       {children}
//     </Dialog>
//   );
// };

// export default Modal;

// Modal.tsx
import React from 'react';
import { Dialog } from 'primereact/dialog';

interface ModalProps {
  visible: boolean;
  onHide: () => void;
  title: string;
  content?: React.ReactNode;
  footer?: React.ReactNode;
  children?: React.ReactNode;
  // titleStyle?: React.CSSProperties;
}

const Modal: React.FC<ModalProps> = ({ visible, onHide, title, content, footer, children }) => {
  return (
    <Dialog
      style={{ width: '372px' }}
      header={title}
      headerStyle={{
        padding: '20px 20px 20px 20px',
        borderRadius: '8px 8px 0 0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: 'black'
      }}
      footer={footer}
      visible={visible}
      onHide={onHide}
      modal
      draggable={false}
      resizable={false}
      className="custom-dialog-bg  custom-dialog-content"
    >
      <div className="modal-content">
        {content || children}
      </div>
    </Dialog>
  );
};

export default Modal;
