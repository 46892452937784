import React from 'react'

const ProfileDetails = () => {
  return (
    <div>
      This is ProfileDetails page
    </div>
  )
}

export default ProfileDetails
