import axios from 'axios';

// Base URL from environment variables
const baseURL = process.env.REACT_APP_BASE_URL || 'http://localhost:5000/api';

// Create a centralized Axios instance
const api = axios.create({
  baseURL: baseURL,
  timeout: 10000, // Set a timeout for requests
});

// Add request interceptor (optional, e.g., for adding tokens)
api.interceptors.request.use(
  (config) => {
    // Add Authorization token if available
    const token = localStorage.getItem('token'); // Adjust based on your auth logic
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add response interceptor (optional, e.g., for global error handling)
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle specific status codes (e.g., 401 for unauthorized)
    if (error.response?.status === 401) {
      console.warn('Unauthorized! Redirecting to login...');
      // Redirect user to login page or clear tokens
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

// Auth API Functions

/**
 * Sign in the user with email and password
 * @param email - User's email address
 * @param password - User's password
 * @returns Response data or error
 */
const SignIn = async (email: string, password: string) => {
  try {
    const response = await api.post('/user/login', { email, password });
    return response;
  } catch (error: any) {
    console.error('Signin Error:', error.response || error.message);
    return Promise.reject(error.response?.data || error.message);
  }
};

/**
 * Trigger forgot password process
 * @param email - User's email address
 * @returns Response data or error
 */
const ForgotPassword = async (email: string) => {
  try {
    const response = await api.post('/user/forgot-password', { email_id: email });
    return response;
  } catch (error: any) {
    console.error('ForgotPassword Error:', error.response || error.message);
    return Promise.reject(error.response?.data || error.message);
  }
};

/**
 * Verify email during forgot password process
 * @param token - Verification token
 * @returns Response data or error
 */
const ForgotEmailVerify = async (token: string) => {
  try {
    const response = await api.post('/user/forgot-email-verify', { token });
    return response;
  } catch (error: any) {
    console.error('ForgotEmailVerify Error:', error.response || error.message);
    return Promise.reject(error.response?.data || error.message);
  }
};

/**
 * Change user password after verification
 * @param email - User's email address
 * @param password - New password
 * @returns Response data or error
 */
const ChangePassword = async (email: string, password: string) => {
  try {
    const response = await api.patch('/user/update-forgot-password', { email, password });
    return response;
  } catch (error: any) {
    console.error('ChangePassword Error:', error.response || error.message);
    return Promise.reject(error.response?.data || error.message);
  }
};

const getWallet = async (id: any, page: any, sort_order: any, startdate: any, enddate: any) => {
  try {
    let queryParams = `?page=${page}&sort_order=${sort_order}`;

    if (startdate) {
      queryParams += `&start_date=${startdate}`;
    }

    if (enddate) {
      queryParams += `&end_date=${enddate}`;
    }

    const response = await api.get(`/wallet/${id}/statements${queryParams}`);
    return response;
  }
  catch (error: any) {
    console.error('getWallet Error:', error.response || error.message);
    return Promise.reject(error.response?.data || error.message);
  }
};


const operatorServiceProvider = async (token: any, id: any, page: any, sort_order: any, providerid: any, providername: any,) => {
  const api = axios.create({
    baseURL: baseURL,
    headers: { Authorization: `Bearer ${token}` },
  });


  let queryParams = `?page=${page}&sort_order=${sort_order}`;

  // &sort_order=${sort_order}`;

  if (providerid) {
    queryParams += `&sp_id=${providerid}`;
  }
  if (providername) {
    queryParams += `&driver_name=${providername}`;
  }


  return api
    .get(`/service-provider/operator/${id}/service-provider-list${queryParams}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error)
      return error;
    });
}

// #service Provider
const serviceProviderList = async (token: any, page: any, providerid: any, providername: any, sort_order: any) => {
  const api = axios.create({
    baseURL: baseURL,
    headers: { Authorization: `Bearer ${token}` },
  });

  let queryParams = `?page=${page}&sort_order=${sort_order}`;

  // &sort_order=${sort_order}`;

  if (providerid) {
    queryParams += `&sp_id=${providerid}`;
  }
  if (providername) {
    queryParams += `&sp_name=${providername}`;
  }

  const url = `/service-provider/service-provider-list-info${queryParams}`;

  return api
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error)
      return error;
    });
}
const serviceProviderDetails = async (token: any, id: any) => {
  const api = axios.create({
    baseURL: baseURL,
    headers: { Authorization: `Bearer ${token}` },
  });
  return api
    .get("/service-provider/" + id + "/details")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error)
      return error;
    });
}
const serviceProviderBookings = async (token: any, id: any, page: any, sort_order: any, bookingID: any,booking_status:any) => {
  const api = axios.create({
    baseURL: baseURL,
    headers: { Authorization: `Bearer ${token}` },
  });
  return api
    .get(`/operator/booking/${id}/spbooking?page=${page}&order_by=${sort_order}&booking_id=${bookingID}&booking_status=${booking_status}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error)
      return error;
    });
}
const serviceProviderSettlements = async (token: any, id: any, page: any, sort_order: any, transactionid: any) => {
  const api = axios.create({
    baseURL: baseURL,
    headers: { Authorization: `Bearer ${token}` },
  });
  const filters = transactionid
    ? `{"sp_id":${id},"transaction_id":"${transactionid}"}`
    : `{"sp_id":${id}}`;

  return api
    .get(`/service-provider/settlements?page=${page}&filters=${filters}&sort_order=${sort_order}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error)
      return error;
    });
}
const Spwallet = async (
  token: any,
  id: any,
  sort_order: any,
  startdate?: any,
  enddate?: any
) => {
  const api = axios.create({
    baseURL: baseURL,
    headers: { Authorization: `Bearer ${token}` },
  });

  let queryParams = `?sort_order=${sort_order}`;
  if (startdate) {
    queryParams += `&start_date=${startdate}`;
  }
  if (enddate) {
    queryParams += `&end_date=${enddate}`;
  }

  try {
    const response = await api.get(`/service-provider/${id}/wallet/history${queryParams}`);
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
};
const sPStatusFlagUpdate = (token: any, spid: any, userId: any, status: any) => {
  const api = axios.create({
    baseURL: baseURL,
    headers: { Authorization: `Bearer ${token}` },
  });
  return api.patch("/service-provider/operator/" + userId + "/service-provider-status",
    {
      toggle_sp_id: spid,
      toggle_status: status,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
// Export all Auth services
const authService = {
  SignIn,
  ForgotPassword,
  ForgotEmailVerify,
  ChangePassword,
  getWallet,
  operatorServiceProvider,
  serviceProviderList,
  serviceProviderDetails,
  serviceProviderBookings,
  serviceProviderSettlements,
  Spwallet,
  sPStatusFlagUpdate
};

export default authService;
