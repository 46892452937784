import React from "react";
import {
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./i18n";

import Signup from "./pages/auth/Signup";
import Signin from "./pages/auth/Signin";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ChangePassword from "./pages/auth/ChangePassword";
import PasswordUpdate from "./pages/auth/PasswordUpdate";
import EmailVerification from "./pages/auth/EmailVerification";
import EmailConfirmation from "./pages/auth/EmailConfirmation";
import EmailVerified from "./pages/auth/EmailVerified";

import Layout from "./components/specific/layout";

import Home from "./pages/Home";
import Dashboard from "./features/home/pages/Dashboard";

import Bookings from "./pages/Bookings";
import BookingList from "./features/bookings/pages/BookingList";
import BookingView from "./features/bookings/pages/BookingView";

import Operators from "./pages/Operators";
import OperatorList from "./features/operator/pages/OperatorList";
import OperatorDetails from "./features/operator/pages/OperatorDetails";
import ServiceProviders from "./features/operator/pages/ServiceProviders";
import Booking from "./features/operator/pages/BookingSection";
import WalletOperator from "./features/operator/pages/WalletSection";
import Settlement from "./features/operator/pages/Settlements";

import ServiceProvider from "./pages/ServiceProvider";
import ServiceList from "./features/serviceprovider/pages/SpList";
import ServiceDetails from "./features/serviceprovider/pages/SpDetails";
import BookingSection from "./features/serviceprovider/pages/BookingSection";
import WalletSection from "./features/serviceprovider/pages/WalletSection";
import Settlements from "./features/serviceprovider/pages/Settlements";


import Wallet from "./pages/Wallet";
import WalletDeatils from "./features/wallet/pages/ChargeOrReport";

import Settings from "./pages/Settings";
import AccountStatus from "./features/settings/pages/AccountStatus";
import ProfileDetails from "./features/settings/pages/ProfileDetails";
import NotFound from "./pages/NotFound";

import { Approved } from "./features/operator/pages/approved";
import { Onboarding } from "./features/operator/pages/onboarding";
// import Historydetail from "./pages/Settlement/Historydetail";
import History from "./pages/Settlement/History";
import Historydetail from "./pages/Settlement/Historydetail";
import OnboardingOperatorDetails from "./features/operator/pages/onboardingOperatorDetails";

const App: React.FC = () => {

  if (process.env.NODE_ENV === 'production') {
    console.log = function () { };
  }

  return (
    <Routes>
      {/*Signup page */}
      <Route path="/" element={<Navigate to="/signin" replace />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/signin" element={<Signin />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/email-verified" element={<EmailVerified />} />
      <Route path="/email-verification" element={<EmailVerification />} />
      <Route path="/email-confirmation" element={<EmailConfirmation />} />
      <Route path="/password-update" element={<PasswordUpdate />} />

      {/* Home Routes */}
      <Route path="/" element={<Layout />}>
        <Route index path="dashboard" element={<Home />} />
        <Route path="dashboard" element={<Dashboard />} />
      </Route>

      {/* Bookings Routes */}
      <Route path="/" element={<Layout />}>
        <Route path="bookings">
          <Route index element={<Bookings />} /> {/* Defaults to Bookings */}
          <Route path="list" element={<BookingList />} />
          <Route path=":encryptedParam" element={<BookingView />} />
        </Route>
        {/* Fallback route for undefined paths */}
        <Route path="*" element={<NotFound />} />
      </Route>

      {/*operator Routes */}
      <Route path="/" element={<Layout />}>
        <Route path="operators" element={<Operators />}>
          <Route path="approved" element={<Approved />} />
          <Route path="onboarding" element={<Onboarding />} />
        </Route>
        <Route path="operators-list" element={<OperatorList />} />

        <Route
          path="operator-details/:encryptedParam"
          element={<OperatorDetails />}
        >
          <Route path="service-providers" element={<ServiceProviders />} />
          <Route path="booking-section" element={<Booking />} />
          <Route path="wallet-section" element={<WalletOperator />} />
          <Route path="settlements" element={<Settlement />} />
        </Route>
        <Route
          path="onboarding-operator-details/:encryptedParam"
          element={<OnboardingOperatorDetails />}
        >
        </Route>
      </Route>

      {/* Service Providers Route */}
      <Route path="/" element={<Layout />}>
        <Route path="service-providers">
          <Route index element={<ServiceProvider />} />
          <Route path="list" element={<ServiceList />} />
          <Route path=":encryptedParam" element={<ServiceDetails />}>
            <Route path="booking-section" element={<BookingSection />} />
            <Route path="wallet-section" element={<WalletSection />} />
            <Route path="settlements" element={<Settlements />} />
          </Route>
        </Route>
      </Route>
     
     { /* Settlements Route*/}
      <Route path="/" element={<Layout />}>
        <Route path="history" element={<History />} />
        <Route path="historydetail" element={<Historydetail />} />
      </Route>

      {/* Wallet Route */}
      <Route path="/" element={<Layout />}>
        <Route path="wallet" element={<Wallet />} />
        <Route path="wallet-deatils" element={<WalletDeatils />} />
      </Route>

      {/* Settings Routes */}
      <Route path="/" element={<Layout />}>
        <Route path="settings" element={<Settings />} />
        <Route path="account-status" element={<AccountStatus />} />
        <Route path="profile-details" element={<ProfileDetails />} />
      </Route>

      {/* Terms and privacy */}
      {/* <Route path="/" element={<OnboardHeader />}>
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy" element={<Privacy />} />
            </Route> */}

      {/* Default Route (404) */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;