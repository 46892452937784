import api from "./api";

const getOperatorList = async () => {
  const response = await api.get("/operator/operator-list");
  return response.data;
};

const getApprovedOperatorList = async (
  page: number | null,
  sortOrder: any  = "asc",
  operatorId: any  = '',
  operatorName: any  = '',
  status: any = ''
) => {
  const params: { [key: string]: string | null } = {};
  if (operatorId) params.operatorId = operatorId;
  if (operatorName) params.operatorName = operatorName;
  if (status) params.status = status;
  const response = await api.get(
    `/operator/operator-list?is_golive_ready=true&page=${page}&sort_by=name&sort_order=${sortOrder}&operator_id=${operatorId}&name=${operatorName}&account_status=${status}`
  );
  return response.data;
};

const getPendingOperatorList = async (
  page: number | null,
  sortOrder: any  = "asc",
  operatorId: any  = '',
  operatorName: any  = '',
  status: any = ''
) => {
  const params: { [key: string]: string | null } = {};
  if (operatorId) params.operatorId = operatorId;
  if (operatorName) params.operatorName = operatorName;
  if (status) params.status = status;
  const response = await api.get(
    `/operator/operator-list?is_golive_ready=false&page=${page}&sort_by=name&sort_order=${sortOrder}&operator_id=${operatorId}&name=${operatorName}&onboarding_status=${status}`
  );
  return response.data;
};

const updateOperatorDetails = async (updatedData: any) => {
  const response = await api.put("/user/update-operator-details", updatedData);
  return response;
};

const approveOnboardingOperator = async (operatorId: any) => {
  const updatedStatus = {
    onboarding_status: "APPROVED",
    operator_id: operatorId,
  };
  const response = await api.put("operator/update", updatedStatus);
  return response.data;
};

const rejectOnboardingOperator = async (operatorId: any, reason: any) => {
  const updatedStatus = {
    status: "REJECTED",
    operator_id: operatorId,
  };
  const response = await api.put("operator/update", updatedStatus);
  return response.data;
};

const getOperatorById = async (operatorId: string) => {
  const response = await api.get(`/operator/${operatorId}`);
  return response;
};

const getBookingsByOperatorId = async (operatorId: string,page:any,sort_order: any,bookingID:any) => {


  const response = await api
  .get(`/user/${operatorId}/bookinglist?page=${page}&sort_order=${sort_order}&booking_id=${bookingID}`);
  console.log("response", response);
  return response;
};

const getWalletTransactionByOperatorId = async (operatorId: string,page:any,sort_order:any,startdate:any,enddate:any) => {
  const response = await api.get(`operator/${operatorId}/wallet-statement?page=${page}&sort_order=${sort_order}&start_date=${startdate}&end_date=${enddate}`);
  console.log(response.data);
  return response.data;
};

const getSettlementsHistoryByOperatorId = async (operatorId: string, page: any, sort_order:any, transactionId :any) => {
  const response = await api.get(
    `/operator/${operatorId}/upcoming-settlements-history?page=${page}&order_by=${sort_order}&transaction_id=${transactionId}`
  );
  console.log(response.data);
  return response.data;
};

const ApprovedOperatorFilter = async (
  page: any | null,
  operatorId: any | null,
  operatorName: any | null,
  status?: any | null
) => {
  try {
    const params: { [key: string]: string | null } = {};

    if (operatorId) params.operatorId = operatorId;
    if (operatorName) params.operatorName = operatorName;
    if (status) params.status = status;

    const response = await api.get(
      `/operator/operator-list?is_golive_ready=true&page=${page}&operator_id=${operatorId}&name=${operatorName}&sort_by=${operatorName}&account_status=${status}`
    );
    console.log(response, "my filter");
    return response.data;
  } catch (error) {
    console.error("Error fetching filtered operators:", error);
    throw error;
  }
};

const OnboardingOperatorFilter = async (
  page: any | null,
  operatorId: any | null,
  operatorName: any | null,
  onboardingStatus?: any | null
) => {
  try {
    const params: { [key: string]: string | null } = {};

    if (operatorId) params.operatorId = operatorId;
    if (operatorName) params.operatorName = operatorName;
    if (onboardingStatus) params.onboardingStatus = onboardingStatus;

    const response = await api.get(
      `/operator/operator-list?is_golive_ready=false&page=${page}&operator_id=${operatorId}&name=${operatorName}&sort_by=${operatorName}&onboarding_status=${onboardingStatus}`
    );
    console.log(response, "my filter");
    return response.data;
  } catch (error) {
    console.error("Error fetching filtered operators:", error);
    throw error;
  }
};

const operatorService = {
  getOperatorList,
  getOperatorById,
  getApprovedOperatorList,
  getPendingOperatorList,
  approveOnboardingOperator,
  getBookingsByOperatorId,
  rejectOnboardingOperator,
  getWalletTransactionByOperatorId,
  updateOperatorDetails,
  getSettlementsHistoryByOperatorId,
  OnboardingOperatorFilter,
  ApprovedOperatorFilter,
};
export default operatorService;
