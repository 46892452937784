import { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MdArrowDropDown } from "react-icons/md";
import { VscSettings } from "react-icons/vsc";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import FloatingLabelInput from "../../../components/common/FloatingLabelInput";
import autoTable from "jspdf-autotable";
import { encryptParams } from "../../../utils/cryptoUtils";
import NoDataFound from "../../../components/common/NoDataFound";
import bookingService from "../../../services/bookingServices";
import Helpers from "../../../utils/helpers";
import Loader from "../../../components/common/Loder";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import FloatingLabelSelect from "../../../components/common/FloatingLabelselect";
import { formatCurrency } from "../../../utils/formatCurrency";

export default function BookingList() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [allBookings, setAllBookings] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [bookingId, setBookingId] = useState("");
  const [SpName, setSpName] = useState("");
  const [bookingStatus, setBookingStatus] = useState("");
  const [operatorId, setOperatorId] = useState("");
  const [serviceProvidername, setServiceProvidername] = useState("");
  const [operatorName, setOperatorName] = useState("");

  const filterDropdownRef = useRef<HTMLDivElement>(null);
  const SortDropdownRef = useRef<HTMLDivElement>(null);
  const ExportDropdownRef = useRef<HTMLDivElement>(null);

  const [isSortDropdownOpen, setSortDropdownOpen] = useState(false);
  const [isFilterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [isExportDropdownOpen, setExportDropdownOpen] = useState(false);
  const [selectedSort, setSelectedSort] = useState(t("Wallet.Latest") || "");
  const [sortOrder, setSortOrder] = useState("");

  const [loading, setLoading] = useState(false);
  const [first, setFirst] = useState(0);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);

  useEffect(() => {
    getBookingList();

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [page, sortOrder]);


  const onPageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    setRows(event.rows);
    setPage(event.page);
  };

  const getBookingList = async () => {
    setLoading(true);

    try {
      const res = await bookingService.getAllBookings(page + 1, sortOrder, bookingId, bookingStatus);

      if (res.status === 200) {
        const listWithDefaults = res.data.BookingDetails.map(
          (item: any, idx: any) => ({
            ...item,
            index: idx + 1,
            amount: item.amount != null ? formatCurrency(item.amount) : "-",
            booking_date_time: Helpers.formatDateString(item.booking_date_time),
            operatorName: item.operator_name || "-",
            serviceProviderName: item.serviceProviderName || "-",
            payment_status:
              item.payment_status === "COMPLETED" ? "Completed" : "Pending",
            payment_reference: item.payment_reference || "-",
          })
        );
        console.log(listWithDefaults);

        setAllBookings(listWithDefaults);
        setBookings(listWithDefaults);
        setTotalRecords(res.data.pagination.total);
      } else {
        setAllBookings([]);
        setBookings([]);
      }

    } catch (error) {
      console.error("error fetching booking list:", error);
    } finally {
      setLoading(false);
    }
  };

  const statusTemplate = (rowData: any) => {
    const statusColor =
      rowData.booking_status === "COMPLETED" || rowData.booking_status === "PAID"
        ? "text-green-500"
        : rowData.booking_status === "ONGOING" || rowData.booking_status === "PENDING"
          ? "text-orange-500"
          : "text-red-500";
    return <span className={statusColor}>{rowData.booking_status}</span>;
  };
  const onApply = () => {
    BookingFilter(bookingId, bookingStatus);
  };
  const BookingFilter = async (bookingIdflg: any, bookingStatusflg: any) => {
    try {
      const response = await bookingService.BookingFilter(page + 1, bookingIdflg === "" ? "" : bookingId, bookingStatusflg === "" ? "" : bookingStatus);
      console.log(response, "Booking List");

      if (response.status === 200) {
        const listWithDefaults = response.data.BookingDetails.map(
          (item: any, idx: any) => ({
            ...item,
            index: idx + 1,
            amount: formatCurrency(item.amount),
            booking_date_time: Helpers.formatDateString(item.booking_date_time),
            operatorName: item.operator_name || "-",
            serviceProviderName: item.serviceProviderName || "-",
            booking_status:
              item.booking_status,
            payment_reference: item.payment_reference || "-",
          })
        );

        setAllBookings(response.data.BookingDetails);
        setBookings(listWithDefaults);
        setFilterDropdownOpen(false);
      }
      else {
        console.log("empty")
        setAllBookings([]);
        setBookings([]);
      }
    } catch (error) {
      console.error("Error fetching booking list:", error);
      setAllBookings([]);
      setBookings([]);
      setFilterDropdownOpen(true);
    }
    console.log(bookings, "inside");
  };

  const columns = [
    {
      field: "index",
      label: "S No",
      sortable: true,
    },
    {
      field: "booking_date_time",
      label: t("Wallet.DateTime"),
      sortable: true,
    },
    {
      field: "booking_id",
      label: t("Booking.BookingId"),
      sortable: true,
    },
    {
      field: "operatorName",
      label: t("Operators.OperatorName"),
      sortable: true,
    },
    {
      field: "driver_name",
      label: t("Booking.ServiceProviderName"),
      sortable: true,
    },
    {
      field: "payment_reference",
      label: t("Booking.PaymentReferenceID"),
      sortable: true,
    },
    {
      field: "amount",
      label: t("Booking.Amount"),
      sortable: true,
    },
    {
      field: "booking_status",
      label: t("Booking.Status"),
      sortable: true,
      body: statusTemplate,
    },
  ];

  const handleRowClick = (e: any) => {
    const bookingData = { index: e.data.index, bookingId: e.data.booking_id };
    const encryptedData = encryptParams(bookingData);
    const encryptedParam = encodeURIComponent(encryptedData);
    navigate(`/bookings/${encryptedParam}`);
    console.log("Clicked ID:", e.data.booking_id);
  };

  const toggleSortDropdown = () => {
    setSortDropdownOpen(!isSortDropdownOpen);
    setFilterDropdownOpen(false);
    setExportDropdownOpen(false);
  };

  const toggleFilterDropdown = () => {
    setFilterDropdownOpen(!isFilterDropdownOpen);
    setSortDropdownOpen(false);
    setExportDropdownOpen(false);
  };

  const toggleExportDropdown = () => {
    setExportDropdownOpen(!isExportDropdownOpen);
    setSortDropdownOpen(false);
    setFilterDropdownOpen(false);
  };

  const handleSortChange = (sortOption: string) => {
    setSelectedSort(sortOption);
    sortOption === "Latest" ? setSortOrder("asc") : setSortOrder("desc");
    getBookingList();
    setSortDropdownOpen(false);
  };

  const clearAllFields = () => {
    setBookingId("");
    setSpName("");
    setBookingStatus("");
    BookingFilter("", "");
    setFilterDropdownOpen(false)
  };

  const exportToExcel = () => {
    console.log("mani");
    const formattedData = bookings.map((item: any) => ({
      DateTime: item.booking_date_time,
      BookingID: item.bookingId,
      operatorName: item.operatorName,
      ServiceProviderName: item.driver_name,
      paymentReferenceId: item.payment_reference,
      amount: item.amount,
      status: item.booking_status,
    }));
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Bookings");
    XLSX.writeFile(workbook, "Bookings.xlsx");
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableColumn = columns.map((col) => col.label);
    const tableRows = bookings.map((item: any,index:any) => [
      index + 1,
      item.booking_date_time,
      item.booking_id,
      item.operatorName,
      item.driver_name,
      item.payment_reference,
      item.amount,
      item.booking_status,
    ]);

    autoTable(doc, {
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      styles: { fontSize: 10 },
      headStyles: {
        fillColor: [231, 246, 254],
        textColor: [0, 0, 0],
      },
    });

    doc.save("Bookings.pdf");
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      filterDropdownRef.current &&
      !filterDropdownRef.current.contains(event.target as Node)
    ) {
      setFilterDropdownOpen(false);
    }
    if (
      ExportDropdownRef.current &&
      !ExportDropdownRef.current.contains(event.target as Node)
    ) {
      setExportDropdownOpen(false);
    }
    if (
      SortDropdownRef.current &&
      !SortDropdownRef.current.contains(event.target as Node)
    ) {
      setSortDropdownOpen(false);
    }
  };

  return (
    <div className="flex flex-col gap-[16px]">
      <span className="custom-page-heading text-[#3E4044]">
        {t("sidebar.bookings")}
      </span>
      <div className="flex flex-row justify-between px-1 items-center">
        <span className="text-[#3E4044] text-[18px] font-medium leading-[27px]">
          {t("ServiceProvider.List")}
        </span>
        <div className="flex flex-row gap-[8px]">
          <div className="relative">
            <button
              className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
              onClick={toggleSortDropdown}
            >
              {t("Wallet.SortBy")}: {selectedSort}
              <MdArrowDropDown />
            </button>
            {isSortDropdownOpen && (
              <div
                ref={SortDropdownRef}
                className="absolute bg-white border border-gray-300 rounded shadow-md mt-[5px] w-[130px] z-[500]"
              >
                <button
                  className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100 border-b"
                  onClick={() => handleSortChange(t("Wallet.Latest"))}
                >
                  <span className="text-[16px] font-normal leading-[24px]">
                    {t("Wallet.Latest")}
                  </span>
                </button>
                <button
                  className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100"
                  onClick={() => handleSortChange(t("Wallet.Oldest"))}
                >
                  <span className="text-[16px] font-normal leading-[24px]">
                    {t("Wallet.Oldest")}
                  </span>
                </button>
              </div>
            )}
          </div>

          {/* Filter Dropdown */}
          <div className="relative">
            <button
              className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
              onClick={toggleFilterDropdown}
            >
              {t("Wallet.Filter")}
              <VscSettings />
            </button>
            {isFilterDropdownOpen && (
              <div
                ref={filterDropdownRef}
                className="absolute flex flex-col right-[-85px] gap-[20px] bg-white border border-gray-300 rounded shadow-md mt-[5px] p-5 w-[390px] z-[500]"
              >
                <div className="text-[#3E4044] text-[18px] font-medium leading-[27px]">
                  {t("Wallet.Filter")}
                </div>

                <div className="text-[14px] leading-[24px] font-normal text-[#00000099]">
                  <FloatingLabelInput
                    id="email_float_label"
                    type="text"
                    label={t("Booking.BookingId")}
                    value={bookingId}
                    onChange={(e) => setBookingId(e.target.value)}
                  />
                </div>
                <div className="text-[14px] leading-[24px] font-normal text-[#00000099]">
                  <FloatingLabelSelect
                    // key={status}
                    id="paymenttype"
                    label={t("BookingDetails.BookingSts")}
                    value={bookingStatus}
                    onChange={(e: any) => setBookingStatus(e.target.value)}
                    options={[
                      { value: "COMPLETED", label: "COMPLETED" },
                      { value: "PENDING", label: "PENDING" },
                      { value: "ONGOING", label: "ONGOING" },
                      { value: "FAILED", label: "FAILED" },
                      { value: "CANCELLED", label: "CANCELLED" },
                    ]}
                  />
                </div>
                <div className="flex flex-col gap-[16px]">
                  <button
                    className="block w-full text-center px-[8px] py-[8px]"
                    onClick={clearAllFields}
                  >
                    {t("Filter.ClearAll")}
                  </button>
                  <button
                    className="block w-full text-center px-[8px] py-[8px] bg-[#2196F3] text-white"
                    onClick={onApply}
                  >
                    {t("Filter.Apply")}
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* Export Dropdown */}
          <div className="relative">
            <button
              className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
              onClick={toggleExportDropdown}
            >
              {t("Wallet.Export")}
              <MdArrowDropDown />
            </button>
            {isExportDropdownOpen && (
              <div
                ref={ExportDropdownRef}
                className="absolute right-0 bg-white border border-gray-300 rounded shadow-md mt-[5px] w-[160px] z-[500]"
              >
                <button
                  className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100 border-b"
                  onClick={() => {
                    setExportDropdownOpen(false);
                    exportToPDF();
                  }}
                >
                  <span className="text-[16px] font-normal leading-[24px]">
                    {t("Export.ExportASPdf")}
                  </span>
                </button>
                <button
                  className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100"
                  onClick={() => {
                    setExportDropdownOpen(false);
                    exportToExcel();
                  }}
                >
                  <span className="text-[16px] font-normal leading-[24px]">
                    {t("Export.ExportASXlsx")}
                  </span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div>
        {loading ? (
          <div className="mt-40">
            <Loader />
          </div>
        ) : bookings.length === 0 ? (
          <NoDataFound />
        ) : (
          <>
            <DataTable
              value={bookings}
              rows={10}
              tableStyle={{ minWidth: "50rem" }}
              rowHover
              onRowClick={handleRowClick}
            >
              {columns.map((col, index) => (
                <Column
                  key={index}
                  field={col.field}
                  header={col.label}
                  sortable={col.sortable}
                  body={col.body}
                  headerClassName="custom-header-bg"
                  className="custom-col-bg cursor-pointer"
                />
              ))}
            </DataTable>
            <Paginator
              first={first}
              rows={rows}
              totalRecords={totalRecords}
              onPageChange={onPageChange}
            />
          </>
        )}
      </div>
    </div>
  );
}
