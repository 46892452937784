import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import FloatingLabelInput from '../common/FloatingLabelInput';
import { useParams } from 'react-router-dom';
import { decryptParams } from '../../utils/cryptoUtils';
import operatorService from '../../services/operatorServices';

const Apiconfiguration = forwardRef((props, ref) => {

  const [acquisition_key, setAcquisition_key] = useState('');
  const [disbursal_key,setdisbursal_key] = useState('');

  const { encryptedParam } = useParams();

  useEffect(() => {
    if (encryptedParam) {
      fetchOperatorDetails(encryptedParam);
    }
  }, [encryptedParam]);

  const fetchOperatorDetails = (encryptedParam: string | undefined) => {
    if (!encryptedParam) return;

    try {
      const decodedParam = decodeURIComponent(encryptedParam).replace(/\s/g, "+");
      const decryptedParam: any = decryptParams(decodedParam);

      if (decryptedParam) {
        console.log(decryptedParam);
        operatorService.getOperatorById(decryptedParam?.operatorId)
          .then((res) => {
            console.log(res)
            setAcquisition_key(res.data.data.payment_gateway_key);
            setdisbursal_key(res.data.data.disbursal_key)

          })
          .catch((error) => {
            console.error("Error fetching operator details:", error);
          });
      }
    } catch (error) {
      console.error("Error processing the encrypted parameter:", error);
    }
  };

  useImperativeHandle(ref, () => ({
    getDetails() {
      return {
        acquisition_key,
        disbursal_key
      };
    },
  }));

  return (
    <div>
      <div>
        <label htmlFor="api" className="input-label-settings"></label>
        <FloatingLabelInput
          id="api_float_label"
          type="text"
          label="Payment Acquirer API"
          value={acquisition_key}
          onChange={(e) => setAcquisition_key(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="paymentacquirer" className="input-label-settings"></label>
        <FloatingLabelInput
          id="paymentacquirer_float_label"
          type="text"
          label="Payment Disbursal API"
          value={disbursal_key}
          onChange={(e) => setdisbursal_key(e.target.value)}
        />
      </div>




    </div>
  );
});

export default Apiconfiguration;