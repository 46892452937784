import React, { useState, useEffect } from 'react';
import profile from '../../assets/userdefault.png';
import usFlag from '../../assets/usFlag.png';
import frFlag from '../../assets/frFlag.png';
import esFlag from '../../assets/esFlag.png';
import ptFlag from '../../assets/ptFlag.png';
import { useTranslation } from 'react-i18next';

export default function Header() {
  const { t, i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    localStorage.getItem('language') || 'ENGLISH (UK)'
  );
  const email = localStorage.getItem("email")
  const name = localStorage.getItem("name")
  // Flag icon mapping based on language selection
  const languageFlagMap: { [key: string]: string } = {
    'ENGLISH (UK)': usFlag,
    // 'ENG (UK)': ukFlag, // UK commented out as requested
    'FRENCH (FR)': frFlag,
    'SPANISH (ES)': esFlag,
    'PORTUGUESE (PT)': ptFlag,
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      changeLanguage(savedLanguage);
    }
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLanguageChange = (language: string) => {
    setSelectedLanguage(language);
    changeLanguage(language);
    setDropdownOpen(false);

    // Change language in i18n
    if (language === 'ENGLISH (UK)') i18n.changeLanguage('en_us');
    else if (language === 'FRENCH (FR)') i18n.changeLanguage('fr');
    else if (language === 'SPANISH (ES)') i18n.changeLanguage('es');
    else if (language === 'PORTUGUESE (PT)') i18n.changeLanguage('pt');
  };

  const changeLanguage = (language: string) => {
    let langCode = '';
    switch (language) {
      case 'ENGLISH (UK)':
        langCode = 'en_us';
        break;
      case 'FRENCH (FR)':
        langCode = 'fr';
        break;
      case 'SPANISH (ES)':
        langCode = 'es';
        break;
        case 'PORTUGUESE (PT)':
        langCode = 'pt';
        break;
      default:
        langCode = 'en_us';
    }
    i18n.changeLanguage(langCode);
    localStorage.setItem('language', language);
  };

  return (
    <div className='bg-[#FFFFFF] h-[80px] px-[24px] py-[16px] flex justify-end items-center border-b border-[#0000001F]'>
      {/* <div className='flex flex-row items-center gap-[16px]'>
        <div className="relative">
          <svg className='absolute left-3 top-1/2 transform -translate-y-1/2 text-slate-400 h-5 w-5' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.0156 18.2168L14.2555 13.2662C15.4794 11.8113 16.15 9.98069 16.15 8.07499C16.15 3.62251 12.5275 0 8.07499 0C3.62251 0 0 3.62251 0 8.07499C0 12.5275 3.62251 16.15 8.07499 16.15C9.74652 16.15 11.3394 15.6458 12.7013 14.6888L17.4975 19.677C17.6979 19.8852 17.9676 20 18.2565 20C18.53 20 18.7895 19.8957 18.9864 19.7061C19.4049 19.3034 19.4182 18.6357 19.0156 18.2168ZM8.07499 2.10652C11.3661 2.10652 14.0435 4.78391 14.0435 8.07499C14.0435 11.3661 11.3661 14.0435 8.07499 14.0435C4.78391 14.0435 2.10652 11.3661 2.10652 8.07499C2.10652 4.78391 4.78391 2.10652 8.07499 2.10652Z" fill="#3E4044" />
          </svg>
          <input
            type="text"
            placeholder={t('header.Search Placeholder')}
            className='text-sm min-h-[53px] custom-width-search pl-[40px] rounded-[57px] bg-[#F4F6FA] focus:outline-cyan-200' />
        </div>
      </div> */}

      <div className='flex flex-row gap-[24px] items-center'>
        <div className='relative'>
          <div
            className='flex flex-row gap-[5px] items-center cursor-pointer'
            onClick={toggleDropdown}
          >
            <img src={languageFlagMap[selectedLanguage] || usFlag}  alt='lang'  className='w-6 h-6 object-contain rounded' />
            <div className='flex flex-row items-center gap-[5px] text-[14px] font-semibold leading-[21px]'>
              {selectedLanguage}
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  transform: dropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s ease'
                }}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.0145 8.27036C5.37498 7.90988 5.95945 7.90988 6.31993 8.27036L11.8211 13.7714L17.3222 8.27036C17.6827 7.90988 18.2671 7.90988 18.6276 8.27036C18.9881 8.63084 18.9881 9.21531 18.6276 9.57575L12.4737 15.7296C12.3007 15.9028 12.0659 16 11.8211 16C11.5763 16 11.3414 15.9028 11.1684 15.7296L5.0145 9.57575C4.65402 9.21531 4.65402 8.63084 5.0145 8.27036Z"
                  fill="#3E4044"
                />
              </svg>
            </div>
          </div>
          {dropdownOpen && (
            <div className='absolute right-0 mt-2 w-[220px] p-[8px] bg-white border border-gray-200 rounded-md shadow-lg z-10'>
              <ul>
                {Object.entries(languageFlagMap).map(([language, flag]) => (
                  <li
                    key={language}
                    className='flex flex-row items-center gap-2 px-4 py-2 hover:bg-gray-100 cursor-pointer text-[14px] font-semibold leading-[21px]'
                    onClick={() => handleLanguageChange(language)}
                  >
                    <img src={flag} alt={language} loading='lazy' className='w-6 h-6 object-contain' />
                    {language}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        {/* <div className='flex flex-row'>
          <div className='flex flex-col p-2 items-end'>
            <div>{t('Alex')}</div>
            <div className='text-[#6C6C6C] text-[14px] font-normal leading-[21px]'>{"LynkPay"}</div>
          </div>
          <img src={profile} alt='profile' loading='lazy' className='w-full h-auto object-contain rounded' />
        </div> */}
        <div className="flex flex-row items-center gap-[2px]">
          <div className="flex flex-col p-2 items-end">
            <div><span className='text-[18px]'> {name}</span></div>
            <div className="text-[#6C6C6C] text-[14px] font-normal leading-[21px]">{email}</div>
          </div>
          <div className="w-[50px] h-[50px] object-contain " >
            <img src={profile} alt="profile" className='rounded-[25px]' />
          </div>
        </div>
      </div>
    </div>
  );
}
