import { useEffect, useState, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { MdArrowDropDown } from 'react-icons/md';
import { VscSettings } from 'react-icons/vsc';

import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import autoTable from 'jspdf-autotable';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";

import NoDataFound from '../../../components/common/NoDataFound';
import SettlementsServices from '../../../services/settlementsServices';
import operatorService from '../../../services/operatorServices';
import { useParams } from 'react-router-dom';
import { decryptParams } from '../../../utils/cryptoUtils';
import Helpers from '../../../utils/helpers';
import Loader from '../../../components/common/Loder';
import FloatingLabelInput from '../../../components/common/FloatingLabelInput';
import moment from 'moment';
import { formatCurrency } from '../../../utils/formatCurrency';

export default function Settlement() {

  const { t } = useTranslation();
  const { encryptedParam } = useParams()

  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false)
  const [bankName, setBankName] = useState('');
  const [upcomingSettlements, setUpcomingSettlements]: any = useState({});
  const [transactionId, setTransactionId] = useState('');
  const [allSettlements, SetAllSettlements] = useState([])
  const [isSortDropdownOpen, setSortDropdownOpen] = useState(false);
  const [isFilterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [isExportDropdownOpen, setExportDropdownOpen] = useState(false);
  const [selectedSort, setSelectedSort] = useState(t('Wallet.Latest'));

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const filterDropdownRef = useRef<HTMLDivElement>(null);
  const SortDropdownRef = useRef<HTMLDivElement>(null);
  const ExportDropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {

    getOperatorSettlements(encryptedParam, "DESC", "")
    document.addEventListener("mousedown", handleClickOutside);
    return () => { document.removeEventListener("mousedown", handleClickOutside); };

  }, [page]);


  const handleSortChange = (sortOption: string) => {
    setSelectedSort(sortOption);
    setSortDropdownOpen(false);

    const sortOrder = sortOption === t('Wallet.Latest') ? "DESC" : "ASC";

    if (encryptedParam) {
      getOperatorSettlements(encryptedParam, sortOrder, "");
      setFilterDropdownOpen(false);
    }
  };


  const getOperatorSettlements = async (encryptedParam: any, sort_order: string, transactionIdflg: any) => {
    setLoading(true)

    const decodedParam = decodeURIComponent(encryptedParam);
    const decryptedParam: any | null = decryptParams(decodedParam);

    try {
      const res = await operatorService.getSettlementsHistoryByOperatorId(decryptedParam.operatorId, page + 1, sort_order, transactionIdflg === "" ? "" : transactionId);
      console.log("Fetched settlements details of operator:", res);
      const totalRecords = res?.data?.pagination?.total_records || 0;
      setTotalRecords(totalRecords);
      SetAllSettlements(res.data.sp_breakdown);
      setUpcomingSettlements(res.data?.upcomingSettlement);

    } catch (error) {
      console.error("Error fetching settlements details of operator:", error);
    } finally {
      setLoading(false)
      console.log("Finished fetching operator settlements.");
    }
  }


  const onPageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    setRows(event.rows);
    setPage(event.page);
  };

  const columns: any = [
    {
      field: "index",
      label: t("Settlements.Sno"),
      sortable: true,
      body: (rowData: any, options: { rowIndex: number }) =>
        options.rowIndex + 1 || "-",
    },
    {
      label: t("Wallet.DateTime"),
      field: "end_date",
      sortable: true,
      body: (rowData: any) =>
        rowData.end_date
          ? moment(rowData.end_date).format('DD MMM YYYY - hh:mm A')
          : '-'
    },
    {
      label: t("Wallet.TranID"),
      field: "transaction_id",
      sortable: true,
      body: (rowData: any) => rowData.transaction_id || "-",
    },
    {
      label: t("Settlements.BankName"),
      field: "bank_name",
      sortable: true,
      body: (rowData: any) => rowData.bank_name || "-",
    },
    {
      label: t("Settlements.Description"),
      field: "description",
      sortable: true,
      body: (rowData: any) => rowData.description || "-",
    },
    {
      label: t('Settlements.SettlementAmt'),
      field: "amount",
      sortable: true,
      body: (rowData: any) => <span className="text-green-500">{formatCurrency(rowData.amount)}</span>

    }
  ]

  const toggleSortDropdown = () => {
    setSortDropdownOpen(!isSortDropdownOpen);
    setFilterDropdownOpen(false);
    setExportDropdownOpen(false);
  };

  const toggleFilterDropdown = () => {
    setFilterDropdownOpen(!isFilterDropdownOpen);
    setSortDropdownOpen(false);
    setExportDropdownOpen(false);
  };

  const toggleExportDropdown = () => {
    setExportDropdownOpen(!isExportDropdownOpen);
    setSortDropdownOpen(false);
    setFilterDropdownOpen(false);
  };
  interface DecryptedParam {
    index?: any;
    operatorId?: string;
  }


  const clearAllFields = () => {
    setTransactionId('');
    setBankName('');

    if (encryptedParam) {
      getOperatorSettlements(encryptedParam, "DESC", "");
      setFilterDropdownOpen(false);

    }

  };

  const exportToExcel = () => {
    const formattedData = allSettlements.map((item: any) => ({
      Sno: item.id,
      BookingDateTime: item.dateTime,
      TransactionID: item.transactionId,
      BankName: item.bankName,
      Description: item.description,
      SettlementAmount: item.total

    }));
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Operator_Settlement');
    XLSX.writeFile(workbook, 'Operator_Settlement.xlsx');
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableColumn = columns.map((col: any) => col.label);
    const tableRows = allSettlements.map((item: any) => [
      item.id,
      item.dateTime,
      item.transactionId,
      item.bankName,
      item.description,
      item.total,
    ]);

    autoTable(doc, {
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      styles: { fontSize: 10, }, // Global text color for body
      headStyles: {
        fillColor: [231, 246, 254],  // Background color for header
        textColor: [0, 0, 0]          // Black text color for header
      }
    });


    doc.save('Operator_Settlement.pdf');
  };


  const handleClickOutside = (event: MouseEvent) => {
    if (filterDropdownRef.current && !filterDropdownRef.current.contains(event.target as Node)) {
      setFilterDropdownOpen(false);
    }
    if (ExportDropdownRef.current && !ExportDropdownRef.current.contains(event.target as Node)) {
      setExportDropdownOpen(false);
    }
    if (SortDropdownRef.current && !SortDropdownRef.current.contains(event.target as Node)) {
      setSortDropdownOpen(false);
    }
  };

  const onApply = () => {
    if (encryptedParam) {
      getOperatorSettlements(encryptedParam, "DESC", transactionId);
      setFilterDropdownOpen(false);

    }
  };

  return (
    <div className="flex flex-col">
      <div className="p-[24px] bg-[#ffffff] rounded-[8px] mb-[16px]">
        <section>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-4 gap-x-8">
            <div className='flex flex-col gap-[4px]'>
              <span className='text-[14px] font-semibold leading-[21px] text-[#3E4044'>{t('Operators.TotalSettledAmount')}</span>
              <span className="text-[14px] font-normal leading-[21px] text-[#6C6C6C]">{formatCurrency(upcomingSettlements?.total_amount_to_be_settled || "-")}</span>
            </div>
            <div className='flex flex-col gap-[4px]'>
              <span className='text-[14px] font-semibold leading-[21px] text-[#3E4044'>{t('ServiceProvider.CurrentWalletAmount')}</span>
              <span className="text-[14px] font-normal leading-[21px] text-[#6C6C6C]">{formatCurrency(upcomingSettlements?.wallet_balance || "-")}</span>
            </div>
            <div className='flex flex-col gap-[4px]'>
              <span className='text-[14px] font-semibold leading-[21px] text-[#3E4044'>{t('Settlements.NxtSettlement')}</span>
              <span className="text-[14px] font-normal leading-[21px] text-[#6C6C6C]">{upcomingSettlements?.next_settlement_date || "-"}</span>
            </div>
            <div className='flex flex-col gap-[4px]'>
              <span className='text-[14px] font-semibold leading-[21px] text-[#3E4044'>{t('Settlements.SettlementCycle')}</span>
              <span className="text-[14px] font-normal leading-[21px] text-[#6C6C6C]">{upcomingSettlements?.settlement_cycle || "-"}</span>
            </div>
            <div className='flex flex-col gap-[4px]'>
              <span className='text-[14px] font-semibold leading-[21px] text-[#3E4044'>{t('Operators.PayOutDay')}</span>
              <span className="text-[14px] font-normal leading-[21px] text-[#6C6C6C]">{upcomingSettlements?.next_settlement_day || "-"}</span>
            </div>
          </div>
        </section>
      </div>
      {/* Datatable */}
      <div className="flex flex-col">
        <div className="flex justify-between items-center">

          <span className="custom-page-heading mb-[16px]">{t('ServiceProvider.SettlementsList')}</span>
          <div className="flex gap-[8px]">
            {/* Sort by Latest Button */}
            <div className="relative">
              <button
                className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
                onClick={toggleSortDropdown}
              >
                {t('Wallet.SortBy')}: {selectedSort}
                <MdArrowDropDown />
              </button>
              {isSortDropdownOpen && (
                <div ref={SortDropdownRef} className="absolute bg-white border border-gray-300 rounded shadow-md mt-[5px] w-[130px] z-[500]">
                  <button
                    className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100 border-b"
                    onClick={() => handleSortChange(t('Wallet.Latest'))}
                  >
                    <span className="text-[16px] font-normal leading-[24px]">{(t('Wallet.Latest'))}</span>
                  </button>
                  <button
                    className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100"
                    onClick={() => handleSortChange(t('Wallet.Oldest'))}
                  >
                    <span className="text-[16px] font-normal leading-[24px]">{(t('Wallet.Oldest'))}</span>
                  </button>
                </div>
              )}
            </div>

            {/* Filter Dropdown */}
            <div className="relative">
              <button
                className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
                onClick={toggleFilterDropdown}
              >
                {t('Wallet.Filter')}
                <VscSettings />
              </button>
              {isFilterDropdownOpen && (
                <div ref={filterDropdownRef} className="absolute flex flex-col right-[-85px] gap-[20px] bg-white border border-gray-300 rounded shadow-md mt-[5px] p-5 w-[390px] z-[500]">
                  <div className="text-[#3E4044] text-[18px] font-medium leading-[27px]">
                    {t('Wallet.Filter')}
                  </div>

                  <div className="text-[14px] leading-[24px] font-normal text-[#00000099]">
                    <FloatingLabelInput
                      id="email_float_label"
                      type="text"
                      label={t('Filter.TransactionsID')}
                      value={transactionId}
                      onChange={(e: any) => setTransactionId(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col gap-[16px]">
                    <button
                      className="block w-full text-center px-[8px] py-[8px]"
                      onClick={clearAllFields}
                    >
                      {t('Filter.ClearAll')}
                    </button>
                    <button
                      className="block w-full text-center px-[8px] py-[8px] bg-[#2196F3] text-white" onClick={onApply}
                    >
                      {t('Filter.Apply')}
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="relative">
              <button
                className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
                onClick={toggleExportDropdown}
              >
                {t('Wallet.Export')}
                <MdArrowDropDown />
              </button>
              {isExportDropdownOpen && (
                <div ref={ExportDropdownRef} className="absolute right-0 bg-white border border-gray-300 rounded shadow-md mt-[5px] w-[160px] z-[500]">
                  <button
                    className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100 border-b"
                    onClick={() => {
                      setExportDropdownOpen(false);
                      exportToPDF()
                    }}
                  >
                    <span className="text-[16px] font-normal leading-[24px]">{t('Export.ExportASPdf')}</span>
                  </button>
                  <button
                    className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100"
                    onClick={() => {
                      setExportDropdownOpen(false);
                      exportToExcel()
                    }}
                  >
                    <span className="text-[16px] font-normal leading-[24px]" >{t('Export.ExportASXlsx')}</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {loading ? (
          <div className='mt-40' ><Loader /></div>
        ) : allSettlements.length === 0 ? (
          <NoDataFound />
        ) : (
          <>
            <DataTable value={allSettlements} rows={10}>
              {columns.map((item: any) => (
                <Column
                  field={item.field}
                  header={item.label}
                  headerClassName='custom-header-bg'
                  className='custom-col-bg'
                  sortable={item.sortable}
                  body={item.body}
                />
              ))}
            </DataTable>
            <Paginator
              first={first}
              rows={rows}
              totalRecords={totalRecords}
              onPageChange={onPageChange}
            />
          </>
        )


        }
      </div>
    </div>
  );
}
