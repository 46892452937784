import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RiArrowLeftSLine } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import pdf from "../../../assets/pdf.png";
import { decryptParams } from "../../../utils/cryptoUtils";
import operatorService from "../../../services/operatorServices";
import ButtonLoader from "../../../components/common/ButtonLoader";
import RejectConfirmationModal from "../../../components/Modal/RejectConfirmationModal";
import Modal from "../../../components/common/Modal";
import EyeIcon from "../../../components/common/EyeIcon";

export interface OperatorDetails {
  operator_id: number | null;
  name: string;
  address_1: string;
  address_2: string | null;
  city: string | null;
  state: string | null;
  zipcode: string | null;
  company_name: string | null;
  mobile_number: string;
  email_id: string | null;
  registration_number: string;
  contract_status: string;
  contract_signed_document_path: string;
  account_status: string;
  onboard_date: string | null;
  created_datetime: string | null;
  updated_datetime: string | null;
  created_by: string | null;
  updated_by: string | null;
  is_email_verified: boolean;
  password: string | null;
  business_name: string;
  business_type: string;
  country_of_incorporation: string;
  tax_registration_number: string;
  director_name: string;
  business_reg_document_path: string;
  tax_reg_document_path: string;
  id_proof_document_path: string;
  address_proof_document_path: string;
  onboarding_status: string;
  current_step: number | null;
  is_golive_ready: boolean;
  payment_gateway_key: string;
  disbursal_key: any;
}

const initialOperatorDetails = {
  operator_id: null,
  name: "",
  address_1: "",
  address_2: "",
  city: "",
  state: "",
  zipcode: "",
  company_name: "",
  mobile_number: "",
  email_id: "",
  registration_number: "",
  contract_status: "",
  contract_signed_document_path: "",
  account_status: "",
  onboard_date: "",
  created_datetime: "",
  updated_datetime: "",
  created_by: "",
  updated_by: "",
  is_email_verified: false,
  password: "",
  business_name: "",
  business_type: "",
  country_of_incorporation: "",
  tax_registration_number: "",
  director_name: "",
  business_reg_document_path: "",
  tax_reg_document_path: "",
  id_proof_document_path: "",
  address_proof_document_path: "",
  onboarding_status: "",
  current_step: null,
  is_golive_ready: false,
  payment_gateway_key: "",
  disbursal_key: "",
};

export default function OnboardingOperatorDetails() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { encryptedParam } = useParams();
  const [modalVisible, setModalVisible] = useState(false);

  const [operatorDetails, setOperatorDetails] = useState<OperatorDetails>(
    initialOperatorDetails
  );
  const [ApproveLoading, setApproveLoading] = useState(false);
  const [showPaymentKey, setShowPaymentKey] = useState(false);
  const [showDisbursalKey, setShowDisbursalKey] = useState(false);

  useEffect(() => {
    if (encryptedParam) {
      // Decode and replace characters consistently before decrypting
      const decodedParam = decodeURIComponent(encryptedParam).replace(
        /\s/g,
        "+"
      );
      const decryptedParam: any = decryptParams(decodedParam);
      console.log("this is  de", decryptedParam);
      if (decryptedParam) {
        operatorService
          .getOperatorById(decryptedParam?.operatorId)
          .then((res) => {
            console.log("Fetched Operator Details:", res);
            setOperatorDetails(res.data.data); // Merge decryptedParam with API response
          })
          .catch((error) => {
            console.error("Error fetching operator details:", error);
          });
      }
    }
  }, [encryptedParam]);

  const handleRejectClick = () => {
    setModalVisible(true);
  };

  const handleApproveClick = async () => {
    setApproveLoading(true);
    try {
      const res = await operatorService.approveOnboardingOperator(
        operatorDetails.operator_id
      );
      toast.success("Operator Approved !");
      console.log(res.data);
    } catch (error) {
      console.error("error updating operator:", error);
    } finally {
      setApproveLoading(false);
      setTimeout(() => {
        navigate("/operators/onboarding");
      }, 500);
    }
  };

  console.log("od", operatorDetails);

  return (
    <>
      <div className="flex flex-col gap-[30px]">
        <div className="flex flex-row items-center gap-2">
          <button
            className="border border-black text-black px-6 py-2 rounded flex items-center"
            onClick={() => navigate("/operators/approved")}
          >
            <span className="flex items-center">
              <RiArrowLeftSLine className="mr-1" />
              {t("emailverify.back")}
            </span>
          </button>
          <span className="custom-page-heading px-2 py-2">
            {operatorDetails?.operator_id +
              " " +
              "-" +
              " " +
              operatorDetails?.name}
          </span>
        </div>

        {/* Render Basic Details content only if we're on the Basic Details tab */}
        <div className="p-[24px] bg-[#ffffff] rounded-[8px]">
          {/* personal Deatils */}
          <div className="flex flex-col gap-y-[32px] pb-[38px]">
            <div className="flex flex-row justify-between items-center">
              <span>{t("Operators.PersonalDetails")}</span>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-4 gap-x-8">
              <DetailItem
                label={t("Operators.Id")}
                value={operatorDetails?.operator_id}
              />
              <DetailItem
                label={t("Operators.Name")}
                value={operatorDetails?.name}
              />
              <DetailItem
                label={t("ServiceProvider.PhoneNumber")}
                value={operatorDetails?.mobile_number}
              />
              <DetailItem
                label={t("ServiceProvider.EmailID")}
                value={operatorDetails?.email_id}
              />
            </div>
          </div>
          <hr />
          {/* Business Deatail */}
          <div className="flex flex-col gap-y-[32px] py-[24px]">
            <div className="flex flex-row justify-between items-center">
              <span>{t("Operators.BusinessDetails")}</span>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-4 gap-x-8 mb-8">
              <DetailItem
                label={t("Operators.Name")}
                value={operatorDetails?.name}
              />
              <DetailItem
                label={t("Operators.Type")}
                value={operatorDetails?.business_type}
              />
              <DetailItem
                label={t("Operators.RegistrationNumber")}
                value={operatorDetails?.registration_number}
              />
              <DetailItem
                label={t("Operators.RegistrationAddress")}
                value={operatorDetails?.address_1}
              />
              <DetailItem
                label={t("Operators.CountryOfIncorporation")}
                value={operatorDetails?.country_of_incorporation}
              />
              <DetailItem
                label={t("Operators.TaxRegistrationNumber")}
                value={operatorDetails?.tax_registration_number}
              />
              <DetailItem label={t("Operators.DirectorName")} value="Name" />
              <DetailItem
                label={t("Operators.DirectorName")}
                value={operatorDetails?.director_name}
              />
            </div>
          </div>
          <hr />
          {/* upload Documents */}
          <div className="flex flex-col gap-y-[32px] py-[24px]">
            <div className="flex flex-row justify-between items-center">
              <span>{t("Operators.UploadedDocuments")}</span>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-10 gap-x-8 mb-8">
              <DetailItem
                label=""
                value={
                  <a
                    href={operatorDetails?.business_reg_document_path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-x-[16.52px] border border-1px-solid-black p-4 rounded-md"
                  >
                    <img
                      src={pdf}
                      alt="PDF Icon"
                      loading="lazy"
                      className="w-[34px] h-[34px]"
                    />
                    <span className="text-[15px] leading-[21px] text-[#6C6C6C]">
                      {t("Operators.BusinessRegistrationCertificate")}
                    </span>
                  </a>
                }
              />
              <DetailItem
                label=""
                value={
                  <a
                    href={operatorDetails?.tax_reg_document_path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-x-[16.52px] border border-1px-solid-black p-4 rounded-md"
                  >
                    <img
                      src={pdf}
                      alt="PDF Icon"
                      loading="lazy"
                      className="w-[34px] h-[34px]"
                    />
                    <span className="text-[15px] leading-[21px] text-[#6C6C6C]">
                      {t("Operators.TaxRegistrationDocument")}
                    </span>
                  </a>
                }
              />
              <DetailItem
                label=""
                value={
                  <a
                    href={operatorDetails?.id_proof_document_path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-x-[16.52px] border border-1px-solid-black p-4 rounded-md"
                  >
                    <img
                      src={pdf}
                      alt="PDF Icon"
                      loading="lazy"
                      className="w-[34px] h-[34px]"
                    />
                    <span className="text-[15px] leading-[21px] text-[#6C6C6C]">
                      {t("Operators.DirectorIDProof")}
                    </span>
                  </a>
                }
              />
              <DetailItem
                label=""
                value={
                  <a
                    href={operatorDetails?.address_proof_document_path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-x-[16.52px] border border-1px-solid-black p-4 rounded-md"
                  >
                    <img
                      src={pdf}
                      alt="PDF Icon"
                      loading="lazy"
                      className="w-[34px] h-[34px]"
                    />
                    <span className="text-[15px] leading-[21px] text-[#6C6C6C]">
                      {t("Operators.ProofAddress")}
                    </span>
                  </a>
                }
              />
            </div>
          </div>
          <hr />
          {/* contract detail */}
          <div className="flex flex-col gap-y-[32px] py-[24px]">
            <div className="flex flex-row justify-between items-center">
              <span>{t("Operators.ContractDetails")}</span>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-4 gap-x-8 mb-8">
              <DetailItem
                label=""
                value={
                  <a
                    href={operatorDetails?.contract_signed_document_path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-x-[16.52px] border border-1px-solid-black p-4 rounded-md"
                  >
                    <img
                      src={pdf}
                      alt="PDF Icon"
                      loading="lazy"
                      className="w-[34px] h-[34px]"
                    />
                    <span className="text-[15px] leading-[21px] text-[#6C6C6C]">
                      {t("Operators.contractPdf")}
                    </span>
                  </a>
                }
              />
            </div>
          </div>
          <hr />
          {/* payment detail */}
          <div className="flex flex-col gap-y-[32px] py-[24px]">
            <div className="flex flex-row justify-between items-center">
              <span>{t("BookingDetails.PaymentDetails")}</span>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-4 gap-x-8 mb-8">
              <DetailItem
                label={t("BusinessDetails.Type")}
                value="Bank Transfers"
              />
              <DetailItem
                label=""
                value={
                  <a
                    href={"pending from be"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-x-[16.52px] border border-1px-solid-black p-4 rounded-md"
                  >
                    <img
                      src={pdf}
                      alt="PDF Icon"
                      loading="lazy"
                      className="w-[34px] h-[34px]"
                    />
                    <span className="text-[15px] leading-[21px] text-[#6C6C6C]">
                      {t("Operators.PaymentReceipt")}
                    </span>
                  </a>
                }
              />
            </div>
          </div>
          <hr />
          {/* Api configuration */}
          <div className="flex flex-col gap-y-[32px] py-[24px]">
            <div className="flex flex-row justify-between items-center">
              <span>{t("Operators.APIConfigurationDetails")}</span>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-4 gap-x-8 mb-8">
              {/* Payment Acquirer API Key */}
              <div className="relative">
                <DetailItem
                  label={t("Operators.PaymentAcquirerAPIKey")}
                  value={
                    showPaymentKey
                      ? operatorDetails?.payment_gateway_key
                      : "••••••••••••••"
                  }
                />
                <EyeIcon
                  onClick={() => setShowPaymentKey((prev) => !prev)}
                  isPasswordVisible={showPaymentKey}
                />
              </div>

              {/* Payment Disbursal API Key */}
              <div className="relative">
                <DetailItem
                  label={t("Operators.PaymentDisbursalAPIKey")}
                  value={
                    showDisbursalKey
                      ? operatorDetails?.disbursal_key
                      : "••••••••••••••"
                  }
                />
                <EyeIcon
                  onClick={() => setShowDisbursalKey((prev) => !prev)}
                  isPasswordVisible={showDisbursalKey}
                />
              </div>
            </div>
          </div>
          <div className="w-full flex flex-row justify-end items-center gap-x-8">
            <button
              className="w-auto py-[8px] px-[16px] h-10 bg-[#ffffff] text-[#da3636] shadow-sm border border-[#da3636] rounded-md"
              onClick={handleRejectClick}
            >
              Reject
            </button>

            <button className="w-auto py-[8px] px-[16px] h-10 bg-[#ffffff] text-[#2196f3] shadow-sm border border-[#2196f3] rounded-md ">
              Send for Clarification
            </button>
            <Toaster />
            <button
              className={`w-auto min-w-[94px] py-[8px] px-[16px] h-10 flex flex-row items-center justify-center bg-[#2196f3] text-[#ffffff] shadow-sm rounded-md ${
                ApproveLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={ApproveLoading}
              onClick={handleApproveClick}
            >
              {ApproveLoading ? <ButtonLoader /> : "Approve"}
            </button>
          </div>
        </div>
      </div>
      {modalVisible && (
        <RejectConfirmationModal
          operatorDetails={operatorDetails}
          setModalVisible={setModalVisible}
        />
      )}
    </>
  );
}

function DetailItem({
  label,
  value,
}: {
  label: string;
  value: React.ReactNode;
}) {
  return (
    <div className="flex flex-col gap-[4px]">
      <span className="text-[14px] leading-[21px] font-semibold text-[rgba(62, 64, 68, 1)]">
        {label}
      </span>
      <span className="block text-[15px] leading-[21px] text-[#6C6C6C]">
        {value ? value : "-"}
      </span>
    </div>
  );
}
