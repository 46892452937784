import { useTranslation } from "react-i18next";
import { Link, Outlet, useLocation } from "react-router-dom";
import "jspdf-autotable";

export default function OperatorList() {
  const location = useLocation();
  const { t } = useTranslation();


  return (
    <div className="flex flex-col gap-[16px]">
      <span className="text-[22px] leading-[26.4px] font-semibold text-[#3E4044]">
        {t("sidebar.operators")}
      </span>
      <div className="flex flex-col gap-[16px]">
        <div className="flex flex-row gap-[16px] text-[14px] text-[#667085] border-b border-[#BDBDBD] font-semibold leading-[17px]">
          <Link
            to="approved"
            className={`cursor-pointer hover:text-[#03A9F4] px-[4px] pt-[1px] pb-[12px] ${
              location.pathname.includes("approved")
                ? "text-[#03A9F4]  border-b-2 border-[#03A9F4]"
                : ""
            }`}
          >
            {t('Operators.List')}
          </Link>
          <Link
            to="onboarding"
            className={`cursor-pointer hover:text-[#03A9F4] px-[4px] pt-[1px] pb-[12px] ${
              location.pathname.includes("onboarding")
                ? "text-[#03A9F4]  border-b-2 border-[#03A9F4]"
                : ""
            }`}
          >
            {t('Operators.Onboarding')}
          </Link>
        </div>
        <Outlet />
      </div>
    </div>
  );
}
