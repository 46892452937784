import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { MdArrowDropDown } from "react-icons/md";
import { VscSettings } from 'react-icons/vsc';

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";

import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import FloatingLabelSelect from "../../components/common/FloatingLabelselect";
import FloatingLabelInput from "../../components/common/FloatingLabelInput";

import SettlementsServices from "../../services/settlementsServices";
import Helpers from "../../utils/helpers";
import Loader from "../../components/common/Loder";
import NoDataFound from "../../components/common/NoDataFound";
import moment from "moment";
import Settlement from "../../features/operator/pages/Settlements";
// import noData from '../../../assets/noData.png';
// import NoDataFound from '../../../components/common/NoDataFound';
import { formatCurrency } from "../../utils/formatCurrency";

export default function History() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [startdate, setstartdate] = useState('');
  const [enddate, setenddate] = useState('');
  const [status, setStatus] = useState("");

  const [loading, setLoading] = useState(false);
  const [allSettlements, setAllSettlements] = useState<any[]>([]);
  const [operatorId, setOperatorId] = useState("");
  const [operatorName, setOperatorName] = useState("");
  const [isSortDropdownOpen, setSortDropdownOpen] = useState(false);
  const [isFilterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [isExportDropdownOpen, setExportDropdownOpen] = useState(false);
  const [selectedSort, setSelectedSort] = useState(t("Wallet.Latest"));


  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const filterDropdownRef = useRef<HTMLDivElement>(null);
  const SortDropdownRef = useRef<HTMLDivElement>(null);
  const ExportDropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchSettlementsHistory("DESC","","");

    document.addEventListener("mousedown", handleClickOutside);
    return () => { document.removeEventListener("mousedown", handleClickOutside); };
  }, [page]);

  const onPageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    setRows(event.rows);
    setPage(event.page);
  };

  console.log(allSettlements);

  const fetchSettlementsHistory = async (sort_order: string = "DESC",startdataflg:any,enddateflag:any) => {
    setLoading(true);
    SettlementsServices.getAllSettlementsHistory(page + 1, sort_order,startdataflg === "" ? "" : startdate, enddateflag === "" ? "" : enddate)
      .then((response) => {
        console.log(response, "Service Provider Bookings");
        if (response.status === 200) {
          setAllSettlements(response.data.data.settlement_history)
          setTotalRecords(response.data.data.pagination.total_records)
        }
        else {
          setAllSettlements([]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }
  const columns: any = [
    {
      field: "index",
      label: t("Settlements.Sno"),
      sortable: true,
      body: (rowData: any, options: { rowIndex: number }) => options.rowIndex + 1,
    },
    {
      field: "settlement_date",
      label: t("Wallet.DateTime"),
      sortable: true,
      body: (rowData: any) =>
        rowData.settlement_date
          ? moment(rowData.settlement_date).format('DD MMM YYYY - hh:mm A')
          : "-",
    },
    {
      field: "settlement_id",
      label: "Settlement ID",
      sortable: true,
    },
    {
      label: t("Operators.OperatorName"),
      field: "name",
      sortable: true,
    },
    {
      label: t("Settlements.tltNoServiceProvider"),
      field: "number_of_sp_settled",
      sortable: true,
    },
    // {
    //   label: t("Wallet.paymentRefId"),
    //   field: "payment_reference_id",
    //   sortable: true,
    // },
    {
      label: t("Settlements.SettlementAmt"),
      field: "total_amount_settled",
      sortable: true,
      body: (rowData: any) => {
        return <span>{formatCurrency(rowData.total_amount_settled)}</span>
      }
    },
  ];

  const toggleSortDropdown = () => {
    setSortDropdownOpen(!isSortDropdownOpen);
    setFilterDropdownOpen(false);
    setExportDropdownOpen(false);
  };

  const toggleExportDropdown = () => {
    setExportDropdownOpen(!isExportDropdownOpen);
    setSortDropdownOpen(false);
    setFilterDropdownOpen(false);
  };
  const toggleFilterDropdown = () => {
    setFilterDropdownOpen(!isFilterDropdownOpen);
    setSortDropdownOpen(false);
    setExportDropdownOpen(false);
  };

  const handleSortChange = (sortOption: string) => {
    setSelectedSort(sortOption);
    setSortDropdownOpen(false);

    const sortOrder = sortOption === t('Wallet.Latest') ? "DESC" : "ASC";
    fetchSettlementsHistory(sortOrder,"","");
  };

  const clearAllFields = () => {
    setstartdate("");
    setenddate("");

    fetchSettlementsHistory("DESC","","")
  };

  // const handleRowClick = (e: any) => {
  //   console.log(e.data.operator_id)
  //   navigate(`/Historydetail/${e.data.operator_id}`);
  //   console.log("Clicked ID:", e.data.id);
  // };

  const handleRowClick = (e: any) => {
    console.log(e.data.operator_id);
    navigate(`/Historydetail/`, {
        state: { operatorId: e.data.operator_id, SettlementID: e.data.settlement_id
           },
    });
    console.log("Clicked ID:", e.data.id);
};

  const exportToExcel = () => {
    const formattedData = allSettlements.map((item: any) => ({
      Sno: item.id,
      DateTime: item.dateTime,
      TotalNumberofServiceProviders: item.totalSP,
      PaymentRefID: item.paymentRefId,
      SettlementAmt: item.total,
    }));
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Settlements_History");
    XLSX.writeFile(workbook, "Settlements_History.xlsx");
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableColumn = columns.map((col: any) => col.label);
    const tableRows = allSettlements.map((item: any) => [
      item.id,
      item.dateTime,
      item.totalSP,
      item.paymentRefId,
      item.total,
    ]);

    autoTable(doc, {
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      styles: { fontSize: 10 },
      headStyles: {
        fillColor: [231, 246, 254],
        textColor: [0, 0, 0],
      },
    });
    doc.save("Settlements_History.pdf");
  };
  const onApply = () => {
    fetchSettlementsHistory("",startdate,enddate);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (filterDropdownRef.current && !filterDropdownRef.current.contains(event.target as Node)) {
      setFilterDropdownOpen(false);
    }
    if (ExportDropdownRef.current && !ExportDropdownRef.current.contains(event.target as Node)) {
      setExportDropdownOpen(false);
    }
    if (SortDropdownRef.current && !SortDropdownRef.current.contains(event.target as Node)) {
      setSortDropdownOpen(false);
    }
  };
  return (
    <div className="flex flex-col">
      {/* Data table */}
      <div className="flex flex-col gap-[16px]">
        <div className="flex justify-between items-center">
          <div className="flex flex-col gap-4">
            <span className="custom-page-heading text-[22px] text-[#3E4044]">
              {t("Settlements.Settlements")}
            </span>
            <div className="text-[18px] text-[#3E4044]">
              {t("Settlements.History")}
            </div>
          </div>

          <div className="flex gap-[8px]">
            {/* Sort by Latest Button */}
            <div className="relative">
              <button
                className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
                onClick={toggleSortDropdown}
              >
                {t("Wallet.SortBy")}: {selectedSort}
                <MdArrowDropDown />
              </button>
              {isSortDropdownOpen && (
                <div ref={SortDropdownRef} className="absolute bg-white border border-gray-300 rounded shadow-md mt-[5px] w-[130px] z-[500]">
                  <button
                    className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100 border-b"
                    onClick={() => handleSortChange(t("Wallet.Latest"))}
                  >
                    <span className="text-[16px] font-normal leading-[24px]">
                      {t("Wallet.Latest")}
                    </span>
                  </button>
                  <button
                    className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100"
                    onClick={() => handleSortChange(t("Wallet.Oldest"))}
                  >
                    <span className="text-[16px] font-normal leading-[24px]">
                      {t("Wallet.Oldest")}
                    </span>
                  </button>
                </div>
              )}
            </div>
            {/* Filter Dropdown */}
            <div className="relative" ref={filterDropdownRef}>
              <button
                className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
                onClick={toggleFilterDropdown}
              >
                {t('Wallet.Filter')}
                <VscSettings />
              </button>
              {isFilterDropdownOpen && (
                <div className="absolute flex flex-col right-[-85px] gap-[20px] bg-white border border-gray-300 rounded shadow-md mt-[5px] p-5 w-[390px] z-[500]">
                  <div className="text-[#3E4044] text-[18px] font-medium leading-[27px]">
                    {t('Wallet.Filter')}
                  </div>

                  <div className="text-[14px] leading-[24px] font-normal text-[#00000099]">
                    <FloatingLabelInput
                      id="start_date_label"
                      type="date"
                      label={t('Filter.startdate')}
                      value={startdate}
                      onChange={(e) => setstartdate(e.target.value)}
                    />
                  </div>
                  <div className="text-[14px] leading-[24px] font-normal text-[#00000099]">
                    <FloatingLabelInput
                      id="end_date_label"
                      type="date"
                      label={t('Filter.enddate')}
                      value={enddate}
                      onChange={(e) => setenddate(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col gap-[16px]">
                    <button
                      className="block w-full text-center px-[8px] py-[8px]"
                      onClick={clearAllFields}
                    >
                      {t('Filter.ClearAll')}
                    </button>
                    <button
                      className="block w-full text-center px-[8px] py-[8px] bg-[#2196F3] text-white"
                      onClick={onApply}
                    >
                      {t('Filter.Apply')}
                    </button>
                  </div>
                </div>
              )}
            </div>
            {/* Export Dropdown */}
            <div className="relative">
              <button
                className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
                onClick={toggleExportDropdown}
              >
                {t("Wallet.Export")}
                <MdArrowDropDown />
              </button>
              {isExportDropdownOpen && (
                <div ref={ExportDropdownRef} className="absolute right-0 bg-white border border-gray-300 rounded shadow-md mt-[5px] w-[160px] z-[500]">
                  <button
                    className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100 border-b"
                    onClick={() => {
                      setExportDropdownOpen(false);
                      exportToPDF();
                    }}
                  >
                    <span className="text-[16px] font-normal leading-[24px]">
                      {t("Export.ExportASPdf")}
                    </span>
                  </button>
                  <button
                    className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100"
                    onClick={() => {
                      setExportDropdownOpen(false);
                      exportToExcel();
                    }}
                  >
                    <span className="text-[16px] font-normal leading-[24px]">
                      {t("Export.ExportASXlsx")}
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          {loading ? (
            <div className="mt-40">
              <Loader />
            </div>
          ) : allSettlements.length === 0 ? (
            <NoDataFound />
          ) : (
            <>
              <DataTable
                value={Array.isArray(allSettlements) ? allSettlements : []}
                rows={3}
                tableStyle={{ minWidth: "70rem" }}
                rowHover
                onRowClick={handleRowClick}
              >
                {columns.map((item: any) => (
                  <Column
                    field={item.field}
                    header={item.label}
                    headerClassName="custom-header-bg"
                    className="custom-col-bg"
                    sortable={item.sortable}
                    body={item.body}
                  />
                ))}
              </DataTable>
              <Paginator
                first={first}
                rows={rows}
                totalRecords={totalRecords}
                onPageChange={onPageChange}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
