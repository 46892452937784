import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface BookingState {
  bookings: string[]; // Example state
}

const initialState: BookingState = {
  bookings: [],
};

const bookingsSlice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {
    addBooking: (state, action: PayloadAction<string>) => {
      state.bookings.push(action.payload);
    },
    removeBooking: (state, action: PayloadAction<number>) => {
      state.bookings.splice(action.payload, 1);
    },
  },
});

export const { addBooking, removeBooking } = bookingsSlice.actions;
export default bookingsSlice.reducer;
