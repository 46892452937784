import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import SettingService from "../../services/settingsService"; // Assuming you have a service to fetch the data

interface ErrorType {
  paymentAcquire: string;
  apiKey: string;
  status: string;
}

interface OptionType {
  id: number;
  name: string;
  key: string;
}

interface PaymentAcquireProps { }

const PaymentAcquireConfiguration = forwardRef((props: PaymentAcquireProps, ref) => {
  const { t } = useTranslation();

  // State variables for dropdowns and input field
  const [paymentAcquireOptions, setPaymentAcquireOptions] = useState<OptionType[]>([]);
  const [paymentAcquire, setPaymentAcquire] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [status, setStatus] = useState("ACTIVE");

  useEffect(() => {
    fetchPaymentAcquireConfig();
  }, []);

  // Fetch configuration data
  const fetchPaymentAcquireConfig = async () => {
    try {
      const res = await SettingService.getPaymentAcquirerConfig();
      const paymentAcquires = res?.data?.paymentAcq.map((item: any) => ({
        id: item.payment_acq_id,
        name: item.payment_acq_name,
        key: item.acq_key,
      }));

      setPaymentAcquireOptions(paymentAcquires);

      // Set initial values
      if (paymentAcquires.length > 0) {
        setPaymentAcquire(paymentAcquires[0].name);
        setApiKey(paymentAcquires[0].key);
      }
    } catch (error) {
      console.error("Error fetching payment acquire config:", error);
    }
  };

  // Handlers to update state when a selection is made
  const handlePaymentAcquireChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedName = event.target.value;
    setPaymentAcquire(selectedName);

    // Update API key based on selected acquire
    const selectedOption = paymentAcquireOptions.find((option) => option.name === selectedName);
    if (selectedOption) {
      setApiKey(selectedOption.key);
    }
  };

  const handleApiKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setApiKey(event.target.value);
  };

  const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    console.log("Previous Status:", status);
    setStatus(event.target.value);
    console.log("New Status:", event.target.value);
  };


  const validateForm = () => {
    let formIsValid = true;
    const newErrors: ErrorType = { paymentAcquire: "", apiKey: "", status: "" };

    if (!paymentAcquire) {
      newErrors.paymentAcquire = "Payment acquire is required.";
      formIsValid = false;
    }
    if (!apiKey) {
      newErrors.apiKey = "API key is required.";
      formIsValid = false;
    }
    if (!status) {
      newErrors.status = "Status is required.";
      formIsValid = false;
    }

    return {
      paymentAcquire,
      apiKey,
      status,
      formIsValid,
    };
  };

  useImperativeHandle(ref, () => ({
    validateForm,
  }));

  return (
    <div>
      <label htmlFor="payment-acquire" className="block input-label-settings">
        {t("Settings.PaymentAcquire")}
      </label>
      <select
        id="payment-acquire"
        value={paymentAcquire}
        onChange={handlePaymentAcquireChange}
        className="w-full mb-4 p-2 pl-[0.3rem] border rounded-md"
      >
        {paymentAcquireOptions.map((option) => (
          <option key={option.id} value={option.name} className="text-[14px]">
            {option.name}
          </option>
        ))}
      </select>

      <label htmlFor="apikey" className="block input-label-settings">
        {t("ApiConfiguraion.APIKey")}
      </label>
      <input
        className="w-full mb-4 p-2 pl-[0.5rem] border rounded-md"
        type="text"
        id="apikey"
        value={apiKey}
        onChange={handleApiKeyChange}
        placeholder="Enter API key"
      />

      <label htmlFor="status" className="block input-label-settings">
        {t("Booking.Status")}
      </label>
      <select
        id="status"
        value={status}
        onChange={handleStatusChange}
        className="w-full mb-4 p-2 pl-[0.3rem] border rounded-md"
      >
        <option value="ACTIVE" className="text-[14px]">
          Active
        </option>
        <option value="INACTIVE" className="text-[14px]">
          Inactive
        </option>
      </select>
    </div>
  );
});

export default PaymentAcquireConfiguration;
