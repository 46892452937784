import { useTranslation } from 'react-i18next';

export default function Home() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-[16px]">
      <span className="custom-page-heading">{t('home.Dashboard page')}</span>
      <div className="p-[24px] bg-[#ffffff] rounded-[8px]">
        
      </div>
    </div>
  )
}
