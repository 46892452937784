import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import signup from './../../assets/signup.png';
import Group from './../../assets/Group.png';
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { useTranslation } from 'react-i18next';

export default function EmailConfirmation() {

    const navigate = useNavigate();
    const [isTimerActive, setIsTimerActive] = useState(false);
    const [timer, setTimer] = useState(10);

    useEffect(() => {
        if (isTimerActive && timer > 0) {
            const interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);

            return () => clearInterval(interval);
        } else if (timer === 0) {
            setIsTimerActive(false);
            navigate('/email-verified');
        }
    }, [isTimerActive, timer]);

    const handleResendClick = () => {
        setIsTimerActive(true);
        setTimer(10);
    };
    const { t } = useTranslation();

    return (
        <div className="flex justify-center min-h-screen bg-gradient-to-b from-[#50BEFF] to-[#019DF7]">
            <div className='screen-size flex flex-row p-[20px] w-full'>
                <div className="w-1/2 flex flex-col justify-center">
                    <img
                        className="w-full h-auto object-contain"
                        src={signup}
                        alt=""
                    />
                </div>

                <div className="w-full md:w-1/2 flex flex-col items-center justify-center gap-6 md:gap-10">
                    <form className="bg-[#FFFFFF] rounded-2xl px-[40px] py-[40px] flex flex-col gap-[0px] min-w-[500px]">
                        <div className="flex items-center gap-[3px] text-[#019DF7] cursor-pointer" onClick={() => navigate(-1)}><IoChevronBackCircleOutline />{t('emailverify.back')}</div>
                        <div className='flex flex-col text-center gap-[20px]  py-[120px]'>
                            <img
                                className="w-full h-[60px] object-contain"
                                src={Group}
                                alt=""
                            />
                            <h2 className="text-[28px] font-bold leading-[38.5px]">{t('emailverify.Emailconfirmation')} </h2>
                            <div className='text-[#808080] text-[14px] font-normal leading-[21px]'>
                                {t('emailverify.confirmationemail')} alex@gmail.com.<br />
                                {t('emailverify.plsclick')}
                            </div>
                            <div className="flex text-[#019DF7] text-sm justify-center items-center cursor-pointer" onClick={() => navigate(-1)}>
                                {t('emailverify.editemail')}
                            </div>
                            <div className="flex text-[black] text-sm justify-center items-center cursor-pointer">
                                {isTimerActive ? (
                                    <div className="flex flex-col items-center gap-1 text-center text-sm text-gray-600">
                                        <label className="text-[#E28A11] font-medium">
                                            {t('emailverify.rstsnt')}
                                        </label>
                                        <label>
                                            {t('emailverify.Youcan')}
                                            <span className="text-[#019DF7] font-semibold">
                                                {` 00:${timer.toString().padStart(2, '0')}`}
                                            </span>
                                        </label>
                                    </div>

                                ) : (
                                    <>
                                        {t('emailverify.notrecemail')}
                                        <span
                                            onClick={handleResendClick}
                                            className="text-[#019DF7] mx-1"
                                        >
                                            {t('emailverify.resend')}
                                        </span>
                                    </>
                                )}
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    )
}
