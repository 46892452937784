import React, { FC, ReactNode } from 'react';

interface FloatingLabelInputProps {
    id: string;
    type?: string;
    label: ReactNode;
    value: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    error?: string;
    disabled?: boolean;
    buttonText?: string;
    onButtonClick?: () => void;
    [key: string]: any;
}

const FloatingLabelInput: FC<FloatingLabelInputProps> = ({
    id,
    type = 'text',
    label,
    value,
    onChange = () => { },
    onKeyDown = () => { },
    onBlur = () => { },
    error = '',
    disabled = false,
    buttonText = '',
    onButtonClick,
    ...props
}) => {

    return (
        <div className="relative w-full">
            <input
                type={type}
                id={id}
                className={`block ${type != "password" ? "p-[12px]" : "p-[12px] pr-[32px]"}  text-black w-full rounded border focus:outline-none focus:ring-0 peer ${error ? 'border-red-500' : 'border-gray-300'
                    } ${disabled || buttonText ? 'bg-white cursor-not-allowed' : ''} peer-placeholder-shown:text-[#999999]`}
                placeholder=" "
                value={value}
                onChange={onChange}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                disabled={disabled}
                {...props}
            />
            <label
                htmlFor={id}
                className={`absolute text-sm duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 
                    left-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2
                    peer-placeholder-shown:text-[#999999] peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:text-black`}
            >
                {label}
            </label>
            {buttonText && (
                <button
                    onClick={onButtonClick}
                    type="button"
                    className={`absolute right-2 top-1/2 transform -translate-y-1/2 text-[#019DF7] font-semibold ${disabled ? " cursor-not-allowed" : "cursor-pointer"}`}
                >
                    {buttonText}
                </button>
            )}
        </div>
    );
};

export default FloatingLabelInput;