import React, { useEffect, useState } from "react";
import failed from "../../assets/failed.png";
import DropDown from "../common/DropDown";
import { ButtonBase, SelectChangeEvent } from "@mui/material";
import CustomTextField from "../common/customTextField";
import Button from "../common/Button";
import toast, { Toaster } from "react-hot-toast";
import operatorService from "../../services/operatorServices";

const RejectConfirmationModal = ({ operatorDetails, setModalVisible }: any) => {
  const [reason, setReason] = useState("");
  const [remarks, setRemarks] = useState("");
  const [loading, setLoading] = useState(false);
  const [operator, setOperator] = useState(operatorDetails);
  const [error, setError] = useState(false); // State for managing error visibility

  const handleBackToPayment = () => {
    console.log("Back to Payment");
  };

  const handleClose = () => {
    setModalVisible(false);
  };

  const handleSelectValueChange = (event: SelectChangeEvent) => {
    setReason(event.target.value as string);
    setError(false); // Clear error when the user selects a reason
  };

  const handleTextFieldValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRemarks(event.target.value);
    setError(false); // Clear error when the user types remarks
  };

  const handleCancelPaymentClick = () => {
    console.log(reason, remarks);
  };

  const options = [
    { value: "Delayed", label: "Delayed" },
    { value: "Others", label: "Others" },
  ];

  const handleReject = async () => {
    if (!reason.trim()) {
      setError(true); 
    }

    setLoading(true);
    try {
      const res = await operatorService.rejectOnboardingOperator(
        operator.operator_id,
        reason
      );
      toast.error("Operator Rejected !");
      console.log(res.data);
    } catch (error) {
      console.error("error updating operator:", error);
    } finally {
      setLoading(false);
      setModalVisible(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="flex flex-col items-center justify-center bg-white rounded-md">
        <div className="flex flex-col justify-center items-start gap-3 px-6 pt-6 mb-3">
          <div className="flex flex-col justify-center items-center gap-y-[12px] w-[342px]">
            <img
              src={failed}
              alt="failed-img"
              className="w-auto h-auto object-contain"
            />
            <p className="leading-7 font-bold text-[24px] text-center text-[#000000]">
              Reject
            </p>
          </div>
          <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-col items-start justify-center gap-1">
              <p className="font-normal text-sm text-[#00000099] px-2">
                Reason for rejection
              </p>
              <div>
                <DropDown
                  required
                  options={options}
                  value={reason}
                  onChange={handleSelectValueChange}
                />
              </div>
            </div>
            <div className="flex flex-col items-start justify-center gap-1">
              <p className="font-normal text-sm text-[#00000099] px-2">
                Remarks (optional)
              </p>
              <CustomTextField
                value={remarks}
                onChange={handleTextFieldValueChange}
                required
                sx={{ width: 342 }}
              />
            </div>
            {error && (
              <p className="text-[#da3636] text-sm mt-2">
                Both Reason and Remarks are required.
              </p>
            )}
            <div className="flex flex-col items-start justify-center gap-[16px] mt-[16px]">
              <Button
                text="Cancel Payment"
                color={"#D32F2F"}
                onClick={handleCancelPaymentClick}
              />
              <Button
                text="Back to Payment"
                color="none"
                textColor="#000000DE"
                onClick={handleBackToPayment}
              />
            </div>
          </div>
        </div>
        <div className="bg-[#f5f5f5] w-full px-4 py-1 rounded-b-md mt-3">
          <div className="w-full flex gap-3 justify-end items-center">
            <button
              className="hover:bg-gray-100 px-4 py-1 rounded-md my-2 border border-1px-solid border-gray-500"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              className="bg-red-600 px-4 py-1 rounded-md my-2 text-white font-medium"
              onClick={handleReject}
            >
              Reject
            </button>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default RejectConfirmationModal;
