import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/slice';
import bookingsReducer from '../features/bookings/slice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    bookings: bookingsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
