import api from "./api";

const getAllSettlementsHistory = async (page: number,sort_order:any ,startdate:any, enddate:any) => {

    let queryParams = `?sortSettlement_time_By=${sort_order}&page=${page}`;

    if (startdate) {
        queryParams += `&start_date=${startdate}`;
    }
    if (enddate) {
        queryParams += `&end_date=${enddate}`;
    }
    const url = `settlement/history${queryParams}`;

    return api
        .get(url)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error)
            return error;
        });
}
const fetchSettlementsHistoryDetails = async (operatorId: any,SettlementID:any, page: any,sort_order:any,transactionId:any) => {
    const response = await api.get(`settlement/operator/${operatorId}/sp-settlement-breakdown/${SettlementID}?page=${page}&order_by=${sort_order}&transactionId=${transactionId}`)
    return response.data
}



const SettlementsServices = {
    getAllSettlementsHistory,
    fetchSettlementsHistoryDetails,
    
}

export default SettlementsServices