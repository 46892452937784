import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import signup from './../../assets/signup.png';
import EyeIcon from '../../components/common/EyeIcon';
import { useTranslation } from 'react-i18next';

interface ErrorType {
  name?: string;
  mobileNumber?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
  terms?: string;
}

const Signup = () => {

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [name, setName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [errors, setErrors] = useState<ErrorType>({});

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const validateForm = () => {
    let formIsValid = true;
    const newErrors = { name: '', mobileNumber: '', email: '', password: '', confirmPassword: '', terms: '' };
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!name) {
      newErrors.name = t('signup.namereq');
      formIsValid = false;
    }

    if (!mobileNumber) {
      newErrors.mobileNumber = t('signup.mobilereq');
      formIsValid = false;
    }

    if (!email) {
      newErrors.email = t('signin.emailaddress');
      formIsValid = false;
    } else if (!email.match(emailPattern)) {
      newErrors.email = t('signin.invalidemail');
      formIsValid = false;
    }

    if (!password) {
      newErrors.password = t('signin.passwordrequired');
      formIsValid = false;
    }

    if (!confirmPassword) {
      newErrors.confirmPassword = t('signup.cpr');
      formIsValid = false;
    } else if (password !== confirmPassword) {
      newErrors.confirmPassword = t('signup.pwdnotmatch');
      formIsValid = false;
    }

    if (!termsAccepted) { // Validate checkbox
      newErrors.terms = t('signup.agreealrt');
      formIsValid = false;
    }
    setErrors(newErrors);
    return formIsValid;
  };

  const handleSignUp = (e: any) => {
    e.preventDefault();
    if (validateForm()) {
      navigate("/email-confirmation")
      console.log('Form submitted successfully');
    }
  };

  const { t } = useTranslation();
  return (
    <div className="flex justify-center min-h-screen bg-gradient-to-b from-[#50BEFF] to-[#019DF7]">
      <div className='screen-size flex flex-row p-[20px] w-full'>
        <div className="w-1/2 flex flex-col justify-center">
          <img
            className="w-full h-auto object-contain"
            src={signup}
            alt="signup"
          />
        </div>
        <div className="w-full md:w-1/2 flex flex-col items-center justify-center gap-6 md:gap-10">
          <form onSubmit={handleSignUp} className="bg-[#FFFFFF] rounded-2xl px-[64px] py-[70px] flex flex-col gap-[40px] min-w-[500px]">
            <div className='flex flex-col text-center gap-[8px]'>
              <h2 className="text-[32px] font-bold leading-[38.5px]">{t('signup.siguplabel')}</h2>
            </div>
            <div className='flex flex-col gap-[10px]'>
              <input
                type="text"
                placeholder={t('signup.signupname')}
                className={`border p-[12px] max-h-[56px] rounded focus:outline-[#019DF7] ${errors.name ? 'border-red-500' : ''}`}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}

              <input
                type="number"
                placeholder={t('signup.mobilenumber')}
                className={`border p-[12px] rounded focus:outline-[#019DF7] ${errors.mobileNumber ? 'border-red-500' : ''}`}
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
              />
              {errors.mobileNumber && <p className="text-red-500 text-sm">{errors.mobileNumber}</p>}

              <input
                type="email"
                placeholder={t('signin.emailaddress')}
                className={`border p-[12px] rounded focus:outline-[#019DF7] ${errors.email ? 'border-red-500' : ''}`}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}

              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder={t('signin.password')}
                  className={`border p-[12px] w-full rounded pl-2 pr-10 focus:outline-[#019DF7] ${errors.password ? 'border-red-500' : ''}`}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <EyeIcon onClick={togglePasswordVisibility} isPasswordVisible={showPassword}   />
              </div>
              {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}

              <div className="relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder={t('signup.confirmpwd')}
                  className={`border p-[12px] w-full rounded pl-2 pr-10 focus:outline-[#019DF7] ${errors.confirmPassword ? 'border-red-500' : ''}`}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <EyeIcon onClick={toggleNewPasswordVisibility}    isPasswordVisible={showConfirmPassword} />
              </div>
              {errors.confirmPassword && <p className="text-red-500 text-sm">{errors.confirmPassword}</p>}
            </div>

            <div className="flex flex-col gap-2 md:gap-2">
              <div className="flex items-center justify-center">
                <input
                  type="checkbox"
                  id="termsCheckbox"
                  checked={termsAccepted}
                  onChange={() => setTermsAccepted(!termsAccepted)} // Toggle checkbox state
                  className="mr-2"
                />
                <label htmlFor="termsCheckbox" className="flex-wrap items-center text-[12px]">
                  {t('signup.signupagree')}
                  <Link to="/terms" className="text-[#019DF7] mx-1">  {t('signup.t&c')}</Link>
                  and
                  <Link to="/privacy" className="text-[#019DF7] mx-1">Privacy Policy</Link>
                </label>

              </div>
              <div className="flex items-center justify-center">{errors.terms && <p className="text-red-500 text-sm">{errors.terms}</p>}</div>
              <button type="submit" className="bg-[#019DF7] text-white py-3 px-8 rounded w-full">
                {t('signup.siguplabel')}
              </button>
            </div>

          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
