import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RiArrowLeftSLine } from "react-icons/ri";
import { useNavigate, useParams, Outlet, Link, useLocation } from 'react-router-dom';
import { decryptParams } from "../../../utils/cryptoUtils";
import authService from "../../../services/authService";
import { InputSwitch, InputSwitchChangeEvent } from "primereact/inputswitch";
import moment from "moment";
import { formatCurrency } from "../../../utils/formatCurrency";

export default function SpDetails() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const location = useLocation();
  // const operatorID = location.state?.operatorID;
  const { encryptedParam } = useParams()

  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");

  const [spname, setSpName] = useState<any>("");
  const [spId, setspID] = useState<any>("");
  const [oprId, setoprID] = useState<any>("");

  const [checked, setChecked] = useState<boolean>(true);
  const [spDetails, setSPDetails] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const encodedEncryptedParam = encodeURIComponent(encryptedParam || '');
  const isBasicDetailsActive = location.pathname === `/service-providers/${encodedEncryptedParam}`;

  const handleToggle = (e: InputSwitchChangeEvent) => {
    const newStatus = e.value ? "ACTIVE" : "INACTIVE";
    setChecked(e.value);
    if (spId) {
      sPStatusUpdate(newStatus, spId);
    }
    console.log(newStatus, userId);
  };
  

  const handleBackClick = () => {
    navigate("/service-providers"); // Go back to the previous page
  };

  interface DecryptedParam {
    index?: any;
    operatorId?: any;
  }

  useEffect(() => {
    console.log(oprId,"operator ID")
    if (encryptedParam) {
      const decodedParam = decodeURIComponent(encryptedParam).replace(/\s/g, '+');
      const decryptedParam = decryptParams(decodedParam) as DecryptedParam;

      if (decryptedParam && 'spid' in decryptedParam) {
        const spid = decryptedParam.spid as number;
        spBasicDetails(spid);
        setspID(spid)
        console.log(spid, "SP ID");
      }
    }
  }, [encryptedParam]);

  const spBasicDetails = (spid: number) => {
    setLoading(true);
    authService.serviceProviderDetails(token, spid)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data,response.data.operator_id, "Sp BasicDetails,op_ID")
          const details = response.data;
          setSPDetails(details);
          setSpName(response.data.driver_name);
          setoprID(response.data.operator_id);
          setChecked(response.data.status === "Active" || response.data.status ===  "active" || response.data.status ===  "ACTIVE")
          console.log(typeof(response.data.status));

        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const sPStatusUpdate = (newStatus: string , spid: number) => {
    authService.sPStatusFlagUpdate(token,spid, oprId, newStatus)
      .then((response) => {
        console.log(response, "SP Update Flag");
        if (response.status === 200) {
          console.log("checked")
        }
      })
  };
  return (
    <div className="flex flex-col gap-[16px]">
      <div className="flex flex-row items-center gap-2">
        <button
          className="border border-black text-black px-6 py-2 rounded flex items-center"
          onClick={handleBackClick}
        >
          <span className="flex items-center">
            <RiArrowLeftSLine className="mr-1" />
            {t('emailverify.back')}
          </span>
        </button>
        <span className="custom-page-heading px-2 py-2">
          {spId} - {spname || "-"}
        </span>
      </div>

      <div className="flex flex-row gap-[16px] text-[14px] text-[#667085] border-b border-[#BDBDBD] font-semibold leading-[17px]">
        <div
          className={`cursor-pointer hover:text-[#03A9F4] pt-[1px] pb-[12px] ${isBasicDetailsActive ? 'text-[#03A9F4] border-b-2 border-[#03A9F4]' : ''}`}
          onClick={() => {
            if (spDetails?.sp_id) {
              spBasicDetails(spDetails.sp_id);
              navigate(`/service-providers/${encodedEncryptedParam}`);
            } else {
              console.error("sp_id is undefined. Cannot fetch details.");
            }
          }}
        >
          {t('ServiceProvider.BasicDetails')}
        </div>
        <Link
          to="booking-section"
          className={`cursor-pointer hover:text-[#03A9F4] px-[4px] pt-[1px] pb-[12px] ${location.pathname.includes("booking-section") ? 'text-[#03A9F4] border-b-2 border-[#03A9F4]' : ''}`}
        >
          {t('sidebar.bookings')}
        </Link>
        <Link
          to="wallet-section"
          className={`cursor-pointer hover:text-[#03A9F4] px-[4px] pt-[1px] pb-[12px] ${location.pathname.includes("wallet") ? 'text-[#03A9F4] border-b-2 border-[#03A9F4]' : ''}`}
        >
          {t('sidebar.wallet')}
        </Link>
        <Link
          to="settlements"
          className={`cursor-pointer hover:text-[#03A9F4] px-[4px] pt-[1px] pb-[12px] ${location.pathname.includes("settlements") ? 'text-[#03A9F4] border-b-2 border-[#03A9F4]' : ''}`}
        >
          {t('sidebar.settlements')}
        </Link>
      </div>

      {isBasicDetailsActive && spDetails && (
        <div className="p-[24px] bg-[#ffffff] rounded-[8px]">
          <section>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-4 gap-x-8">
              <DetailItem
                label={t('BookingDetails.ServiceProviderID')}
                value={spDetails.sp_id || "-"} />
              <DetailItem
                label={t('BookingDetails.ServiceProviderName')}
                value={spDetails.driver_name || "-"} />
              <DetailItem label={t('Operators.OperatorName')}
                value={spDetails.name || "-"}
              />
              <DetailItem
                label={t('ServiceProvider.PhoneNumber')}
                value={spDetails.telephone || "-"} />
              <DetailItem
                label={t('ServiceProvider.EmailID')}
                value={spDetails.email || "-"}
              />
              <DetailItem
                label={t('ServiceProvider.BussinessAddress')}
                value={spDetails.address || "-"} />
              <DetailItem
                label={t('ServiceProvider.RegistrationDate')}
                value={spDetails.onboard_date ? moment(spDetails.onboard_date).format('DD MMM YYYY - hh:mm A') : "-"}
              />
              <DetailItem
                label={t('ServiceProvider.LicenceNumber')}
                value={spDetails.license_number || "-"} />
              <DetailItem
                label={t('ServiceProvider.TotalNumberServices')}
                value={spDetails.total_bookings || "-"} />
              <DetailItem
                label={t('ServiceProvider.TotalAmountEarned')}
                value={formatCurrency(spDetails.total_revenue || "-")} />
              <DetailItem
                label={t('ServiceProvider.Walletbalance')}
                value={formatCurrency(spDetails.wallet_balance || "-")} />
              <div className="flex flex-col gap-[4px]">
                <DetailItem label={t('ServiceProvider.StatusServiceProvider')} value=""></DetailItem>
                <div className="card flex flex-row gap-[10px]">
                  <InputSwitch
                    checked={checked}
                    onChange={handleToggle}
                  />
                  <span className={`block ${checked ? 'text-green-500' : 'text-red-500'}`}>
                    {checked ? t('Filter.Active') : t('Filter.Deactive')}
                  </span>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}

      <Outlet />
    </div>
  );
}

function DetailItem({ label, value }: { label: string; value: string }) {
  return (
    <div className="flex flex-col gap-[4px]">
      <span className="text-[14px] font-semibold leading-[21px] text-[#3E4044]">{label}</span>
      <span className="text-[14px] font-normal leading-[21px] text-[#6C6C6C]">{value}</span>
    </div>
  );
}
