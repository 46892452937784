import React from 'react'

const ChargeOrReport = () => {
  return (
    <div>
      This is ChargeOrReport Page
    </div>
  )
}

export default ChargeOrReport
