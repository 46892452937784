import CryptoJs from 'crypto-js'

const SECRET_KEY = 'f2e680561da5711cb757831766f9f387';

export const encryptParams = (data: object): string => {
    const jsonString = JSON.stringify(data);
    const encrypted = CryptoJs.AES.encrypt(jsonString, SECRET_KEY).toString();
    return encrypted
}

export const decryptParams = (encryptedData: string): object | null => {
    try {
        const bytes = CryptoJs.AES.decrypt(encryptedData, SECRET_KEY);
        const decryptedData = bytes.toString(CryptoJs.enc.Utf8);
        return JSON.parse(decryptedData);
    } catch (error) {
        console.error('Error decrypted data:', error);
        return null
    }
}