import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next"
import { RiArrowLeftSLine } from "react-icons/ri";
import { useNavigate, useParams } from 'react-router-dom';
import { decryptParams } from "../../../utils/cryptoUtils";
import bookingService from "../../../services/bookingServices";
import Helpers from "../../../utils/helpers";
import { formatCurrency } from "../../../utils/formatCurrency";

export default function BookingView() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const paymentStatus = "Completed"; 
  const { encryptedParam } = useParams();
  const [bookingDetails, setBookingDetails] = useState<any>({});

  const getBookingDetails = useCallback(async (id: string) => {
    try {
      const res = await bookingService.getBookingDetails(id);
      console.log("Response:", res);

      const resData = {
        ...res.data,
        booking_date_time: Helpers.formatDateString(res.data.booking_date_time),
      };
      setBookingDetails(resData);
    } catch (error) {
      console.error("Error fetching booking details:", error);
    }
  }, []);


  useEffect(() => {
    console.log("Encrypted data:", encryptedParam);

    if (encryptedParam) {
      try {
        const decryptedParam: any = decryptParams(decodeURIComponent(encryptedParam));
        if (decryptedParam && decryptedParam.bookingId) {
          console.log("Decrypted data:", decryptedParam);
          getBookingDetails(decryptedParam.bookingId);
        } else {
          console.error("Failed to decrypt data: Decrypted param is null or missing bookingId");
        }
      } catch (error) {
        console.error("Error during decryption:", error);
      }
    }
  }, [encryptedParam, getBookingDetails]);

  console.log('booking details:', bookingDetails)

  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-2 mb-6">
        <button className="border border-black text-black px-5 py-2 rounded flex items-center" onClick={() => navigate('/bookings')}>
          <span className="flex items-center text-[14px]">
            <RiArrowLeftSLine className="mr-1" size={22} />
            {t('OnBoardPage.Back')}
          </span>
        </button>
        <span className="custom-page-heading px-2 py-2 text-[#3E4044]">{t('BookingDetails.BookingDetails')}</span>
      </div>

      <div className="p-[24px] bg-[#ffffff] rounded-[8px] ">
        <section>
          <h2 className="text-lg font-[18px] mb-8 text-[#3E4044]">{t('BookingDetails.BookingDetails')}</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-4 gap-x-8 mb-8">
            <div>
              <span className="text-[#3E4044] text-[14px] font-semibold">{t('BookingDetails.CustomerName')}</span>
              <span className="block text-[#6C6C6C] text-[14px] font-normal">{bookingDetails.customer_name}</span>
            </div>
            <div>
              <span className="text-[#3E4044] text-[14px] font-semibold"> {t('BookingDetails.BookingDateTime')}</span>
              <span className="block text-[#6C6C6C] text-[14px] font-normal">{bookingDetails.booking_date_time}</span>
            </div>
            <div>
              <span className="text-[#3E4044] text-[14px] font-semibold">{t('BookingDetails.BookingID')}</span>
              <span className="block text-[#6C6C6C] text-[14px] font-normal">{bookingDetails.booking_id}</span>
            </div>
            <div>
              <span className="text-[#3E4044] text-[14px] font-semibold">{t('BookingDetails.FromLocation')}</span>
              <span className="block text-[#6C6C6C] text-[14px] font-normal">{bookingDetails.pickup_location}</span>
            </div>
            <div>
              <span className="text-[#3E4044] text-[14px] font-semibold">{t('BookingDetails.ToLocation')}</span>
              <span className="block text-[#6C6C6C] text-[14px] font-normal">{bookingDetails.drop_location}</span>
            </div>
            <div>
              <span className="text-[#3E4044] text-[14px] font-semibold">{t('BookingDetails.BookingSts')}</span>
              <span
                className={`block text-[14px] font-normal ${bookingDetails.booking_status?.toUpperCase() === 'COMPLETED'
                  ? 'text-green-500'
                  : bookingDetails.booking_status?.toUpperCase() === 'FAILED' || bookingDetails.booking_status?.toUpperCase() === 'PENDING'
                    ? 'text-red-500'
                    : 
                      bookingDetails.booking_status?.toUpperCase() === 'ONGOING'
                      ? 'text-orange-500'
                      : 'text-gray-500'
                  }`}
              >
                {bookingDetails.booking_status || '-'}
              </span>
            </div>

          </div>
        </section>
        <hr />

        {/* Operator  Details Section */}
        <section>
          <h2 className="text-lg font-[18px] my-8 text-[#3E4044]">{t('BookingDetails.OperatorDetails')}</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-4 gap-x-8 mb-8">
            <div>
              <span className="text-[#3E4044] text-[14px] font-semibold">{t('BookingDetails.OperatorId')}</span>
              <span className="block text-[#6C6C6C] text-[14px] font-normal">{bookingDetails.operator_id || "-"}</span>
            </div>
            <div>
              <span className="text-[#3E4044] text-[14px] font-semibold">{t('BookingDetails.OperatorName')}</span>
              <span className="block text-[#6C6C6C] text-[14px] font-normal">{bookingDetails.operator_name || "-"}</span>
            </div>
            <div>
              <span className="text-[#3E4044] text-[14px] font-semibold">{t('BookingDetails.OperatorNumber')}</span>
              <span className="block text-[#6C6C6C] text-[14px] font-normal">{bookingDetails.operator_mobile_number || "-"}</span>
            </div>
            <div>
              <span className="text-[#3E4044] text-[14px] font-semibold">{t('BookingDetails.OperatorEmailId')}</span>
              <span className="block text-[#6C6C6C] text-[14px] font-normal">{bookingDetails.operator_email || "-"}</span>
            </div>
          </div>

        </section>
        <hr />

        {/* Service Provider Details Section */}
        <section>
          <h2 className="text-lg font-[18px] my-8 text-[#3E4044]">{t('BookingDetails.ServiceProviderDetails')}</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-4 gap-x-8 mb-8">
            <div>
              <span className="text-[#3E4044] text-[14px] font-semibold">{t('BookingDetails.ServiceProviderID')}</span>
              <span className="block text-[#6C6C6C] text-[14px] font-normal">{bookingDetails.sp_id || "-"}</span>
            </div>
            <div>
              <span className="text-[#3E4044] text-[14px] font-semibold">{t('BookingDetails.ServiceProviderName')}</span>
              <span className="block text-[#6C6C6C] text-[14px] font-normal">{bookingDetails.service_provider_name || "-"}</span>
            </div>
            <div>
              <span className="text-[#3E4044] text-[14px] font-semibold">{t('BookingDetails.ServiceProviderNumber')}</span>
              <span className="block text-[#6C6C6C] text-[14px] font-normal">{bookingDetails.sp_phone_number || "-"}</span>
            </div>
            <div>
              <span className="text-[#3E4044] text-[14px] font-semibold">{t('BookingDetails.ServiceProviderEmailId')}</span>
              <span className="block text-[#6C6C6C] text-[14px] font-normal">{bookingDetails.sp_phone_number || "-"}</span>
            </div>
            <div>
              <span className="text-[#3E4044] text-[14px] font-semibold">{t('BookingDetails.ServiceProviderPayment')}</span>
              <span className="block text-[#6C6C6C] text-[14px] font-normal">{bookingDetails.sp_name || "-"}</span>
            </div>
          </div>

        </section>
        <hr />

        {/* Payment Details Section */}
        <section>
          <h2 className="text-lg font-[18px] my-8 text-[#3E4044]">{t('BookingDetails.PaymentDetails')}</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-4 gap-x-8 mb-8">
            <div>
              <span className="text-[#3E4044] text-[14px] font-semibold">{t('Booking.Amount')}</span>
              <span className="block text-[#6C6C6C] text-[14px] font-normal">{formatCurrency(bookingDetails.amount || "-")}</span>
            </div>
            <div>
              <span className="text-[#3E4044] text-[14px] font-semibold">{t('Wallet.TranID')}</span>
              <span className="block text-[#6C6C6C] text-[14px] font-normal">{bookingDetails.transaction_ref_number || "-"}</span>
            </div>
            <div>
              <span className="text-[#3E4044] text-[14px] font-semibold">{t('Wallet.PaymentType')}</span>
              <span className="block text-[#6C6C6C] text-[14px] font-normal">{bookingDetails.payment_transaction_type || "-"}</span>
            </div>
            <div>
              <span className="text-[#3E4044] text-[14px] font-semibold">{t('Wallet.paymentRefId')}</span>
              <span className="block text-[#6C6C6C] text-[14px] font-normal">{bookingDetails.payment_gateway_ref_number || "-"}</span>
            </div>
            <div>
              <span className="text-[#3E4044] text-[14px] font-semibold">{t('BookingDetails.PaymentStatus')}</span>
              <span
                className={`block text-[14px] font-normal mt-1 truncate max-w-full ${bookingDetails.payment_status?.toUpperCase() === 'COMPLETED'
                    ? 'text-[#15B565]'
                    : bookingDetails.payment_status?.toUpperCase() === 'ONGOING'
                      ? 'text-[#FF8C1A]'
                      : bookingDetails.payment_status?.toUpperCase() === 'PENDING'
                        ? 'text-[#FF0000]'
                        : 'text-[#6C6C6C]'
                  }`}
              >
                {bookingDetails.payment_status || '-'}
              </span>

            </div>
          </div>
        </section>
      </div>
    </div>
  )
}