import React, { useState, useEffect, useRef } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
import editIcon from '../assets/edit_1.svg';
import Modal from '../components/common/Modal';
import SuccesIcon from '../assets/success.svg';
import AskIcon from '../assets/askMark.svg';
import { useTranslation } from 'react-i18next';
import ChangePasswordModalContent from '../components/Modal/ChangePasswordModalContent';
import { IoEyeOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import GeneralConfiguration from '../components/Modal/GeneralConfiguration';
import PaymentAcquireconfiguration from '../components/Modal/PaymentAcquireConfiguration';
import PaymentDisbursalConfiguration from '../components/Modal/PaymentDisbursalConfiguration';
import { Password } from 'primereact/password';
import SettingService from '../services/settingsService';
import EyeIcon from '../components/common/EyeIcon';

const Settings: React.FC = () => {

  const { t, ready } = useTranslation();
  const navigate = useNavigate()
  const [isLoaded, setIsLoaded] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [selectedSection, setSelectedSection] = useState<string | null>(null);
  const [paymentAcquirerDetails, setPaymentAcquirerDetails] = useState<any>([])
  const [paymentDisbursalDetails, setPaymentDisbursalDetails] = useState<any>([])

  const [generalDetails, setGeneralDetails] = useState<any>([])

  const [userDetails, setUserDetails]: any = useState([])

  const [activeModal, setActiveModal] = useState<'confirm' | 'section' | 'success' | 'done' | null>(null);
  const [isPasswordUpdate, setIsPasswordUpdate] = useState<boolean>(false);
  const childRef = useRef<{ validateForm: () => any } | null>(null);

  const [isVisible, setIsVisible] = useState(false);
  const [isVisible1, setIsVisible1] = useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);
  const toggleVisibility1 = () => setIsVisible1(!isVisible1);

  const [apiError, setApiError] = useState('');

  const getMaskedValue = (key: string) => "*".repeat(key?.length);

  const [id]: any = localStorage.getItem("userId");

  useEffect(() => {
    if (ready) {
      setIsLoaded(true);
    }
    getPaymentAcquirerDetails()
    getGeneralConfig()
    getDetails()
  }, [ready]);

  const getDetails = async () => {
    try {
      const response = await SettingService.getSODetails(id)
      setUserDetails(response?.data?.personal_details?.[0])
    } catch (error) {
      console.error('error fetching payment acquirer details:', error)
    }
  }

  const getPaymentAcquirerDetails = async () => {
    try {
      const res = await SettingService.getPaymentAcquirerConfig()
      console.log(res)
      setPaymentAcquirerDetails(res?.data?.paymentAcq)
      setPaymentDisbursalDetails(res?.data?.paymentDisb)
    } catch (error) {
      console.error('error fetching payment acquirer details:', error)
    }
  }

  const getGeneralConfig = async () => {
    try {
      const res = await SettingService.getGeneralConfig()
      console.log(res, "lan")
      setGeneralDetails(res?.data)
    } catch (error) {
      console.error('error fetching payment acquirer details:', error)
    }
  }

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();

    if (childRef.current && childRef.current.validateForm) {
      const user_id = localStorage.getItem('userId')?.toString();
      const res = childRef.current.validateForm();
      console.log("formIsValid: ", res);

      if (res.formIsValid) {
        if (selectedSection === "password") {
          const email = localStorage.getItem("email");
          if (email) {
            const payload = {
              email: email,
              old_password: res?.currentPassword,
              new_password: res?.newPassword,
              confirm_password: res?.confirmPassword
            };

            try {
              console.log("payload", payload);
              const res = await SettingService.updatePassword(payload);
              console.log("password update res:", res);
              if (res.status === 200) {
                handleUpdateSuccess(true)
              }
              else {
                setApiError(res?.response?.data?.message)
              }
            } catch (error) {
              console.error("error updating password:", error);
            }
          } else {
            console.error("Email not found in localStorage");
          }
        }

        if (selectedSection === "general") {
          const payload = {
            countryName: res.country,
            languageName: res.language,
            currencyName: res.currency
          }

          try {
            console.log('general config payload:', payload)
            const res = await SettingService.updateGeneralConfig(payload)
            console.log('updated general config res:', res)
            if (res.status === 200) {
              handleUpdateSuccess(true)
            }
            else {
              setApiError(res?.response?.data?.message)
            }
          } catch (error) {
            console.error('error updating general config:', error)
          }
        }

        if (selectedSection === "acquire") {
          const payload = {
            id: user_id || null,
            name: res.paymentAcquire,
            key: res.apiKey,
            status: res.status
          }

          try {
            console.log('general config payload:', payload)
            const res = await SettingService.upsertPaymentAcquirerConfig(payload)
            console.log('updated payment acquirer config res:', res)
            if (res.status === 200) {
              handleUpdateSuccess(true)
            }
            else {
              setApiError(res?.response?.data?.message)
            }
          } catch (error) {
            console.error('error payment acquirer config:', error)
          }
        }

        if (selectedSection === "disbursal") {
          console.log(res, "disbursal")
          const payload = {
            id: user_id || null,
            country_id: res.country_id,
            name: res.name,
            key: res.key,
            status: res.status
          }

          try {
            console.log('disbursal config payload:', payload)
            const res = await SettingService.upsertPaymentDisbursalConfig(payload)
            console.log('updated payment disbursal config res:', res)
            if (res.status === 200) {
              handleUpdateSuccess(true)
            }
            else {
              setApiError(res?.response?.data?.message)
            }
          } catch (error) {
            console.error('error payment disbursal config:', error)
          }
        }
      } else {
        console.log("Form is invalid! Handle errors...");
      }
    }
  };


  const handlePaymentdisbursalClick = () => handleSure('disbursal', t('Settings.PaymentDisbursal'));
  const handleChangePasswordClick = () => handleAllEdit('password', t('Settings.ChangePassword'));
  const handlePaymentacquireClick = () => handleSure('acquire', t('Settings.PaymentAcquire'));
  const handleGeneralConfigurationClick = () => handleSure('general', t('Settings.Generalconfiguration'));

  const handleConfirmProceed = () => {
    setActiveModal('section');
  };

  const handleUpdateSuccess = (isPassword: boolean) => {
    if (selectedSection === 'password') {
      setIsPasswordUpdate(isPassword);
    }
    setActiveModal('success');
  };

  const handleGoToLogin = () => {
    navigate('/signin')
  };

  const handleCloseAllModals = () => {
    setModalVisible(false)
    setActiveModal(null);
    setSelectedSection(null);
  };

  const handleAllEdit = (section: string, title: string) => {
    setSelectedSection(section);
    setActiveModal('section');
    setModalTitle(title);
    setModalVisible(true);
  };

  const handleSure = (section: string, title: string) => {
    setSelectedSection(section);
    setActiveModal('confirm');
    setModalTitle(title);
    setModalVisible(true);
  };

  const getModalContent = () => {


    if (activeModal === 'confirm') {
      return (
        <div className="flex flex-col items-center justify-center text-center">
          <img className="w-[60px] h-[60px]" src={AskIcon} alt="" />
          <div className="settings-modal-text">{t('Settings.areyousureyouwanttoedit?')}</div>
        </div>
      );
    }

    if (activeModal === 'section') {
      switch (selectedSection) {
        case 'disbursal':
          return (
            <>
              <PaymentDisbursalConfiguration ref={childRef} />
              {apiError && (
                <p className="text-red-500 text-[12px] text-center mt-2">
                  {apiError}
                </p>
              )}
            </>
          )
        case 'password':
          return (
            <>
              <ChangePasswordModalContent ref={childRef} />
              {apiError && (
                <p className="text-red-500 text-[12px] text-center mt-2">
                  {apiError}
                </p>
              )}
            </>
          );
        case 'acquire':
          return (
            <>
              <PaymentAcquireconfiguration ref={childRef} />
              {apiError && (
                <p className="text-red-500 text-[12px] text-center mt-2">
                  {apiError}
                </p>
              )}
            </>
          )
        case 'general':
          return (
            <>
              <GeneralConfiguration ref={childRef} />
              {apiError && (
                <p className="text-red-500 text-[12px] text-center mt-2">
                  {apiError}
                </p>
              )}
            </>
          )
        default:
          return null;
      }
    }
    if (activeModal === 'success') {
      return selectedSection === 'password' ? (
        <div className='flex flex-col items-center justify-center text-center'>
          <img className='w-[60px] h-[60px]' src={SuccesIcon} alt="Success Icon" />
          <span className="font-bold text-xl text-black">{t('Settings.successful')}</span><br />
          <div>
            <span className='text-[14px]'> {t('Settings.passwordchange')}</span><br></br>
            <span className='text-[14px]'> {t('Settings.loginpage')}</span>
          </div>
        </div>
      ) : (
        <div className='flex flex-col items-center justify-center text-center'>
          <img className='w-[60px] h-[60px]' src={SuccesIcon} alt="Success Icon" />
          <h2 className='settings-modal-text'>
            {t('Settings.successful')}
          </h2>
          <div>
            {t('Settings.Updatedsuccessfully')}
          </div>
        </div>
      )
    }
  };

  const getFooterContent = () => {
    if (activeModal === 'confirm') {
      return (
        <div className='settings-modal-button'>
          <button className='settings-modal-cancel-button' onClick={() => setModalVisible(false)}>{t('Settings.cancel')}</button>
          <button className='settings-modal-success-button' onClick={handleConfirmProceed}>{t('Settings.proceed')}</button>
        </div>
      );
    }
    if (activeModal === 'section') {
      console.log(selectedSection)
      return selectedSection === 'password' || 'general' || 'acquire' || 'disbursal' ? (
        <div className='settings-modal-button'>
          <button className='settings-modal-cancel-button' onClick={() => setModalVisible(false)}>{t('Settings.cancel')}</button>
          <button className='settings-modal-success-button' onClick={handleFormSubmit}>{t('Settings.update')}</button>
        </div>
      ) : (
        <div className='settings-modal-button'>
          <button className='settings-modal-cancel-button' onClick={() => setModalVisible(false)}>{t('sidebar.close')}</button>
          <button className='settings-modal-success-button' onClick={() => handleUpdateSuccess(false)}>{t('Settings.update')}</button>
        </div>
      );
    }
    if (activeModal === 'success') {
      return isPasswordUpdate ? (
        <div className='settings-modal-button'>
          <button className='settings-modal-cancel-button' onClick={() => setModalVisible(false)}>{t('Settings.cancel')}</button>
          <button className='settings-modal-success-button' onClick={handleGoToLogin}>{t('Settings.gologin')}</button>
        </div>
      ) : (
        <div className='settings-modal-button'>
          <button className='settings-modal-cancel-button' onClick={handleCloseAllModals}>{t('sidebar.close')}</button>
        </div>
      );
    }
  }


  if (!isLoaded) {
    return <div>{t('sidebar.loading')}</div>;
  }

  return (
    <>
      <div className='custom-page-heading pt-4 py-6'>{t('Settings.settings')}</div>
      <div className="bg-white shadow-md p-6 flex flex-col gap-4 w-full  rounded-lg">

        {/* Personal Details */}

        <div className='flex flex-row justify-between items-center'>
          <span className='settings-section-heading'>{t('Settings.personalDetails')}</span>
        </div>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6'>
          <div className='flex flex-col gap-1'>
            <div className='custom-body3-semibold'>{t('Settings.Name')}</div>
            <div className='custom-body-regular'>{userDetails?.first_name} {userDetails?.last_name}</div>
          </div>
          <div className='flex flex-col gap-1'>
            <div className='custom-body3-semibold'>{t('Settings.phnumber')}</div>
            <div className='custom-body-regular'>{userDetails?.mobile_number}</div>
          </div>
          <div className='flex flex-col gap-1'>
            <div className='custom-body3-semibold'>{t('Settings.Email')}</div>
            <div className='custom-body-regular'>{userDetails?.email_id}</div>
          </div>
          <div className='flex flex-col gap-1'>
            <div className='custom-body3-semibold'>{t('Booking.Status')}</div>
            <div className={`${(userDetails?.status)?.toLowerCase() === "active" ? 'text-[#15b565]' : 'text-[#da3636]'} `}>{userDetails?.status}</div>
          </div>
        </div>
        <div>
          <button onClick={handleChangePasswordClick} className='custom-body-semibold'>
            {t('Settings.ChangePassword')}
          </button>
        </div>

        <div className="border-t border-gray-200 my-4"></div>

        {/* General Configuration */}

        <div className="flex flex-row justify-between items-center">
          <span className='settings-section-heading'> {t('Settings.Generalconfiguration')}</span>
          <button onClick={handleGeneralConfigurationClick} className='custom-edit-button'>
            <img src={editIcon} alt="Edit Icon" />
            {t('Settings.Edit')}
          </button>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          <div className="flex flex-col gap-1">
            <div className='custom-body3-semibold'>{t('Settings.language')}</div>
            <div className='custom-body-regular'>{generalDetails.languages?.[0]?.language_name || '-'}</div>
          </div>
          <div className="flex flex-col gap-1">
            <div className='custom-body3-semibold'>{t('Settings.country')}</div>
            <div className='custom-body-regular'>{generalDetails?.countries?.[0]?.country_name || '-'}</div>
          </div>
          <div className="flex flex-col gap-1">
            <div className='custom-body3-semibold'>{t('Settings.currency')}</div>
            <div className='custom-body-regular'>{generalDetails?.currencies?.[0]?.currency_name || '-'}</div>
          </div>
        </div>

        <div className="border-t border-gray-200 my-4"></div>

        {/* Payment Acquirer Configuration */}

        <div className="flex flex-row justify-between items-center">
          <span className='settings-section-heading'>{t('Settings.Paymentacquire')}</span>
          <button onClick={handlePaymentacquireClick} className='custom-edit-button'>
            <img src={editIcon} alt="Edit Icon" />
            {t('Settings.Edit')}
          </button>
        </div>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6'>
          <div className="flex flex-col gap-1">
            <div className='custom-body3-semibold'>{t('Settings.Paymentacquire')}</div>
            <div className='custom-body-regular'>{paymentAcquirerDetails[0]?.payment_acq_name}</div>
          </div>
          <div className="flex flex-col gap-1">
            <div className='custom-body3-semibold'>{t('Settings.Apikey')}</div>
            <div className="flex items-center space-x-2">
              <div className='relative flex w-full custom-body-regular tracking-widest'>
                <div className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap pr-7">
                  {isVisible ? paymentAcquirerDetails[0]?.acq_key : getMaskedValue(paymentAcquirerDetails[0]?.acq_key)}
                </div>
                <EyeIcon
                  onClick={toggleVisibility}
                  isPasswordVisible={isVisible}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <div className='custom-body3-semibold'>{t('Booking.Status')}</div>
            <div className={`${paymentAcquirerDetails[0]?.status === "ACTIVE" ? 'text-[#15b565]' : 'text-[#da3636]'} `}>{paymentAcquirerDetails[0]?.status?.toLowerCase().replace(/^\w/, (c: any) => c.toUpperCase())}</div>
          </div>
        </div>

        <div className="border-t border-gray-200 my-4"></div>

        {/* Payment Disbural Configuration */}

        <div className="flex flex-row justify-between items-center">
          <span className='settings-section-heading'>{t('Settings.PaymentDisbursal')}</span>
          <button onClick={handlePaymentdisbursalClick} className='custom-edit-button'>
            <img src={editIcon} alt="Edit Icon" />
            {t('Settings.Edit')}
          </button>
        </div>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6'>
          <div className="flex flex-col gap-1">
            <div className='custom-body3-semibold'>{t('Settings.Paymentdisbursal')}</div>
            <div className='custom-body-regular'>{paymentDisbursalDetails[0]?.payment_disb_name}</div>
          </div>
          <div className="flex flex-col gap-1">
            <div className='custom-body3-semibold'>{t('Settings.Apikey')}</div>
            <div className="flex items-center space-x-2">
              <div className='relative flex w-full custom-body-regular tracking-widest'>
                <div className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap pr-7">
                  {isVisible1 ? paymentDisbursalDetails[0]?.disb_key : getMaskedValue(paymentDisbursalDetails[0]?.disb_key)}
                </div>
                <EyeIcon
                  onClick={toggleVisibility1}
                  isPasswordVisible={isVisible1}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <div className='custom-body3-semibold'>{t('Booking.Status')}</div>
            <div className={`${paymentAcquirerDetails[0]?.status === "ACTIVE" ? 'text-[#15b565]' : 'text-[#da3636]'} `}>{paymentDisbursalDetails[0]?.status?.toLowerCase().replace(/^\w/, (c: any) => c.toUpperCase())}</div>
          </div>
        </div>

        <Modal
          visible={modalVisible}
          onHide={() => setModalVisible(false)}
          title={activeModal === 'confirm' || activeModal === 'success' ? '' : modalTitle}
          footer={getFooterContent()}
          content={getModalContent()}
        />
      </div>
    </>
  );
};

export default Settings;
