import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MdArrowDropDown } from "react-icons/md";
import { VscSettings } from "react-icons/vsc";

import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";

import { encryptParams } from "../../../utils/cryptoUtils";
import operatorService from "../../../services/operatorServices";

import Loader from "../../../components/common/Loder";
import NoDataFound from "../../../components/common/NoDataFound";
import FloatingLabelInput from "../../../components/common/FloatingLabelInput";
import FloatingLabelSelect from "../../../components/common/FloatingLabelselect";
import { formatCurrency } from "../../../utils/formatCurrency";

export const Onboarding = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [OperatorsList, setOperatorsList] = useState([]);
  const [operatorName, setOperatorName] = useState("");
  const [operatorId, setOperatorId] = useState("");
  const [onboardingStatus, setOnboardingStatus] = useState("");

  const [filteredOperators, setFilteredOperators] = useState([]);
  const [isSortDropdownOpen, setSortDropdownOpen] = useState(false);
  const [isFilterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [isExportDropdownOpen, setExportDropdownOpen] = useState(false);
  const [selectedSort, setSelectedSort] = useState(t("Wallet.Latest"));
  const [sortOrder, setSortOrder] = useState("asc");

  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);


  const filterDropdownRef = useRef<HTMLDivElement>(null);
  const SortDropdownRef = useRef<HTMLDivElement>(null);
  const ExportDropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchOperators("","","");

    document.addEventListener("mousedown", handleClickOutside);
    return () => { document.removeEventListener("mousedown", handleClickOutside); };
  }, [page, sortOrder]);

  const onPageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    setRows(event.rows);
    setPage(event.page);
  };

  const fetchOperators = async (operatorIdflg:any,operatorNameflg:any,onboardingflg:any) => {
    setLoading(true);
    try {
      const res = await operatorService.getPendingOperatorList(page + 1, sortOrder,operatorIdflg === "" ? "" : operatorId,operatorNameflg === "" ? "" : operatorName,onboardingflg === "" ? "" : onboardingStatus);

      console.log(res);
      const statusMap: Record<string, string> = {
        PENDING: "PENDING",
        pending: "PENDING",
        APPROVED: "APPROVED",
        approved: "APPROVED",
        REJECTED: "REJECTED",
        rejected: "REJECTED"
      };

      const listWithDefaults = res.data.map((item: any, idx: any) => ({
        ...item,
        index: idx + 1,
        totalServiceProviders: item.totalServiceProviders || 0,
        totalServiceCount: item.totalServiceCount || 0,
        wallet_balance: item.wallet_balance || 0,
        onboarding_status: statusMap[item.onboarding_status]
      }));
      setOperatorsList(listWithDefaults);
      setTotalRecords(res.pagination.total_records);
    } catch (error) {
      setOperatorsList([]);
      console.error("Error fetching operators:", error);
    } finally {
      setLoading(false);
    }
  };

  const statusTemplate = (rowData: any) => {
    const statusColor =
      rowData.onboarding_status === "Pending" || rowData.onboarding_status === "PENDING" || rowData.onboarding_status === "pending"
        ? "text-[#e28a11]"
        : rowData.onboarding_status === "Completed" || rowData.onboarding_status === "APPROVED"
          ? "text-[#15b565]"
          : "text-[#E55959]";
    return <span className={statusColor}>{rowData.onboarding_status || "-"}</span>;
  };

  const columns = [
    {
      field: "index",
      label: t("Settlements.Sno"),
      sortable: false,
    },
    {
      field: "operator_id",
      label: t("Operators.OperatorID"),
      sortable: true,
    },
    {
      field: "name",
      label: t("Operators.OperatorName"),
      sortable: true,
    },
    {
      field: "total_service_provider",
      label: t("Operators.TotalServiceProviders"),
      sortable: true,
    },
    {
      field: "total_service_count",
      label: t("Operators.TotalServiceCount"),
      sortable: true,
    },

    {
      field: "wallet_balance",
      label: t("Operators.WalletBalance"),
      sortable: true,
      body: (rowData: any) => {
        return <span>{formatCurrency(rowData.wallet_balance)}</span>;
      }
    },
    {
      field: "is_golive_ready",
      label: t("Operators.Status"),
      sortable: true,
      body: statusTemplate,
    },
  ];

  const handleRowClick = (e: any) => {
    const operatorData = {
      index: e.data.index,
      operatorId: e.data.operator_id,
    };
    const encryptedData = encryptParams(operatorData);
    const encryptedParam = encodeURIComponent(encryptedData);
    navigate(`/onboarding-operator-details/${encryptedParam}`);
    console.log("Clicked ID:", operatorData.operatorId);
  };

  const toggleSortDropdown = () => {
    setSortDropdownOpen(!isSortDropdownOpen);
    setFilterDropdownOpen(false);
    setExportDropdownOpen(false);
  };

  const toggleFilterDropdown = () => {
    setFilterDropdownOpen(!isFilterDropdownOpen);
    setSortDropdownOpen(false);
    setExportDropdownOpen(false);
  };

  const toggleExportDropdown = () => {
    setExportDropdownOpen(!isExportDropdownOpen);
    setSortDropdownOpen(false);
    setFilterDropdownOpen(false);
  };

  const handleSortChange = (sortOption: string) => {
    setSelectedSort(sortOption);
    sortOption === "Latest" ? setSortOrder("asc") : setSortOrder("desc");
    setSortDropdownOpen(false);
  };

  const clearAllFields = () => {
    setOperatorId("");
    setOperatorName("");
    setStatus("");
    setOnboardingStatus("");

    fetchOperators("", "","");
    setFilterDropdownOpen(false);
  };

  const exportToExcel = () => {
    const formattedData = OperatorsList.map((item: any, index: any) => ({
      Sno: index,
      OperatorID: item.operatorId,
      OperatorName: item.operatorName,
      TotalServiceProvider: item.totalServiceProviders,
      TotalServiceCount: item.totalServiceCount,
      WalletBalance: item.walletBalance,
      Status: item.status,
    }));
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Operator");
    XLSX.writeFile(workbook, "ApprovedOperatorList.xlsx");
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableColumn = columns.map((col: any) => col.label);
    const tableRows = OperatorsList.map((item: any, index: any) => [
      index,
      item.operatorId,
      item.operatorName,
      item.totalServiceProviders,
      item.totalServiceCount,
      item.walletBalance,
      item.status,
    ]);

    autoTable(doc, {
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      styles: { fontSize: 10 },
      headStyles: {
        fillColor: [231, 246, 254],
        textColor: [0, 0, 0],
      },
    });

    doc.save("OnboardingOperatorList.pdf");
  };

  const onApply = () => {
    fetchOperators(operatorId, operatorName,onboardingStatus);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (filterDropdownRef.current && !filterDropdownRef.current.contains(event.target as Node)) {
      setFilterDropdownOpen(false);
    }
    if (ExportDropdownRef.current && !ExportDropdownRef.current.contains(event.target as Node)) {
      setExportDropdownOpen(false);
    }
    if (SortDropdownRef.current && !SortDropdownRef.current.contains(event.target as Node)) {
      setSortDropdownOpen(false);
    }
  };

  return (
    <div className="flex flex-col gap-[16px]">
      <div className="flex flex-row justify-between items-center">
        <span className="text-[18px] font-medium leading-[27px] text-[#3e4044]">
          {t("Operators.OnboardingOperators")}
        </span>
        <div className="flex justify-between items-center">
          <div className="flex gap-[8px]">
            {/* Sort by Latest Button */}
            <div className="relative">
              <button
                className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
                onClick={toggleSortDropdown}
              >
                {t("Wallet.SortBy")}: {selectedSort}
                <MdArrowDropDown />
              </button>
              {isSortDropdownOpen && (
                <div ref={SortDropdownRef} className="absolute bg-white border border-gray-300 rounded shadow-md mt-[5px] w-[130px] z-[500]">
                  <button
                    className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100 border-b"
                    onClick={() => handleSortChange(t("Wallet.Latest"))}
                  >
                    <span className="text-[16px] font-normal leading-[24px]">
                      {t("Wallet.Latest")}
                    </span>
                  </button>
                  <button
                    className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100"
                    onClick={() => handleSortChange(t("Wallet.Oldest"))}
                  >
                    <span className="text-[16px] font-normal leading-[24px]">
                      {t("Wallet.Oldest")}
                    </span>
                  </button>
                </div>
              )}
            </div>

            {/* Filter Dropdown */}
            <div className="relative">
              <button
                className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
                onClick={toggleFilterDropdown}
              >
                {t("Wallet.Filter")}
                <VscSettings />
              </button>
              {isFilterDropdownOpen && (
                <div ref={filterDropdownRef} className="absolute flex flex-col right-[-85px] gap-[20px] bg-white border border-gray-300 rounded shadow-md mt-[5px] p-5 w-[390px] z-[500]">
                  <div className="text-[#3E4044] text-[18px] font-medium leading-[27px]">
                    {t("Wallet.Filter")}
                  </div>

                  <div className="text-[14px] leading-[24px] font-normal text-[#00000099]">
                    <FloatingLabelInput
                      id="email_float_label"
                      type="text"
                      label={t("Filter.OperatorId")}
                      value={operatorId}
                      onChange={(e: any) => setOperatorId(e.target.value)}
                    />
                  </div>

                  <div className="text-[14px] leading-[24px] font-normal text-[#00000099]">
                    <FloatingLabelInput
                      id="email_float_label"
                      type="text"
                      label={t("Operators.OperatorName")}
                      value={operatorName}
                      onChange={(e: any) => setOperatorName(e.target.value)}
                    />
                  </div>
                  <div className="text-[14px] leading-[24px] font-normal text-[#00000099]">
                    <FloatingLabelSelect
                      // key={status}
                      id="paymenttype"
                      label={t("Booking.Status")}
                      value={onboardingStatus}
                      onChange={(e: any) => setOnboardingStatus(e.target.value)}
                      options={[
                        { value: "APPROVED", label: "APPROVED" },
                        { value: "PENDING", label: "PENDING" },
                        { value: "REJECTED", label: "REJECTED" },
                      ]}
                    />
                  </div>
                  <div className="flex flex-col gap-[16px]">
                    <button
                      className="block w-full text-center px-[8px] py-[8px]"
                      onClick={clearAllFields}
                    >
                      {t("Filter.ClearAll")}
                    </button>
                    <button
                      className="block w-full text-center px-[8px] py-[8px] bg-[#2196F3] text-white"
                      onClick={onApply}
                    >
                      {t("Filter.Apply")}
                    </button>
                  </div>
                </div>
              )}
            </div>

            {/* Export Dropdown */}
            <div className="relative">
              <button
                className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
                onClick={toggleExportDropdown}
              >
                {t("Wallet.Export")}
                <MdArrowDropDown />
              </button>
              {isExportDropdownOpen && (
                <div ref={ExportDropdownRef} className="absolute right-0 bg-white border border-gray-300 rounded shadow-md mt-[5px] w-[160px] z-[500]">
                  <button
                    className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100 border-b"
                    onClick={() => {
                      setExportDropdownOpen(false);
                      exportToPDF();
                    }}
                  >
                    <span className="text-[16px] font-normal leading-[24px]">
                      {t("Export.ExportASPdf")}
                    </span>
                  </button>
                  <button
                    className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100"
                    onClick={() => {
                      setExportDropdownOpen(false);
                      exportToExcel();
                    }}
                  >
                    <span className="text-[16px] font-normal leading-[24px]">
                      {t("Export.ExportASXlsx")}
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        {loading ? (
          <div className="mt-40">
            <Loader />
          </div>
        ) : OperatorsList.length === 0 ? (
          <NoDataFound /> // Show NoDataFound when no data is present
        ) : (
          <>
            <DataTable
              value={OperatorsList}
              rows={10}
              tableStyle={{ minWidth: "50rem" }}
              rowHover
              onRowClick={handleRowClick}
            >
              {columns.map((col, index) => (
                <Column
                  key={index}
                  field={col.field}
                  header={col.label}
                  sortable={col.sortable}
                  body={col.body}
                  headerClassName="custom-header-bg"
                  className="custom-col-bg"
                />
              ))}
            </DataTable>
            <Paginator
              first={first}
              rows={rows}
              totalRecords={totalRecords}
              onPageChange={onPageChange}
            />
          </>
        )}
      </div>
    </div>
  );
};
