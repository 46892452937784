// import {
// 	HiOutlineViewGrid,
// 	HiOutlineCube,
// 	HiOutlineShoppingCart,
// 	HiOutlineUsers,
// 	HiOutlineDocumentText,
// 	HiOutlineAnnotation,
// 	HiOutlineQuestionMarkCircle,
// 	HiOutlineCog
// } from 'react-icons/hi'

export const DASHBOARD_SIDEBAR_LINKS = [
    // {
    //     key: 'dashboard',
    //     label: 'Dashboard',
    //     path: '/dashboard', // Adjusted to reflect your routes
    //     icon: <HiOutlineViewGrid />
    // },
    {
        key: 'bookings',
        // label: 'Bookings',
        path: '/bookings', // Adjusted to match booking list route
        // icon: <HiOutlineCube />
    },
    {
        key: 'operators',
        // label: 'operators',
        path: '/operators/approved', // Adjusted to match operators route
        // icon: <HiOutlineUsers />
    },
    {
        key: 'service-providers',
        // label: 'Service Providers',
        path: '/service-providers', // Adjusted to match service provider route
        // icon: <HiOutlineUsers />
    },
    // {
    //     key: 'financials',
    //     label: 'Financials',
    //     path: '/finacials', // Adjusted to match financials route
    //     icon: <HiOutlineDocumentText />
    // },
    {
        key: 'settlements',
        // label: 'Reports',
        path: '/History',
        // icon: <HiOutlineDocumentText />
    },
    {
        key: 'wallet',
        // label: 'Wallet',
        path: '/wallet', // Adjusted to match wallet route
        // icon: <HiOutlineAnnotation />
    },
    {
        key: 'settings',
        // label: 'Wallet',
        path: '/settings', // Adjusted to match wallet route
        // icon: <HiOutlineAnnotation />
    },
    // {
    //     key: 'settlements',
    //     // label: 'Reports',
    //     path: '/settlement',
    //     // icon: <HiOutlineDocumentText />
    // },
    // {
    //     key: 'reports',
    //     // label: 'Reports',
    //     path: '/reports', // Adjusted to match reports route
    //     // icon: <HiOutlineDocumentText />
    // },
    // {
    //     key: 'settings',
    //     // label: 'Settings',
    //     path: '/settings', // Matches settings route
    //     // icon: <HiOutlineCog />
    // },
];

export const DASHBOARD_SIDEBAR_BOTTOM_LINKS = [
    {
        key: 'logout',
        label: 'Log out',
        path: '/signin', // Matches settings route
        // icon: <HiOutlineCog />
    },
];
