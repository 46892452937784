import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MdArrowDropDown } from "react-icons/md";
import { VscSettings } from "react-icons/vsc";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import autoTable from 'jspdf-autotable';
import FloatingLabelInput from '../../../components/common/FloatingLabelInput';
import NoDataFound from '../../../components/common/NoDataFound';
import { decryptParams } from "../../../utils/cryptoUtils";
import operatorService from "../../../services/operatorServices";

import Helpers from '../../../utils/helpers';
import { formatDateTime } from '../../../utils/formatDateTime';
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';
import { formatCurrency } from '../../../utils/formatCurrency';

export interface OperatorDetails {
  booking_id: string | null;

}
interface Booking {
  operator_id: number;
  name: string;
  address_1: string | null;
  email_id: string | null;
  onboarding_status: string | null;
  mobile_number: number;
  // Add other fields here...
}

export default function BookingSection() {
  const { t } = useTranslation();
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [bookingID, setbookingid] = useState('');
  const [transactionID, settransactionId] = useState('');
  const [status, setStatus] = useState('');
  const [isSortDropdownOpen, setSortDropdownOpen] = useState(false);
  const [isFilterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [isExportDropdownOpen, setExportDropdownOpen] = useState(false);
  const [selectedSort, setSelectedSort] = useState(t('Wallet.Latest'));

  const { encryptedParam } = useParams();
  const [loading, setLoading] = useState<boolean>(false);

  const filterDropdownRef = useRef<HTMLDivElement>(null);
  const SortDropdownRef = useRef<HTMLDivElement>(null);
  const ExportDropdownRef = useRef<HTMLDivElement>(null);

  const [first, setFirst] = useState(0);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);


  interface DecryptedParam {
    index?: any;
    operatorId?: string;
  }

  useEffect(() => {
    if (encryptedParam) {
      const decodedParam = decodeURIComponent(encryptedParam).replace(/\s/g, '+');
      const decryptedParam = decryptParams(decodedParam) as DecryptedParam;
      if (decryptedParam.operatorId) {
        bookingList(decryptedParam.operatorId,"DESC","");
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => { document.removeEventListener("mousedown", handleClickOutside); };
  }, [encryptedParam, page]);

  const bookingStatusTemplate = (rowData: any) => {
    const status = rowData.booking_status || "-"; // Fallback to "-"
    const statusColor =
      status.toUpperCase() === 'COMPLETED' ? 'text-green-500' :
        status.toUpperCase() === 'ONGOING' ? 'text-orange-500' :
          status.toUpperCase() === 'PENDING' ? 'text-yellow-500' :
            status.toUpperCase() === 'FAILED' ? 'text-red-500' :
              'text-gray-500'; // Default color for unknown statuses

    return <span className={statusColor}>{status}</span>;
  };

  // Template for payment status
  const paymentStatusTemplate = (rowData: any) => {
    const status = rowData.payment_status || "-"; // Fallback to "-"
    const statusColor =
      status.toUpperCase() === 'COMPLETED' ? 'text-green-500' :
        status.toUpperCase() === 'ONGOING' ? 'text-orange-500' :
          status.toUpperCase() === 'PENDING' ? 'text-yellow-500' :
            status.toUpperCase() === 'FAILED' ? 'text-red-500' :
              'text-gray-500'; // Default color for unknown statuses

    return <span className={statusColor}>{status}</span>;
  };



  const columns = [
    {
      field: 'id',
      header: t('Settlements.Sno'),
      sortable: true,
      body: (rowData: any, options: { rowIndex: number }) => options.rowIndex + 1
    },
    {
      field: 'updated_datetime',
      header: t('Settlements.SettlementDateandTime'),
      sortable: true,
      body: (rowData: any) => Helpers.formatValue(formatDateTime(rowData.booking_date_time)),
    },
    {
      field: 'booking_id',
      header: t('Booking.BookingId'),
      sortable: true,
      body: (rowData: any) => Helpers.formatValue(rowData.booking_id),
    },
    {
      field: 'amount',
      header: t('Booking.Amount'),
      sortable: true,
      body: (rowData: any) => Helpers.formatValue(formatCurrency(rowData.amount)),
    },
    {
      field: 'payment_reference',
      header: t('Booking.PaymentReferenceID'),
      sortable: true,
      body: (rowData: any) => Helpers.formatValue(rowData.payment_reference),
    },
    {
      field: 'booking_status',
      header: t('Wallet.BookingStatus'),
      sortable: true,
      body: bookingStatusTemplate, // Use the bookingStatusTemplate for rendering
    },
    {
      field: 'payment_status',
      header: t('Wallet.PaymentStatus'),
      sortable: true,
      body: paymentStatusTemplate, // Use the paymentStatusTemplate for rendering
    },
  ];


  const bookingList = (operatorId: any, sort_order: string = "DESC",BookingIdflg:any) => {
    setLoading(true);
    operatorService
      .getBookingsByOperatorId(operatorId, page + 1, sort_order,BookingIdflg === "" ? "" :  bookingID)
      .then((res) => {
        if (res.status === 200) {
          setBookings(res.data?.data);
          setTotalRecords(res.data?.pagination?.total_records);
        } else {
          setBookings([]);
        }
      })
      .catch((error) => {
        console.error('Error fetching bookings:', error.response?.data || error.message);
        setBookings([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  const toggleSortDropdown = () => {
    setSortDropdownOpen(!isSortDropdownOpen);
    setFilterDropdownOpen(false);
    setExportDropdownOpen(false);
  };

  const toggleFilterDropdown = () => {
    setFilterDropdownOpen(!isFilterDropdownOpen);
    setSortDropdownOpen(false);
    setExportDropdownOpen(false);
  };

  const toggleExportDropdown = () => {
    setExportDropdownOpen(!isExportDropdownOpen);
    setSortDropdownOpen(false);
    setFilterDropdownOpen(false);
  };
  const clearAllFields = () => {
    setbookingid('');
    settransactionId('');
    setStatus('');

    if (encryptedParam) {
      const decodedParam = decodeURIComponent(encryptedParam).replace(/\s/g, '+');
      const decryptedParam = decryptParams(decodedParam) as DecryptedParam;

      if (decryptedParam.operatorId) {
        bookingList(decryptedParam.operatorId, "DESC", "");
        setFilterDropdownOpen(false);
      }
    }
  };
  const handleSortChange = (sortOption: string) => {
    setSelectedSort(sortOption);
    setSortDropdownOpen(false);

    const sortOrder = sortOption === t('Wallet.Latest') ? "DESC" : "ASC";
    if (encryptedParam) {
      const decodedParam = decodeURIComponent(encryptedParam).replace(/\s/g, '+');
      const decryptedParam = decryptParams(decodedParam) as DecryptedParam;

      if (decryptedParam.operatorId) {
        bookingList(decryptedParam.operatorId, sortOrder,"");
        setFilterDropdownOpen(false);
      }
    }

  };

  const exportToExcel = () => {
    const formattedData = bookings.map((item: any, index: number) => ({
      Sno: index + 1,
      BookingDateTime: item.updated_datetime ?? " - ",
      BookingID: item.booking_id ?? " - ",
      Amount: item.amount ?? " - ",
      PaymentReferenceID: item.paymentReferenceId ?? " - ",
      PaymentStatus: item.payment_status ?? " - ",
      Status: item.booking_status ?? " - "

    }));
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Operator_Bookings');
    XLSX.writeFile(workbook, 'Operator_Bookings.xlsx');
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableColumn = columns.map((col: any) => col.header);
    const tableRows = bookings.map((item: any, index: number) => [
      index + 1,
      item.updated_datetime ?? " - ",
      item.booking_id ?? " - ",
      item.amount ?? " - ",
      item.paymentReferenceId ?? " - ",
      item.payment_status ?? " - ",
      item.booking_status ?? " - "

    ]);

    autoTable(doc, {
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      styles: { fontSize: 10, },
      headStyles: {
        fillColor: [231, 246, 254],
        textColor: [0, 0, 0]
      }
    });


    doc.save('Operator_Bookings.pdf');
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (filterDropdownRef.current && !filterDropdownRef.current.contains(event.target as Node)) {
      setFilterDropdownOpen(false);
    }
    if (ExportDropdownRef.current && !ExportDropdownRef.current.contains(event.target as Node)) {
      setExportDropdownOpen(false);
    }
    if (SortDropdownRef.current && !SortDropdownRef.current.contains(event.target as Node)) {
      setSortDropdownOpen(false);
    }
  };

  const onPageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    setRows(event.rows);
    setPage(event.page);
  };

  const onApply = () => {
    if (encryptedParam) {
      const decodedParam = decodeURIComponent(encryptedParam).replace(/\s/g, '+');
      const decryptedParam = decryptParams(decodedParam) as DecryptedParam;

      if (decryptedParam.operatorId) {
        bookingList(decryptedParam.operatorId, "DESC", bookingID);
        setFilterDropdownOpen(false);
      }
    }
  };

  return (
    <div className="flex flex-col gap-[16px]">
      <div className="flex justify-between items-center">
        <span className="custom-page-heading">{t('BookingDetails.BookingDetails')}</span>
        <div className="flex gap-[8px]">
          {/* Sort by Latest Button */}
          <div className="relative">
            <button
              className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
              onClick={toggleSortDropdown}
            >
              {t('Wallet.SortBy')}: {selectedSort}
              <MdArrowDropDown />
            </button>
            {isSortDropdownOpen && (
              <div ref={SortDropdownRef} className="absolute bg-white border border-gray-300 rounded shadow-md mt-[5px] w-[130px] z-[500]">
                <button
                  className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100 border-b"
                  onClick={() => handleSortChange(t('Wallet.Latest'))}
                >
                  <span className="text-[16px] font-normal leading-[24px]">{(t('Wallet.Latest'))}</span>
                </button>
                <button
                  className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100"
                  onClick={() => handleSortChange(t('Wallet.Oldest'))}
                >
                  <span className="text-[16px] font-normal leading-[24px]">{(t('Wallet.Oldest'))}</span>
                </button>
              </div>
            )}
          </div>
          {/* Filter Dropdown */}
          <div className="relative">
            <button
              className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
              onClick={toggleFilterDropdown}
            >
              {t('Wallet.Filter')}
              <VscSettings />
            </button>
            {isFilterDropdownOpen && (
              <div ref={filterDropdownRef} className="absolute flex flex-col right-[-85px] gap-[20px] bg-white border border-gray-300 rounded shadow-md mt-[5px] p-5 w-[390px] z-[500]">
                <div className="text-[#3E4044] text-[18px] font-medium leading-[27px]">
                  {t('Wallet.Filter')}
                </div>

                <div className="text-[14px] leading-[24px] font-normal text-[#00000099]">
                  <FloatingLabelInput
                    id="email_float_label"
                    type="text"
                    label={t('Booking.BookingId')}
                    value={bookingID}
                    onChange={(e) => setbookingid(e.target.value)}
                  />
                </div>
                <div className="flex flex-col gap-[16px]">
                  <button
                    className="block w-full text-center px-[8px] py-[8px]"
                    onClick={clearAllFields}
                  >
                    {t('Filter.ClearAll')}
                  </button>
                  <button
                    className="block w-full text-center px-[8px] py-[8px] bg-[#2196F3] text-white" onClick={onApply}
                  >
                    {t('Filter.Apply')}
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className="relative">
            <button
              className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
              onClick={toggleExportDropdown}
            >
              {t('Wallet.Export')}
              <MdArrowDropDown />
            </button>
            {isExportDropdownOpen && (
              <div ref={ExportDropdownRef} className="absolute right-0 bg-white border border-gray-300 rounded shadow-md mt-[5px] w-[160px] z-[500]">
                <button
                  className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100 border-b"
                  onClick={() => {
                    setExportDropdownOpen(false);
                    exportToPDF()
                  }}
                >
                  <span className="text-[16px] font-normal leading-[24px]">{t('Export.ExportASPdf')}</span>
                </button>
                <button
                  className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100"
                  onClick={() => {
                    setExportDropdownOpen(false);
                    exportToExcel()
                  }}
                >
                  <span className="text-[16px] font-normal leading-[24px]">{t('Export.ExportASXlsx')}</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        {bookings.length === 0 ? <NoDataFound /> :
          <>
            <DataTable
              value={bookings}
              rows={10}
              tableStyle={{ minWidth: '50rem' }}
              rowHover
            >
              {columns.map((col, index) => (
                <Column
                  key={index}
                  field={col.field}
                  header={col.header}
                  sortable={col.sortable}
                  body={col.body}
                  headerClassName='custom-header-bg'
                  className='custom-col-bg'
                  bodyStyle={{ textAlign: 'center' }}
                />
              ))}
            </DataTable>
            <Paginator
              first={first}
              rows={rows}
              totalRecords={totalRecords}
              onPageChange={onPageChange}
            />
          </>

        }
      </div>
    </div>
  );
}
