/**
 * @param {string | Date} date - The date to be formatted (string or Date object)
 * @param {object} options - Optional settings for formatting
 * @param {string} options.locale - The locale to use (default: 'en-US')
 * @param {object} options.formatOptions - Custom Intl.DateTimeFormat Options
 * @returns {string} The formatted date-time string
 */

export function formatDateTime (
    date: string | Date,
    options: {
        locale?: string;
        formatOptions?: Intl.DateTimeFormatOptions;
    } = {}
): string {
    // const { locale = 'en-US', formatOptions } = options;
    const { locale = 'en-GB', formatOptions } = options;

    const dateObj = typeof date == 'string' ? new Date(date) : date;

    if(isNaN(dateObj.getTime())) {
        // If the date is invalid, return it as string..
        return date.toString();
    }

    return new Intl.DateTimeFormat(locale, {
        // Default options if none are provided
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        // second: '2-digit',
        // dateStyle: 'medium',
        // timeStyle: 'short',
        ...formatOptions,
    }).format(dateObj)
    .replace(',', ' -');
}