import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import FloatingLabelInput from '../common/FloatingLabelInput';
import { useParams } from "react-router-dom";
import { decryptParams } from "./../../utils/cryptoUtils";
import { useTranslation } from "react-i18next";
import operatorService from '../../services/operatorServices';
 
const PersonalDetails = forwardRef((props, ref) => {
 
  const [operatorId, setOperatorId] = useState('');
  const [name, setName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [email, setEmail] = useState('');
  const [operatorDetails, setOperatorDetails]: any = useState("");
  const { encryptedParam } = useParams();
  const { t } = useTranslation();
 
  useEffect(() => {
    if (encryptedParam) {
      fetchOperatorDetails(encryptedParam);
    }
  }, [encryptedParam]);
 
  const fetchOperatorDetails = (encryptedParam: string | undefined) => {
    if (!encryptedParam) return;
 
    try {
      const decodedParam = decodeURIComponent(encryptedParam).replace(/\s/g, "+");
      const decryptedParam: any = decryptParams(decodedParam);
 
      if (decryptedParam) {
        console.log(decryptedParam);
        operatorService.getOperatorById(decryptedParam?.operatorId)
          .then((res) => {
            console.log("Personal details:", res);
            setOperatorDetails(res.data.data);
            setOperatorId(res.data.data.operator_id);
            setName(res.data.data.name)
            setEmail(res.data.data.email_id)
            setMobileNumber(res.data.data.mobile_number)
 
          })
          .catch((error) => {
            console.error("Error fetching operator details:", error);
          });
      }
    } catch (error) {
      console.error("Error processing the encrypted parameter:", error);
    }
  };
 
  useImperativeHandle(ref, () => ({
    getDetails() {
      return {
        name,
        mobileNumber,
        email,
      };
    },
  }));
 
  return (
    <div>
      <div>
        <label htmlFor="name" className="input-label-settings"></label>
        <FloatingLabelInput
          id="name"
          type="text"
          label={t("Operators.Name")}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="mobile_number" className="input-label-settings"></label>
        <FloatingLabelInput
          id="mobile_number"
          type="tel"
          label={t("ServiceProvider.PhoneNumber")}
          value={mobileNumber}
          onChange={(e) => {
            const numericValue = e.target.value.replace(/[a-zA-Z]/g, '');
            setMobileNumber(numericValue);
          }}
        />
      </div>
      <div>
        <label htmlFor="email" className="input-label-settings"></label>
        <FloatingLabelInput
          id="email"
          type="email"
          label={t("ServiceProvider.EmailID")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
    </div>
  );
});
 
export default PersonalDetails;