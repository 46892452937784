// src/i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN_UK from './locales/en_uk/translation.json';
import translationEN_US from './locales/en_us/translation.json';
import translationFR from './locales/fr/translation.json';
import translationES from './locales/es/translation.json';
import translationPT from './locales/pt/translation.json';
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en_uk: { translation: translationEN_UK },
      en_us: { translation: translationEN_US },
      fr: { translation: translationFR },
      es: { translation: translationES },
      pt: { translation: translationPT }
    },
    fallbackLng: 'en_us',
    lng: 'en_us',
    interpolation: {
      escapeValue: false,
    }
  });
 
export default i18n;