import api from "./api";

const getAllBookings = async (
  page: any,
  sortOrder: any = "asc",
  booking_id: string = "",
  booking_status: string = ""
) => {
  const response = await api.get(
    `bookings/allBookings?page=${page}&sort_order=${sortOrder}&sort_field=booking_date_time&booking_id=${booking_id}&booking_status=${booking_status}`,
  );
  console.log(response);
  return response;
};

const getBookingDetails = async (bookingId: any) => {
  const response = await api.get(`/bookings/detail/${bookingId}`);
  return response.data;
};

const BookingFilter = async (
  page: any,
  booking_id: string | null,
  booking_status: string | null
) => {
  try {
    const params: { [key: string]: string | null } = {};
    if (booking_id) params.booking_id = booking_id;
    if (booking_status) params.booking_status = booking_status;

    // Make the GET request with headers including the token
    const response = await api.get(
      `bookings/allBookings?page=${page}&booking_id=${booking_id}&booking_status=${booking_status}`
    );
    console.log(response, "my filter");
    return response;
  } catch (error) {
    console.error("Error fetching filtered bookings:", error);
    throw error; // Re-throw the error for handling in the calling function
  }
};

const bookingService = {
  getAllBookings,
  getBookingDetails,
  BookingFilter,
};
export default bookingService;
