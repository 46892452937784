// Formats a given number into a currency string
/**
* @param {number} amount - the amount to be formatted
* @param {string} currency - the currency code (ex: 'USD', 'EUR')
* @param {string} locale - the locale to use default ('en-US')
* @returns {string} the formatted currency string
*/

export function formatCurrency(amount: number, currency: string = 'USD', locale: string = 'en-US'): string {
    if (isNaN(amount)) {
        //if the input is not a valid number, return it as it is..
        return amount.toString();
    }

    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
    }).format(amount);
}