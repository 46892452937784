import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useNavigate } from 'react-router-dom';
import { MdArrowDropDown } from "react-icons/md";
import { VscSettings } from "react-icons/vsc";
import autoTable from 'jspdf-autotable';
import Loader from '../../../components/common/Loder';
import authService from '../../../services/authService';
import { encryptParams } from '../../../utils/cryptoUtils';
import FloatingLabelInput from '../../../components/common/FloatingLabelInput';
import NoDataFound from '../../../components/common/NoDataFound';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Operator from '../../../pages/Operators';
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';
import { formatCurrency } from '../../../utils/formatCurrency';

export default function SpList() {

  const navigate = useNavigate();

  const { t } = useTranslation();
  const token = localStorage.getItem("token");

  const [status, setStatus] = useState('');
  const [allservice, setAllservice] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedSort, setSelectedSort] = useState(t('Wallet.Latest'));
  const [serviceProviders, setServiceProviders] = useState<any[]>([]);

  const [isSortDropdownOpen, setSortDropdownOpen] = useState(false);
  const [isFilterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [isExportDropdownOpen, setExportDropdownOpen] = useState(false);

  const [providerid, setproviderId] = useState('');
  const [providername, setprovidername] = useState('');

  const [first, setFirst] = useState(0);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);

  const filterDropdownRef = useRef<HTMLDivElement>(null);
  const SortDropdownRef = useRef<HTMLDivElement>(null);
  const ExportDropdownRef = useRef<HTMLDivElement>(null);


  useEffect(() => {
    ServiceProviderList("","");
    document.addEventListener("mousedown", handleClickOutside);
    return () => { document.removeEventListener("mousedown", handleClickOutside); };
  }, [page]);

  const statusTemplate = (rowData: any) => {
    const status = rowData.status || "";
    const displayStatus = status ? status.toUpperCase() : "-";
    const statusColor =
      status.toLowerCase() === 'active' ? 'text-green-500' :
        status.toLowerCase() === 'inactive' ? 'text-red-500' :
          'text-gray-500'; // Default color if status is neither active nor inactive

    return <span className={statusColor}>{displayStatus}</span>;
  };

  const onPageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    setRows(event.rows);
    setPage(event.page);
  };


  const columns = [
    {
      field: 'id',
      label: t('Settlements.Sno'),
      body: (rowData: any, options: { rowIndex: number }) => options.rowIndex + 1 || '-',
    },
    {
      field: 'sp_id',
      label: t('BookingDetails.ServiceProviderID'),
      sortable: true,
      body: (rowData: any) => rowData.sp_id || '-',
    },
    {
      field: 'name',
      label: t('BookingDetails.ServiceProviderName'),
      sortable: true,
      body: (rowData: any) => rowData.name || '-',
    },
    {
      field: 'total_bookings',
      label: t('ServiceProvider.TotalServiceCount'),
      sortable: true,
      body: (rowData: any) => rowData.total_bookings || '-',
    },
    {
      field: 'current_balance_due',
      label: t('ServiceProvider.WalletBalance'),
      sortable: true,
      body: (rowData: any) => formatCurrency(rowData.current_balance_due || '-'),
    },
    {
      field: 'status',
      label: t('Booking.Status'),
      sortable: true,
      body: statusTemplate
    },
  ];

  const ServiceProviderList = (provideridflg:any,providernameflg:any,sort_order: string = "DESC") => {
    setLoading(true);
    authService.serviceProviderList(token, page + 1,provideridflg === "" ? "" : providerid,providernameflg === "" ? "" :providername,sort_order)
      .then((response) => {
        console.log(response, "Service Provider List");
        if (response.status === 200) {
          setServiceProviders(response.data.data.service_provider_list)
          setTotalRecords(response.data.data.pagination.total_records)
        }
        else {
          setServiceProviders([]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  if (loading) {
    return <Loader />;
  }

  const handleRowClick = (e: any) => {
    const providerData = { spid: e.data.sp_id , operatorID:e.data.operator_id}
    const encryptedData = encryptParams(providerData);
    const encryptedParam = encodeURIComponent(encryptedData)
    navigate(`/service-providers/${encryptedParam}`, { state: { operatorID: providerData.operatorID } });
    console.log("Clicked ID:", encryptedParam, providerData.spid);
  };

  const toggleSortDropdown = () => {
    setSortDropdownOpen(!isSortDropdownOpen);
    setFilterDropdownOpen(false);
    setExportDropdownOpen(false);
  };

  const toggleFilterDropdown = () => {
    setFilterDropdownOpen(!isFilterDropdownOpen);
    setSortDropdownOpen(false);
    setExportDropdownOpen(false);
  };

  const toggleExportDropdown = () => {
    setExportDropdownOpen(!isExportDropdownOpen);
    setSortDropdownOpen(false);
    setFilterDropdownOpen(false);
  };

  const handleSortChange = (sortOption: string) => {
    setSelectedSort(sortOption);
    setSortDropdownOpen(false);

    const sortOrder = sortOption === t('Wallet.Latest') ? "DESC" : "ASC";
    ServiceProviderList("", "", sortOrder);
  };

  const clearAllFields = () => {
    setproviderId('');
    setprovidername('');
    setStatus('');
    ServiceProviderList("","")
    setFilterDropdownOpen(false);
  };

  const exportToExcel = () => {
    const formattedData = serviceProviders.map((item: any) => ({
      ServiceProviderID: item.sp_id || "-",
      ServiceProviderName: item.name || "-",
      TotalServiceCount: item.total_bookings || "-",
      WalletBalance: item.current_balance_due || "-",
      Status: item.status || "-"

    }));
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'ServiceProvider');
    XLSX.writeFile(workbook, 'ServiceProvider.xlsx');
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableColumn = columns.map((col: any) => col.label);
    const tableRows = serviceProviders.map((item: any,index:any) => [
      index + 1 || "-",
      item.sp_id || "-",
      item.name || "-",
      item.total_bookings || "-", 
      item.current_balance_due || "-",
      item.status || "-"
    ]);

    autoTable(doc, {
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      styles: { fontSize: 10, },
      headStyles: {
        fillColor: [231, 246, 254],
        textColor: [0, 0, 0]          // Black text color for header
      }
    });

    doc.save('ServiceProvider.pdf');
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (filterDropdownRef.current && !filterDropdownRef.current.contains(event.target as Node)) {
      setFilterDropdownOpen(false);
    }
    if (ExportDropdownRef.current && !ExportDropdownRef.current.contains(event.target as Node)) {
      setExportDropdownOpen(false);
    }
    if (SortDropdownRef.current && !SortDropdownRef.current.contains(event.target as Node)) {
      setSortDropdownOpen(false);
    }
  };

  
  const onApply = () => {
    ServiceProviderList(providerid, providername);
    setFilterDropdownOpen(false);
  };

  return (
    <div className="flex flex-col gap-[16px]">
      <span className="custom-page-heading">{t('sidebar.service-providers')}</span>
      <div className="flex justify-between items-center">
        <span className="text-[#3E4044] text-[18px] font-medium leading-[27px]">{t('ServiceProvider.List')}</span>
        <div className="flex gap-[8px]">
          {/* Sort by Latest Button */}
          <div className="relative">
            <button
              className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
              onClick={toggleSortDropdown}
            >
              {t('Wallet.SortBy')}: {selectedSort}
              <MdArrowDropDown />
            </button>
            {isSortDropdownOpen && (
              <div ref={SortDropdownRef} className="absolute bg-white border border-gray-300 rounded shadow-md mt-[5px] w-[130px] z-[500]">
                <button
                  className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100 border-b"
                  onClick={() => handleSortChange(t('Wallet.Latest'))}
                >
                  <span className="text-[16px] font-normal leading-[24px]">{(t('Wallet.Latest'))}</span>
                </button>
                <button
                  className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100"
                  onClick={() => handleSortChange(t('Wallet.Oldest'))}
                >
                  <span className="text-[16px] font-normal leading-[24px]">{(t('Wallet.Oldest'))}</span>
                </button>
              </div>
            )}
          </div>

          {/* Filter Dropdown */}
          <div className="relative">
            <button
              className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
              onClick={toggleFilterDropdown}
            >
              {t('Wallet.Filter')}
              <VscSettings />
            </button>
            {isFilterDropdownOpen && (
              <div ref={filterDropdownRef} className="absolute flex flex-col right-[-85px] gap-[20px] bg-white border border-gray-300 rounded shadow-md mt-[5px] p-5 w-[390px] z-[500]">
                <div className="text-[#3E4044] text-[18px] font-medium leading-[27px]">
                  {t('Wallet.Filter')}
                </div>

                <div className="text-[14px] leading-[24px] font-normal text-[#00000099]">
                  <FloatingLabelInput
                    id="email_float_label"
                    type="text"
                    label={t('Filter.ServiceProviderId')}
                    value={providerid}
                    onChange={(e: any) => setproviderId(e.target.value)}
                  />

                </div>

                <div className="text-[14px] leading-[24px] font-normal text-[#00000099]">
                  <FloatingLabelInput
                    id="Service_Provider_Name"
                    type="text"
                    label={t('BookingDetails.ServiceProviderName')}
                    value={providername}
                    onChange={(e: any) => setprovidername(e.target.value)}
                  />

                </div>

                <div className="flex flex-col gap-[16px]">
                  <button
                    className="block w-full text-center px-[8px] py-[8px]"
                    onClick={clearAllFields}
                  >
                    {t('Filter.ClearAll')}
                  </button>
                  <button
                    className="block w-full text-center px-[8px] py-[8px] bg-[#2196F3] text-white" onClick={onApply}
                  >
                    {t('Filter.Apply')}
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="relative">
            <button
              className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
              onClick={toggleExportDropdown}
            >
              {t('Wallet.Export')}
              <MdArrowDropDown />
            </button>
            {isExportDropdownOpen && (
              <div ref={ExportDropdownRef} className="absolute right-0 bg-white border border-gray-300 rounded shadow-md mt-[5px] w-[160px] z-[500]">
                <button
                  className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100 border-b"
                  onClick={() => {
                    setExportDropdownOpen(false);
                    exportToPDF()
                  }}
                >
                  <span className="text-[16px] font-normal leading-[24px]">{t('Export.ExportASPdf')}</span>
                </button>
                <button
                  className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100"
                  onClick={() => {
                    setExportDropdownOpen(false);
                    exportToExcel()
                  }}
                >
                  <span className="text-[16px] font-normal leading-[24px]">{t('Export.ExportASXlsx')}</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-[16px]">
        <div>
          {serviceProviders.length === 0 ? <NoDataFound /> :
            <>
            <DataTable
              value={serviceProviders}
              rows={10}
              tableStyle={{ minWidth: '50rem' }}
              rowHover
              onRowClick={handleRowClick}
            >
              {columns.map((col, index) => (
                <Column
                  key={index}
                  field={col.field}
                  header={col.label}
                  sortable={col.sortable}
                  body={col.body}
                  headerClassName="custom-header-bg"
                  className="custom-col-bg"
                />
              ))}
            </DataTable>
            <Paginator
              first={first}
              rows={rows}
              totalRecords={totalRecords}
              onPageChange={onPageChange}
            />
            </>
          }
        </div>
      </div>
    </div>

  );
}
