import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import SettingService from "../../services/settingsService";

interface ErrorType {
  payment_disb_name: string;
  apiKey: string;
  status: string;
}

interface PaymentDisbursalProps { }

const PaymentDisbursalConfiguration = forwardRef(
  (props: PaymentDisbursalProps, ref) => {
    const { t } = useTranslation();

    const [paymentDisburalOptions, setPaymentDisburalOptions]: any = useState([]);
    const [paymentDisbName, setPaymentDisbName] = useState("");
    const [apiKey, setApiKey] = useState("");
    const [status, setStatus] = useState("active");
    const [id, setId] = useState<number | null>(null); // Optional id for updates
    const [errors, setErrors] = useState<ErrorType>({
      payment_disb_name: "",
      apiKey: "",
      status: "",
    });

    useEffect(() => {
      fetchPaymentConfig();
    }, []);

    // Fetch configuration data
    const fetchPaymentConfig = async () => {
      try {
        const res = await SettingService.getPaymentAcquirerConfig();
        const paymentAcquires = res?.data?.paymentDisb.map((item: any) => ({
          id: item.payment_disb_id,
          name: item.payment_disb_name,
          key: item.disb_key,
        }));

        setPaymentDisburalOptions(paymentAcquires);

        // Set initial values
        if (paymentAcquires.length > 0) {
          setPaymentDisbName(paymentAcquires[0].name);
          setApiKey(paymentAcquires[0].key);
        }
      } catch (error) {
        console.error("Error fetching payment acquire config:", error);
      }
    };

    // Handlers to update state when a selection is made
    const handlePaymentDisbNameChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => setPaymentDisbName(event.target.value);

    const handleApiKeyChange = (event: React.ChangeEvent<HTMLInputElement>) =>
      setApiKey(event.target.value);

    const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
      setStatus(event.target.value);

    const handlePaymentDisburalChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedName = event.target.value;
      setPaymentDisbName(selectedName);

      // Update API key based on selected acquire
      const selectedOption = paymentDisburalOptions.find((option: any) => option.name === selectedName);
      if (selectedOption) {
        setApiKey(selectedOption.key);
      }
    };

    const validateForm = () => {
      let formIsValid = true;
      const newErrors: ErrorType = {
        payment_disb_name: "",
        apiKey: "",
        status: "",
      };

      if (!paymentDisbName) {
        formIsValid = false;
        newErrors.payment_disb_name = "Payment disburser name is required.";
      }

      if (!apiKey) {
        formIsValid = false;
        newErrors.apiKey = "API key is required.";
      }

      if (!status) {
        formIsValid = false;
        newErrors.status = "Status is required.";
      }

      setErrors(newErrors);

      return {
        id: id,
        name: paymentDisbName,
        country_id: 1,
        status: status.toLowerCase(),
        key: apiKey,
        formIsValid,
      };
    };

    // Handle form submission
    const handleSubmit = async () => {
      const formData = validateForm();

      if (formData.formIsValid) {
        try {
          const response = await fetch("/api/update-payment-disbursal", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(formData), // Send formData object directly
          });

          if (response.ok) {
            console.log("Payment disbursal configuration updated successfully.");
          } else {
            console.error("Failed to update payment disbursal configuration.");
          }
        } catch (error) {
          console.error("Error submitting form:", error);
        }
      }
    };

    useImperativeHandle(ref, () => ({
      validateForm,
      handleSubmit, // Expose handleSubmit to the parent component
    }));

    return (
      <div>
        <label htmlFor="payment-disb-name" className="block input-label-settings">
          {t("Settings.Paymentdisbursal")}
        </label>
        <select
          id="payment-disbural"
          value={paymentDisbName}
          onChange={handlePaymentDisburalChange}
          className="w-full mb-4 p-2 pl-[0.3rem] border rounded-md"
        >
          {paymentDisburalOptions.map((option: any) => (
            <option key={option.id} value={option.name} className="text-[14px]">
              {option.name}
            </option>
          ))}
        </select>
        {errors.payment_disb_name && (
          <span className="text-red-500 text-sm">{errors.payment_disb_name}</span>
        )}

        <label htmlFor="api-key" className="block input-label-settings">
          {t("ApiConfiguraion.APIKey")}
        </label>
        <input
          className="w-full mb-4 p-2 pl-[0.5rem] border rounded-md"
          type="text"
          id="api-key"
          value={apiKey}
          onChange={handleApiKeyChange}
          placeholder="Enter API Key"
        />
        {errors.apiKey && <span className="text-red-500 text-sm">{errors.apiKey}</span>}

        <label htmlFor="status" className="block input-label-settings">
          {t("Booking.Status")}
        </label>
        <select
          id="status"
          value={status}
          onChange={handleStatusChange}
          className="w-full mb-4 p-2 pl-[0.3rem] border rounded-md"
        >
          <option value="active" className="text-[14px]">
            Active
          </option>
          <option value="inactive" className="text-[14px]">
            Inactive
          </option>
        </select>
        {errors.status && <span className="text-red-500 text-sm">{errors.status}</span>}
      </div>
    );
  }
);

export default PaymentDisbursalConfiguration;
