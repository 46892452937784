function formatDateString(dateString: string): string {
    const date = new Date(dateString);
  
    // Format day, month, and year
    const optionsDate: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    };
    const formattedDate: string = new Intl.DateTimeFormat('en-US', optionsDate).format(date);
  
    // Format time
    const optionsTime: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };
    const formattedTime: string = new Intl.DateTimeFormat('en-US', optionsTime).format(date);
  
    // Combine date and time
    return `${formattedDate} - ${formattedTime}`;
  }


const formatValue = (value: any): string => {
  return value !== null && value !== undefined ? value : ' - ';
};

const getSerialNumber = (index: number): number => {
  return index + 1;
};
  
const Helpers = { formatDateString, formatValue, getSerialNumber }
export default Helpers  
  
