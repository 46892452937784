import OpList from "../features/operator/pages/OperatorList";
import 'jspdf-autotable';

export default function Operator() {
  return (
    <div className="rounded-[8px]">
      <OpList />
    </div>
  );
}
