import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useEffect, useState, useRef } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { VscSettings } from 'react-icons/vsc';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment'
import Loader from '../../../components/common/Loder';
import authService from '../../../services/authService';
import autoTable from 'jspdf-autotable';
import FloatingLabelInput from '../../../components/common/FloatingLabelInput';
import { useLocation, useParams } from 'react-router-dom';
import NoDataFound from '../../../components/common/NoDataFound';
import { decryptParams } from "../../../utils/cryptoUtils";
import { formatCurrency } from '../../../utils/formatCurrency';

export default function WalletSection() {

  const op = useRef(null);
  const { t } = useTranslation();

  const location = useLocation();
  const { encryptedParam } = useParams()
  const { spID } = location.state || {};

  const filterDropdownRef = useRef<HTMLDivElement>(null);
  const SortDropdownRef = useRef<HTMLDivElement>(null);
  const ExportDropdownRef = useRef<HTMLDivElement>(null);

  const [wallet, setWallet]: any = useState([]);

  const [startdate, setstartdate] = useState('');
  const [enddate, setenddate] = useState('');

  const [loading, setLoading] = useState<boolean>(false);
  const [Walletbalance, setWalletbalance] = useState(null);

  const [isSortDropdownOpen, setSortDropdownOpen] = useState(false);
  const [isFilterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [isExportDropdownOpen, setExportDropdownOpen] = useState(false);
  const [selectedSort, setSelectedSort] = useState(t('Wallet.Latest'));

  const token = localStorage.getItem("token");

  interface DecryptedParam {
    index?: any;
  }

  useEffect(() => {
    if (encryptedParam) {
      const decodedParam = decodeURIComponent(encryptedParam).replace(/\s/g, '+');
      const decryptedParam = decryptParams(decodedParam) as DecryptedParam;

      if (decryptedParam && 'spid' in decryptedParam) {
        const spid : any  = decryptedParam.spid as number;
        SPwallet(spid,"","","");
        console.log(spid, "SP ID");
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => { document.removeEventListener("mousedown", handleClickOutside); };
  }, [encryptedParam]);

  const columns: any = [
    {
      label: t('Wallet.DateTime'),
      field: "transaction_datetime",
      sortable: true,
      body: (rowData: any) =>
        rowData.transaction_datetime
          ? moment(rowData.transaction_datetime).format('DD MMM YYYY - hh:mm A')
          : "-", // Show "-" if no date
    },
    {
      label: t('Wallet.TranID'),
      field: "transactionId",
      sortable: true,
      body: (rowData: any) => rowData.transactionId || '-',
    },
    {
      label: t('Wallet.paymentRefId'),
      field: "paymentRefId",
      sortable: true,
      body: (rowData: any) => rowData.paymentRefId || '-',
    },
    {
      label: t('Wallet.PaymentType'),
      field: "transaction_type",
      sortable: true,
      body: (rowData: any) => rowData.transaction_type || '-',
    },
    {
      label: t('Wallet.TotalAmount'),
      field: "amount",
      sortable: true,
      body: (rowData: any) => <span >{formatCurrency(rowData.amount || "-")}</span>
    },
    {
      label: t('Wallet.Balance'),
      field: "balance",
      sortable: true,
      body: (rowData: any) => formatCurrency(rowData.balance || '-'),
    }
  ]

  const SPwallet = (spid:any ,sort_order: string = "DESC",startdateflg:any,enddateflg:any) => {
    setLoading(true);
    authService.Spwallet(token, spid, sort_order,startdateflg === "" ? "" : startdate,enddateflg === "" ? "" : enddate)
      .then((response: any) => {
        console.log(response.data, "Service Provider Wallet");
        if (response.status === 200) {
          setWalletbalance(response.data.transactions_list.wallet_balance);
          setWallet(response.data.transactions_list.transactions
          );
        } else {
          setWallet([]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <Loader />;
  }

  const toggleSortDropdown = () => {
    setSortDropdownOpen(!isSortDropdownOpen);
    setFilterDropdownOpen(false);
    setExportDropdownOpen(false);
  };

  const toggleFilterDropdown = () => {
    setFilterDropdownOpen(!isFilterDropdownOpen);
    setSortDropdownOpen(false);
    setExportDropdownOpen(false);
  };

  const toggleExportDropdown = () => {
    setExportDropdownOpen(!isExportDropdownOpen);
    setSortDropdownOpen(false);
    setFilterDropdownOpen(false);
  };

  const handleSortChange = (sortOption: string) => {
    setSelectedSort(sortOption);
    setSortDropdownOpen(false);

    const sortOrder = sortOption === t('Wallet.Latest') ? "DESC" : "ASC";
    if (encryptedParam) {
      const decodedParam = decodeURIComponent(encryptedParam).replace(/\s/g, '+');
      const decryptedParam = decryptParams(decodedParam) as DecryptedParam;

      if (decryptedParam && 'spid' in decryptedParam) {
        const spid : any  = decryptedParam.spid as number;
        SPwallet(spid,sortOrder,"","");
      }
    }
  };

  const clearAllFields = () => {
    setstartdate("");
    setenddate("");

    if (encryptedParam) {
      const decodedParam = decodeURIComponent(encryptedParam).replace(/\s/g, '+');
      const decryptedParam = decryptParams(decodedParam) as DecryptedParam;
  
      if (decryptedParam && 'spid' in decryptedParam) {
        const spid: any = decryptedParam.spid;
        SPwallet(spid,"","","");
        setFilterDropdownOpen(false);
      }
    } 

  };
  const exportToExcel = () => {
    const formattedData = wallet.map((item: any) => ({

      DateTime: item.dateTime,
      TransactionID: item.transactionId,
      PaymentRefID: item.paymentRefId,
      PaymentType: item.paymentType,
      TotalAmount: item.total,
      Balance: item.balance

    }));
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Serviceprovider_Wallet');
    XLSX.writeFile(workbook, 'Serviceprovider_Wallet.xlsx');
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableColumn = columns.map((col: any) => col.label);
    const tableRows = wallet.map((item: any) => [

      item.dateTime,
      item.transactionId,
      item.paymentRefId,
      item.paymentType,
      item.total,
      item.balance
    ]);

    autoTable(doc, {
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      styles: { fontSize: 10, },
      headStyles: {
        fillColor: [231, 246, 254],
        textColor: [0, 0, 0]
      }
    });


    doc.save('Serviceprovider_Wallet.pdf');
  };
  const handleApplyFilter = () => {
    if (encryptedParam) {
      const decodedParam = decodeURIComponent(encryptedParam).replace(/\s/g, '+');
      const decryptedParam = decryptParams(decodedParam) as DecryptedParam;
  
      if (decryptedParam && 'spid' in decryptedParam) {
        const spid: any = decryptedParam.spid;
        SPwallet(spid,"","",""); // Pass the required `spid`
        setFilterDropdownOpen(false);
      }
    } else {
      console.error('Encrypted parameter is missing.');
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (filterDropdownRef.current && !filterDropdownRef.current.contains(event.target as Node)) {
      setFilterDropdownOpen(false);
    }
    if (ExportDropdownRef.current && !ExportDropdownRef.current.contains(event.target as Node)) {
      setExportDropdownOpen(false);
    }
    if (SortDropdownRef.current && !SortDropdownRef.current.contains(event.target as Node)) {
      setSortDropdownOpen(false);
    }
  };

  return (
    <div className="flex flex-col gap-[16px]">
      <div className="flex flex-col gap-[4px] p-[24px] bg-[#ffffff] rounded-[8px]">
        <span className="text-[#3E4044] text-[14px] font-semibold"> {t('Wallet.WalletBalance')}</span>
        <span className="block text-[#232323] text-[22px] font-semibold">{formatCurrency(Walletbalance ?? 0)}</span>
      </div>
      <div className="flex justify-between items-center">
        <span className="text-[#3E4044] text-[18px] font-medium leading-[27px]">{t('Settlements.TransactionList')}</span>
        <div className="flex gap-[8px]">
          {/* Sort by Latest Button */}
          <div className="relative">
            <button className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]" onClick={toggleSortDropdown}>
              {t('Wallet.SortBy')}: {selectedSort}
              <MdArrowDropDown />
            </button>
            {isSortDropdownOpen && (
              <div ref={SortDropdownRef} className="absolute bg-white border border-gray-300 rounded shadow-md mt-[5px] w-[130px] z-[500]">
                <button className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100 border-b"
                  onClick={() => handleSortChange(t('Wallet.Latest'))} >
                  <span className="text-[16px] font-normal leading-[24px]">{(t('Wallet.Latest'))}</span>
                </button>
                <button className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100" onClick={() => handleSortChange(t('Wallet.Oldest'))}>
                  <span className="text-[16px] font-normal leading-[24px]">{(t('Wallet.Oldest'))}</span>
                </button>
              </div>
            )}
          </div>

          {/* Filter Dropdown */}
          <div className="relative">
            <button
              className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]"
              onClick={toggleFilterDropdown}
            >
              {t('Wallet.Filter')}
              <VscSettings />
            </button>
            {isFilterDropdownOpen && (
              <div ref={filterDropdownRef} className="absolute flex flex-col right-[-85px] gap-[20px] bg-white border border-gray-300 rounded shadow-md mt-[5px] p-5 w-[390px] z-[500]">
                <div className="text-[#3E4044] text-[18px] font-medium leading-[27px]">
                  {t('Wallet.Filter')}
                </div>
                <div className="text-[14px] leading-[24px] font-normal text-[#00000099]">

                  <FloatingLabelInput
                    id="startdate_float_label"
                    type="date"
                    label={t('Filter.startdate')}
                    value={startdate}
                    onChange={(e) => setstartdate(e.target.value)}
                  />
                </div>

                <div className="text-[14px] leading-[24px] font-normal text-[#00000099]">

                  <FloatingLabelInput
                    id="enddate_float_label"
                    type="date"
                    label={t('Filter.enddate')}
                    value={enddate}
                    onChange={(e) => setenddate(e.target.value)}
                  />
                </div>

                <div className="flex flex-col gap-[16px]">
                  <button className="block w-full text-center px-[8px] py-[8px]" onClick={clearAllFields}>
                    {t('Filter.ClearAll')}
                  </button>
                  <button className="block w-full text-center px-[8px] py-[8px] bg-[#2196F3] text-white" onClick={handleApplyFilter}>
                    {t('Filter.Apply')}
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="relative">
            <button className="text-[black] text-[14px] font-medium hover:bg-gray-200 p-[8px] rounded flex items-center gap-[4px]" onClick={toggleExportDropdown} >
              {t('Wallet.Export')}
              <MdArrowDropDown />
            </button>
            {isExportDropdownOpen && (
              <div ref={ExportDropdownRef} className="absolute right-0 bg-white border border-gray-300 rounded shadow-md mt-[5px] w-[160px] z-[500]">
                <button className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100 border-b"
                  onClick={() => {
                    setExportDropdownOpen(false);
                    exportToPDF()
                  }}
                >
                  <span className="text-[16px] font-normal leading-[24px]">{t('Export.ExportASPdf')}</span>
                </button>
                <button className="block w-full text-left px-[16px] py-[8px] hover:bg-gray-100" onClick={() => { setExportDropdownOpen(false); exportToExcel() }}>
                  <span className="text-[16px] font-normal leading-[24px]">{t('Export.ExportASXlsx')}</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {wallet.length === 0 ? <NoDataFound /> :

        <DataTable value={wallet} paginator rows={15}>
          {columns.map((item: any) => (
            <Column
              field={item.field}
              header={item.label}
              headerClassName='custom-header-bg'
              className='custom-col-bg'
              sortable={item.sortable}
              body={item.body}
            />
          ))}
        </DataTable>
      }
    </div>
  )
}
